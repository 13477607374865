<button [ngClass]="getClass()"
  [disabled]="disabled"
  [style.text-align]="textAlign"
  [style.padding-left]="(textAlign === 'left' || textAlign === 'inherit') ? textPadding : '0px'"
  [style.padding-right]="(textAlign === 'right' || textAlign === 'inherit') ? textPadding : '0px'"
  [style.width]="width"
  [style.height]="height"
  >
  <ng-content></ng-content>
</button>
