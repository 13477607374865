import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddDialogComponent } from './dialogs/add-dialog/add-dialog.component';
import { UserPasswordDialogComponent } from './dialogs/user-password-dialog/user-password-dialog.component';
import { EditStatusDialogComponent } from './dialogs/edit-status-dialog/edit-status-dialog.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ResponseDialogComponent } from './dialogs/response-dialog/response-dialog.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { AddOrEditStatusComponent } from './dialogs/add-or-edit-status/add-or-edit-status.component';
import { AddOrEditDepartmentComponent } from './dialogs/add-or-edit-department/add-or-edit-department.component';
import { AddOrEditJobComponent } from './dialogs/add-or-edit-job/add-or-edit-job.component';
import { AddOrEditBranchComponent } from './dialogs/add-or-edit-branch/add-or-edit-branch.component';
import { ChangeStatusComponent } from './dialogs/change-status/change-status.component';
import { DocuViewerComponent } from './dialogs/docu-viewer/docu-viewer.component';
import { MessagerChatComponent } from './dialogs/messager-chat/messager-chat.component';
import { CandidateDetailComponent } from './home/candidate-detail/candidate-detail.component';
import { LoadingDialogComponent } from './dialogs/loading-dialog/loading-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CandidateDocumentsComponent } from './candidate-documents/candidate-documents.component';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NoticeComponent } from './notice/notice.component';
import { BannerViewerComponent } from './dialogs/banner-viewer/banner-viewer.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { RecaptchaModule } from "ng-recaptcha";
import { TermsComponent } from './terms/terms.component';
import { ResponseSmallDialogComponent } from './dialogs/response-small-dialog/response-small-dialog.component';
import { EmployeeListComponent } from './home/employee-list/employee-list.component';
import { EmployeeDetailComponent } from './home/employee-detail/employee-detail.component';
import { DocumentCatalogComponent } from './home/catalogues/document-catalog/document-catalog.component';
import { AddOrEditDocumentComponent } from './dialogs/add-or-edit-document/add-or-edit-document.component';
import { RedirectComponent } from './home/redirect/redirect.component';
import { ConfirmDialogIconComponent } from './dialogs/confirm-dialog-icon/confirm-dialog-icon.component';
import { NewEvaluationComponent } from './dialogs/new-evaluation/new-evaluation.component';
import { ResponseListDialogComponent } from './dialogs/response-list-dialog/response-list-dialog.component';
import { ResponseAnchorDialogComponent } from './dialogs/response-anchor-dialog/response-anchor-dialog.component';
import { SharedComponentsModule } from './common/shared-components.module';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';
import { VacancyDialogComponent } from './dialogs/vacancy-dialog/vacancy-dialog.component';
import { CandidateExternalComponent } from './candidate-external/candidate-external.component';
import { VacancyEmailComponent } from './dialogs/vacancy-email/vacancy-email.component';
import { QrLinkDialogComponent } from './dialogs/qr-link-dialog/qr-link-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AddOrEditRoleComponent } from './dialogs/add-or-edit-role/add-or-edit-role.component';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { AddOrEditSourceComponent } from './dialogs/add-or-edit-source/add-or-edit-source.component';
import { AddOrEditReasonComponent } from './dialogs/add-or-edit-reason/add-or-edit-reason.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropDialogComponent } from './dialogs/image-crop-dialog/image-crop-dialog.component';
import { AddOrEditClientComponent } from './dialogs/add-or-edit-client/add-or-edit-client.component';
import { AddSubdomainComponent } from './dialogs/add-subdomain/add-subdomain.component';
import { QuillModule } from 'ngx-quill';
import { MultiPhotoDialogComponent } from './dialogs/multi-photo-dialog/multi-photo-dialog.component';
import { AddOrEditJobQuestionnaireComponent } from './dialogs/add-or-edit-job-questionnaire/add-or-edit-job-questionnaire.component';
import { DuplicateJobQuestionnaireComponent } from './dialogs/duplicate-job-questionnaire/duplicate-job-questionnaire.component';
import { QuestionnaireViewerComponent } from './dialogs/questionnaire-viewer/questionnaire-viewer.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { GoogleMapsModule } from '@angular/google-maps';
import { AddOrEditDiscardMailComponent } from './dialogs/add-or-edit-discard-mail/add-or-edit-discard-mail.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { DuplicateCandidateComponent } from './dialogs/duplicate-candidate/duplicate-candidate.component';
import { ShareCandidatesDialogComponent } from './dialogs/share-candidates-dialog/share-candidates-dialog.component';
import { TalentAccountDetailComponent } from './dialogs/talent-account-detail/talent-account-detail.component';
import { RegisterDialogComponent } from './dialogs/register-dialog/register-dialog.component';
import { FreemiumDialogComponent } from './dialogs/freemium-dialog/freemium-dialog.component';
import { BlogComponent } from './blog/blog.component';
import { BlogIdComponent } from './blog-id/blog-id.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TourDialogComponent } from './dialogs/tour-dialog/tour-dialog.component';
import { CookiesComponent } from './cookies/cookies.component';
import { PlansDialogComponent } from './dialogs/plans-dialog/plans-dialog.component';
import { SuccessPaymentComponent } from './success-payment/success-payment.component';
import { CancelPaymentComponent } from './cancel-payment/cancel-payment.component';
import { FeaturesDialogComponent } from './dialogs/features-dialog/features-dialog.component';
import { ClientPaymentComponent } from './dialogs/client-payment/client-payment.component';
import { UploadCsfDialogComponent } from './dialogs/upload-csf-dialog/upload-csf-dialog.component';
import { UploadCatalogsDialogComponent } from './dialogs/upload-catalogs-dialog/upload-catalogs-dialog.component';
import { UploadUsersDialogComponent } from './dialogs/upload-users-dialog/upload-users-dialog.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoverComponent,
    AddDialogComponent,
    UserPasswordDialogComponent,
    EditStatusDialogComponent,
    ResponseDialogComponent,
    AddOrEditStatusComponent,
    AddOrEditDepartmentComponent,
    AddOrEditJobComponent,
    AddOrEditBranchComponent,
    ChangeStatusComponent,
    DocuViewerComponent,
    MessagerChatComponent,
    CandidateDetailComponent,
    LoadingDialogComponent,
    CandidateDocumentsComponent,
    NoticeComponent,
    BannerViewerComponent,
    TermsComponent,
    ResponseSmallDialogComponent,
    EmployeeListComponent,
    EmployeeDetailComponent,
    // DocumentCatalogComponent */,
    AddOrEditDocumentComponent,
    RedirectComponent,
    ConfirmDialogIconComponent,
    NewEvaluationComponent,
    ResponseListDialogComponent,
    ResponseAnchorDialogComponent,
    PasswordResetComponent,
    LoginDialogComponent,
    VacancyDialogComponent,
    CandidateExternalComponent,
    VacancyEmailComponent,
    QrLinkDialogComponent,
    AddOrEditRoleComponent,
    AddOrEditSourceComponent,
    AddOrEditReasonComponent,
    ImageCropDialogComponent,
    AddOrEditClientComponent,
    AddSubdomainComponent,
    MultiPhotoDialogComponent,
    AddOrEditJobQuestionnaireComponent,
    DuplicateJobQuestionnaireComponent,
    QuestionnaireViewerComponent,
    AddOrEditDiscardMailComponent,
    DuplicateCandidateComponent,
    ShareCandidatesDialogComponent,
    TalentAccountDetailComponent,
    RegisterDialogComponent,
    FreemiumDialogComponent,
    BlogComponent,
    BlogIdComponent,
    TourDialogComponent,
    CookiesComponent,
    PlansDialogComponent,
    SuccessPaymentComponent,
    CancelPaymentComponent,
    FeaturesDialogComponent,
    ClientPaymentComponent,
    UploadCsfDialogComponent,
    UploadCatalogsDialogComponent,
    UploadUsersDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgImageSliderModule,
    RecaptchaModule,
    FormsModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory }),
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedComponentsModule,
    QRCodeModule,
    ImageCropperModule,
    PdfJsViewerModule,
    CommonModule,
    QuillModule.forRoot(),
    TourMatMenuModule.forRoot(),
    GoogleMapsModule,
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    DatePipe,

    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
