import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Job } from 'src/app/interfaces/job';
import { Vacancy } from 'src/app/interfaces/vacancy';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-vacancy-email',
  templateUrl: './vacancy-email.component.html',
  styleUrls: ['./vacancy-email.component.css']
})
export class VacancyEmailComponent implements OnInit {

  showErrorMessage: boolean = false;
  vacancy: Vacancy[];
  dataSource: Vacancy[] = [];
  formattedVacancies: string[] = [];
  selectedVacancyId: string | null = null;
  emailList: string[] = [];
  emailForm: FormGroup;
  sending: boolean = false;

  constructor(
    private puzzleService: PuzzleService,
    private localService: LocalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<VacancyEmailComponent>,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getVacancies();
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, this.isEmailValid]],
      vacancySelect: ['', Validators.required],
    });

  }

  closeErrorModal() {
    this.showErrorMessage = false;
  }

  onVacancySelected(selectedVacancy: Vacancy) {
    this.selectedVacancyId = selectedVacancy.vacanT_ID;
  }

  getVacancies() {
    this.puzzleService
      .getVacancies(this.localService.getJsonValue('token')?.data.companyId, true, true, 1, 1000)
      .subscribe(
        (response) => {
          this.vacancy = response;
          this.dataSource = this.vacancy;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  formatVacancyDropdownItem(vacancy: Vacancy): string {
    return `${vacancy.jobInfo?.joB_NAME} - ${vacancy.departmentInfo?.deP_NAME} - ${vacancy.branchOfficeInfo?.broF_NAME || 'Sin definir'}`;
  }

  addEmail(event: KeyboardEvent): void {
    event.preventDefault();
    if (this.emailForm.valid) {
      const email = this.emailForm.get('email').value;
      this.emailList.push(email);
      this.emailForm.get('email').reset();
    }
  }

  deleteEmail(index: number): void {
    this.emailList.splice(index, 1);
  }

  removeSpecialCharactersAndSpaces(text: string): string {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  sendEmails(): void {
    this.emailForm.markAllAsTouched();
    const selectedVacancyId = this.emailForm.get('vacancySelect').value;
    const emailInput = this.emailForm.get('email').value;
    if (emailInput && this.emailForm.get('email').valid) {
      if (!this.emailList.includes(emailInput)) {
        this.emailList.push(emailInput);
      }
    }

    if (selectedVacancyId && this.emailList.length > 0) {
      if (!this.sending) {
        this.sending = true;
        this.puzzleService.createVacancyToken(selectedVacancyId, this.emailList).subscribe(
          (response) => {
            if (response.isSuccess && response.data) {
              const token = response.data;
              const userToken = this.localService.getJsonValue('token');
              const companyName = this.removeSpecialCharactersAndSpaces(userToken.data.companyName);
              const urlWithToken = environment.candidateUrlExternal + companyName + '/CE?id=' + token;
              this.copyToClipboard(urlWithToken);
              this.dialogRef.close({ isSuccess: true, message: response.message });
            } else {
              const errorMessage = this.translate.instant(response.message);
              this.dialogRef.close({ isSuccess: false, message: errorMessage });
            }
          },
          (error) => {
            console.error("Error al solicitar la creación del token:", error);
            this.dialogRef.close({ isSuccess: false, message: 'Error al enviar los datos' });
          },
          () => {
            this.sending = false;
          }
        );
      }
    }
  }

  copyToClipboard(text: string) {
    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  closeModal() {
    this.dialogRef.close();
  }

  public isEmailValid(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números y espacios en blanco
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!regex.test(value)) {
      return { 'isEmailValid': true };
    }

    return null;
  }

}
