import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FreemiumDialogComponent } from 'src/app/dialogs/freemium-dialog/freemium-dialog.component';
import { PlansDialogComponent } from 'src/app/dialogs/plans-dialog/plans-dialog.component';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.css']
})
export class MaintenanceBannerComponent implements OnInit {

  isHaibuJobs: boolean = false;
  isFreemium: boolean = false;
  daysLeft: number = 10;
  token: APIResponse = this.localService.getJsonValue('token');
  testHaibu: boolean = false;
  actualPlan: string;
  isMiniCompany: boolean = false;
  userPermissions: string[];

  constructor(
    private localService: LocalService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.token?.data?.isHaibuJobs) {
      this.isHaibuJobs = true;
    } else if (this.token?.data?.isFreemium) {
      this.isFreemium = true;
    }

    this.actualPlan = this.token?.data?.comP_CURRENT_PLAN;
    this.isMiniCompany = this.token?.data?.isMiniCompany;

    this.userPermissions = this.token?.data?.permissions.map(permission => permission.code);

    if (this.token?.data?.comP_SUBSCRIPTION_STATUS == 'Trial') {
      this.testHaibu = true;
      this.daysLeft = this.token?.data?.daysLeftInTrial;

      if (!this.isMiniCompany && this.getPermission('m002')) {
        const showed14Days = this.localService.getJsonValue('showed14Days');
        if (this.daysLeft <= 0 && !showed14Days) {
          this.openFreemiumDialog('showed14Days');
          this.localService.setJsonValue('showed14Days', 'true');
          return;
        }

        const showed7Days = this.localService.getJsonValue('showed7Days');
        if (this.daysLeft <= 7 && !showed7Days) {
          this.openFreemiumDialog('showed7Days');
          this.localService.setJsonValue('showed7Days', 'true');
        }
      }
    }
  }

  getPermission(permission: string | string[]): boolean {
    if (typeof permission === 'string') {
      return this.userPermissions.includes(permission);
    } else {
      for (let i = 0; i < permission.length; i++) {
        if (this.userPermissions.includes(permission[i])) {
          return true;
        }
      }
    }
  }

  openPlansModal() {
    const planModal = this.dialog.open(PlansDialogComponent, {
      panelClass: 'plans-dialog',
      width: '1200px',
      maxWidth: '95vw',
    });

    planModal.afterClosed().subscribe(result => {
      const salesModal = this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
        data: {
          title: this.translate.instant('notReady'),
          secondAction: this.translate.instant('continueFreemium', { plan: this.translate.instant(this.actualPlan) + (this.testHaibu ? this.translate.instant('Trial') : '') }),
          action: 1,
          btnText: 'contactSales'
        }
      });

      salesModal.afterClosed().subscribe(result => {
        if (this.actualPlan != 'Freemium') {
          this.dialog.open(FreemiumDialogComponent, {
            panelClass: 'freemium-dialog',
            width: '500px',
            maxWidth: '95vw',
            data: {
              title: this.translate.instant('loosedFeatures'),
              secondAction: this.translate.instant('continueFreemium', { plan: this.translate.instant(this.actualPlan) + (this.testHaibu ? this.translate.instant('Trial') : '') }),
              icon: 'none',
              plan: this.actualPlan
            }
          });
        }
      });
    });
  }

  openFreemiumDialog(days: string) {
    const ref = this.dialog.open(FreemiumDialogComponent, {
      panelClass: 'freemium-dialog',
      width: '500px',
      maxWidth: '95vw',
      data: {
        title: this.translate.instant(days)
      }
    });

    ref.afterClosed().subscribe(result => {
      const salesModal = this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
        data: {
          title: this.translate.instant('notReady'),
          secondAction: this.translate.instant('continueFreemium', { plan: this.translate.instant(this.actualPlan) + (this.testHaibu ? this.translate.instant('Trial') : '') }),
          action: 1,
          btnText: 'contactSales'
        }
      });
    });
  }
}
