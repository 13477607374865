import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-upload-users-dialog',
  templateUrl: './upload-users-dialog.component.html',
  styleUrls: ['./upload-users-dialog.component.css']
})
export class UploadUsersDialogComponent implements OnInit {

  selectedFile: File | null = null;
  sending = false;
  companyId: string = '';
  userName: string = '';

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<UploadUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.companyId = data.companyId;
    this.userName = data.userName;
  }

  ngOnInit(): void {
  }

  // Maneja el evento de selección de archivo
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      this.validateFile();
    }
  }

  // Maneja el evento de arrastrar y soltar archivos
  onFileDropped(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer?.files;
    if (files?.length) {
      this.selectedFile = files[0]; // Solo permite un archivo
      this.validateFile();
    }
    const dropzone = event.currentTarget as HTMLElement;
    dropzone.classList.remove('dragover');
  }

  validateFile() {
    if (this.selectedFile) {
      const allowedExtensions = ['.xls', '.xlsx'];
      const allowedSize = 10 * 1024 * 1024; // 10MB en bytes
      const extension = this.selectedFile.name.split('.').pop();

      if (allowedExtensions.includes(`.${extension?.toLowerCase()}`) && this.selectedFile.size <= allowedSize) {
      } else {
        this.selectedFile = null;
        this.openResponseDialog('Error', this.translate.instant('uploadCatalogsDialogErrorValidation'));
      }
    }
  }

  // Evita el comportamiento por defecto en el dragover
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // Añadir clase cuando el archivo esté sobre el área
    const dropzone = event.currentTarget as HTMLElement;
    dropzone.classList.add('dragover');
  }

  // Maneja el evento dragleave (opcional)
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // Remover clase cuando el archivo ya no esté sobre el área
    const dropzone = event.currentTarget as HTMLElement;
    dropzone.classList.remove('dragover');
  }

  // Simula el proceso de subida de archivo
  uploadFile(): void {
    if (!this.selectedFile || this.sending) return;

    this.sending = true;

    const formData = new FormData();
    formData.append('CompanyId', this.companyId);
    formData.append('ExcelFile', this.selectedFile);
    formData.append('UserName', this.userName);

    this.puzzleService.uploadUsersFromExcel(formData).subscribe(
      (response) => {
        if (response.isSuccess) {
          let extra = null;
          if (response.data?.users) {
            let failed = 0;
            let success = 0;
            response.data.users.forEach((user: any) => {
              if (user.status == 'Usuario creado y correo enviado' || user.status == 'Usuario existente agregado correctamente.') {
                success++;
              } else {
                failed++;
              }
            });
            extra = this.translate.instant('uploadCatalogsUsersDialogSuccessExtra', { success: success, failed: failed });
          }
          this.openResponseDialog('Exito', this.translate.instant('uploadCatalogsUsersDialogSuccess'), extra);
        } else {
          let message = this.translate.instant('uploadCatalogsUsersDialogError')
          if (response.message == 'userLimitReached') {
            message = this.translate.instant('userLimitReached');
          }
          this.openResponseDialog('Error', message);
        }
        this.dialogRef.close(true);
      },
      (error) => {
        this.sending = false;
        this.openResponseDialog('Error', this.translate.instant('uploadCatalogsUsersDialogError'));
      }
    );
  }

  openResponseDialog(response: string, message?: string, extra?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      if (extra) {
        data.push('');
        data.push('');
        data.push(extra);
      }
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });

      return dialogRef;
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);
      if (extra) {
        data.push('');
        data.push('');
        data.push(extra);
      }
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });

      return dialogRef;
    } else if (response == 'Warning') {
      data.push('Warning');
      data.push(message);
      if (extra) {
        data.push('');
        data.push('');
        data.push(extra);
      }
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });

      return dialogRef;
    }
  }
}
