<div class="p-2 pt-0 relative">
    <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="cancel()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>
    <div class="flex items-center gap-4 pb-2">
        <!-- Foto perfil -->
        <div class="flex">
            <div class="w-14 h-14 relative">
                <!-- Borde redondo externo -->
                <div class="w-14 h-14 absolute rounded-full border image-container z-10"></div>
                <!-- Imagen del perfil del usuario -->
                <img *ngIf="userImage" class="w-14 h-14 absolute rounded-full object-cover" [src]="userImage" alt="">
                <img *ngIf="!userImage" class="w-14 h-14 absolute rounded-full object-cover" [src]="defaultpfp" alt="">
            </div>
        </div>
        <!-- Foto perfil END -->

        <div class="flex flex-col justify-center w-full">
            <div class="w-3/4 candidate-name">
                <div *ngIf="candidate" (click)="closeModal()">
                    {{candidate.cdT_NAME}} {{candidate.cdT_PATERNAL_SURNAME}} {{candidate.cdT_MATERNAL_SURNAME}}
                </div>
            </div>

            <div class="w-full candidate-job flex justify-between items-center">
                <span *ngIf="candidate">{{candidate.cdT_JOB_INFO.joB_NAME}}</span>
            </div>
        </div>
    </div>
    <div mat-dialog-content class="max-h-[60%]">
        <div class="flex items-center justify-between">
            <div class="py-3 green-title">
                {{ questionnaire.questionnaireName}}
            </div>
            <div [ngClass]="'eval' + questionnaire.statusScore">
                {{ questionnaire.score }} ({{ questionnaire.userScore }}%)
            </div>
        </div>
        <div>
            <div *ngFor="let question of questionnaire.questions">
                <div *ngIf="question.questionType == 'OPEN_ENDED'" class="w-full flex flex-col mb-3">
                    <label class="title-input-text" for="correoprev">
                        {{ question.questionText }}*
                    </label>
                    <div class="w-full flex items-center gap-1">
                        <div class="w-full input-container mobile-responsive">
                            <div class="input-text"
                                [ngClass]="(question.needsReview && !question.needsReviewPass) ? 'warningopen' : (question.isCorrect ? 'correctopen' : 'incorrectopen')">
                                {{ question.answerText }}
                            </div>
                        </div>
                        <div *ngIf="question.needsReview && !question.needsReviewPass" class="flex items-center gap-1">
                            <mat-icon class="cursor-pointer text-gray-500"
                                (click)="question.isCorrect = true; question.needsReviewPass = true">
                                check
                            </mat-icon>
                            <mat-icon class="cursor-pointer text-gray-500"
                                (click)="question.isCorrect = false; question.needsReviewPass = true">
                                close
                            </mat-icon>
                        </div>
                        <div *ngIf="question.needsReview && question.needsReviewPass" class="flex items-center gap-1">
                            <mat-icon class="cursor-pointer" [ngClass]="question.isCorrect ? 'text-green-500 green-icon' : 'text-gray-500'"
                                (click)="question.isCorrect = true">
                                check
                            </mat-icon>
                            <mat-icon class="cursor-pointer" [ngClass]="!question.isCorrect ? 'text-red-500 red-icon' : 'text-gray-500'"
                                (click)="question.isCorrect = false">
                                close
                            </mat-icon>
                        </div>
                        <div *ngIf="!question.needsReview" class="flex items-center gap-1">
                            <mat-icon [ngClass]="question.isCorrect ? 'text-green-500 green-icon' : 'text-gray-500'">
                                check
                            </mat-icon>
                            <mat-icon [ngClass]="!question.isCorrect ? 'text-red-500 red-icon' : 'text-gray-500'">
                                close
                            </mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="['YES_NO', 'MULTIPLE_CHOICE'].includes(question.questionType)"
                    class="w-full flex flex-col mb-3">
                    <label class="title-input-text" for="correoprev">
                        {{ question.questionText }}*
                    </label>
                    <div class="w-full flex items-center gap-1">
                        <mat-radio-group [(ngModel)]="selectedValueRadio" class="w-full flex flex-col gap-1">
                            <mat-radio-button *ngFor="let option of question.options" class="custom-radio"
                                [ngClass]="getRadioClass(option)" [value]="option.isSelected" disabled>
                                <div class="flex items-center gap-1">
                                    <span>
                                        {{ option.optionText | translate }}
                                    </span>
                                    <div *ngIf="option.isSelected || option.isCorrect" class="icon-check">
                                        <mat-icon *ngIf="option.isCorrect" class="text-green-500 icon-check">
                                            check
                                        </mat-icon>
                                        <mat-icon *ngIf="!option.isCorrect" class="text-red-500 icon-check">
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div *ngIf="question.questionType == 'CHECKBOXES'" class="w-full flex flex-col mb-3">
                    <label class="title-input-text" for="correoprev">
                        {{ question.questionText }}*
                    </label>
                    <div class="w-full flex flex-col gap-1">
                        <mat-checkbox *ngFor="let option of question.options" class="custom-checkbox"
                            [ngClass]="getRadioClass(option)" [(ngModel)]="option.isSelected" disabled>
                            <div class="flex items-center gap-1">
                                <span>
                                    {{ option.optionText }}
                                </span>
                                <div *ngIf="option.isSelected || option.isCorrect" class="icon-check">
                                    <mat-icon *ngIf="option.isCorrect" class="text-green-500 icon-check">
                                        check
                                    </mat-icon>
                                    <mat-icon *ngIf="!option.isCorrect" class="text-red-500 icon-check">
                                        close
                                    </mat-icon>
                                </div>
                            </div>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-4 w-full flex flex-row items-center justify-center">
        <app-custom-primary-button *ngIf="getResponesToEvaluate() > 0" id="candidateDownloadQrDialog"
            size="super-small-light" color="primary" class="text-center w-1/2 md:w-auto" [disabled]="loading"
            textPadding="10px" (click)="saveResponses()">
            <span *ngIf="!loading">{{ 'saveResponses' | translate }}</span>
            <div *ngIf="loading" class="w-full flex justify-center">
                <mat-spinner diameter="20" color="primary"></mat-spinner>
            </div>
        </app-custom-primary-button>
    </div>
</div>