<app-navbar></app-navbar>
<div class="mt-28 py-3 px-4 md:px-12 lg:px-18 xl:px-20">
    <div class="flex justify-between items-center pb-4">
        <!-- Botón de regresar -->
        <mat-icon class="cursor-pointer btn-top" (click)="navigateToBlog()">arrow_back</mat-icon>
    </div>
    <div class="bg-white border border-gray-300 rounded-lg overflow-hidden">
        <!-- Imagen del blog en la parte superior ocupando todo el ancho -->
        <figure class="rounded-t-lg h-60 relative">
            <img *ngIf="blog?.eH_BLOG_IMG_BLOG" [src]="imgPath + blog.eH_BLOG_IMG_BLOG"
                [alt]="blog ? blog.eH_BLOG_TITLE : 'banner'" class="w-full object-cover object-center h-60">
            <div *ngIf="!blog?.eH_BLOG_IMG_BLOG" class="bg-gray-200 flex items-center justify-center h-60">
                <mat-icon
                    class="text-gray-400 w-20 h-20 text-3xl flex items-center justify-center">photo_camera</mat-icon>
            </div>
        </figure>

        <!-- Contenido dividido en dos columnas debajo de la imagen del blog -->
        <div class="flex flex-col-reverse lg:flex-row p-5 px-3 lg:px-6 gap-4">
            <!-- Columna izquierda (25%) - Foto del autor y otros blogs -->
            <div class="w-full lg:w-1/5">
                <!-- Foto del autor con nombre y fecha debajo -->
                <div class="flex flex-col items-center pb-6">
                    <div class="flex items-center">
                        <figure class="w-28 h-28 rounded-full overflow-hidden">
                            <img *ngIf="blog?.eH_BLOG_PFP_AUTHOR" [src]="imgPath + blog.eH_BLOG_PFP_AUTHOR"
                                [alt]="blog?.eH_BLOG_AUTHOR || 'author'" class="w-full h-full object-cover">
                            <div *ngIf="!blog?.eH_BLOG_PFP_AUTHOR"
                                class="bg-gray-300 flex items-center justify-center h-full">
                                <mat-icon class="text-gray-400 text-3xl">photo_camera</mat-icon>
                            </div>
                        </figure>
                    </div>

                    <!-- Nombre del autor y fecha debajo de la imagen -->
                    <div class="text-center text-lg font-medium mt-2">{{ blog?.eH_BLOG_AUTHOR || 'Autor' }}</div>
                    <div class="text-center text-sm text-gray-500">{{ blog?.eH_BLOG_CREATED_DATE | localDate }}</div>

                    <!-- Clave de traducción centrada debajo del nombre y fecha -->
                    <div class="text-center linkedin-text mt-2">
                        <a [href]="blog?.eH_BLOG_LINKEDIN_AUTHOR || 'https://www.linkedin.com/company/haibuhr/posts/?feedView=all'"
                            target="_blank" rel="noopener noreferrer">
                            {{ 'followOnLinkedin' | translate }}
                        </a>
                    </div>
                </div>

                <!-- Tarjetas pequeñas para otros blogs -->
                <div *ngFor="let otherBlog of relatedBlogs" class="related-blog-card mb-4"
                    (click)="goToPost(otherBlog)">
                    <div class="related-blog-content">
                        <!-- Imagen del autor en círculo -->
                        <div class="author-image">
                            <img [src]="otherBlog.eH_BLOG_PFP_AUTHOR || defaultProfilePic"
                                alt="{{ otherBlog.eH_BLOG_AUTHOR || 'author' }}">
                        </div>
                        <div class="blog-info">
                            <h3 class="related-blog-title">{{ otherBlog.eH_BLOG_TITLE }}</h3>
                            <p class="related-blog-description">{{ otherBlog.eH_BLOG_SEO_DESCRIPTION }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Columna derecha (75%) - Descripción principal del blog -->
            <div class="w-full lg:w-4/5 relative">


                <!-- Título del blog -->
                <div>
                    <div class="flex justify-between items-start gap-3">
                        <h1 class="green-title text-xl">
                            {{ blog ? blog.eH_BLOG_TITLE : 'Título del blog' }}
                        </h1>
                        <!-- Botón de compartir en la esquina superior derecha -->
                        <div>
                            <button mat-icon-button [matMenuTriggerFor]="shareMenu2">
                                <mat-icon class="cursor-pointer btn-top">share</mat-icon>
                            </button>

                            <mat-menu #shareMenu2="matMenu">
                                <a mat-menu-item
                                    [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + currentUrl"
                                    target="_blank">
                                    <div class="flex items-center gap-2">
                                        <img [src]="linkedinIcon" alt="LinkedIn">
                                        LinkedIn
                                    </div>
                                </a>
                                <a mat-menu-item [href]="'https://wa.me/?text=' + currentUrl" target="_blank">
                                    <div class="flex items-center gap-2">
                                        <img [src]="whatsIcon" alt="WhatsApp">
                                        WhatsApp
                                    </div>
                                </a>
                                <a mat-menu-item [href]="'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl"
                                    target="_blank">
                                    <div class="flex items-center gap-2">
                                        <img [src]="fbIcon" alt="Facebook">
                                        Facebook
                                    </div>
                                </a>
                                <button mat-menu-item (click)="copyLinkToClipboard()">
                                    <div class="flex items-center gap-2">
                                        <img [src]="copyIcon" [alt]="'copyLink' | translate">
                                        {{ 'copyLink' | translate }}
                                    </div>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <!-- Descripción del blog -->
                    <div *ngIf="blog?.eH_BLOG_DESCRIPTION" class="text-gray-600 quill mt-4 w-full text-justify">
                        <quill-view [content]="blog.eH_BLOG_DESCRIPTION" format="html" theme="snow"></quill-view>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <app-haibu-banner></app-haibu-banner>
</div>