import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-custom-primary-button',
  templateUrl: './custom-primary-button.component.html',
  styleUrls: ['./custom-primary-button.component.css']
})
export class CustomPrimaryButtonComponent{

  @Input() size: string;
  @Input() color: string;
  @Input() textAlign: string = 'center';
  @Input() textPadding: string = '0px';
  @Input() width: string;
  @Input() height: string;
  @Input() sticky: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() applySelectionStyles: boolean = false;
  @Input() applySelectionStyles2: boolean = false;
  @Input() applySelectionStyles3: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tour: string | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.tour) {
      this.renderer.setAttribute(this.el.nativeElement.querySelector('button'), 'idTour', this.tour);
    }
  }

  getClass() {
    let classes = ['btn', this.size, this.color];
    if (this.sticky) {
      classes.push('sticky-button');
    }
    if (this.applySelectionStyles) {
      if (this.isSelected) {
        classes.push('selected-button');
      } else {
        classes.push('not-selected-button');
      }
    }
    if (this.applySelectionStyles2) {
      if (this.isSelected) {
        classes.push('selected-button2');
      } else {
        classes.push('not-selected-button');
      }
    }
    if (this.applySelectionStyles3) {
      if (this.isSelected) {
        classes.push('candidate-color');
      } else {
        classes.push('not-selected-button2');
      }
    }
    return classes;
  }

}
