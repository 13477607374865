import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Job } from 'src/app/interfaces/job';
import { Questionnaire } from 'src/app/interfaces/questionnaire';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-duplicate-job-questionnaire',
  templateUrl: './duplicate-job-questionnaire.component.html',
  styleUrls: ['./duplicate-job-questionnaire.component.css']
})
export class DuplicateJobQuestionnaireComponent implements OnInit {

  sending: boolean = false;
  job: Job;
  questionnaire: Questionnaire;
  jobList: Job[] = [];
  pageSize = 1000;
  pageIndex = 0;
  id: string = '';

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<DuplicateJobQuestionnaireComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Job,
  ) {
    this.job = data;
    this.questionnaire = this.job.questionnaire;
    this.questionnaire.questionnairE_NAME = this.job.questionnairE_NAME;
    this.questionnaire.questionnairE_DESCRIPTION = this.job.questionnairE_DESCRIPTION;
    this.questionnaire.questionnairE_EXPECTED_SCORE = this.job.questionnairE_EXPECTED_SCORE;
    this.questionnaire.questionnairE_MINIMUM_PASSING_SCORE = this.job.questionnairE_MINIMUM_PASSING_SCORE

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.id = urlParams.get('id');
  }

  ngOnInit(): void {
    this.getJobList();
    this.questionnaire.jobs = [
      {
        JOB_ID: this.job.joB_ID
      }
    ]
  }

  duplicateQuestionnaire() {
    if (this.sending) {
      return;
    }
    this.sending = true;
    const selectedJobs = this.jobList.filter(job => job.checked);

    if (selectedJobs.length <= 0) {
      this.openResponseDialog('Error', this.translate.instant('selectAtLeastOneJob'));
      this.sending = false;
      return;
    }

    this.questionnaire.questionnairE_ID = null;
    this.questionnaire.jobs = selectedJobs.map(job => {
      return {
        JOB_ID: job.joB_ID
      }
    });
    this.questionnaire.questionnariE_DESCRIPTION = this.questionnaire.questionnairE_DESCRIPTION;
    delete this.questionnaire.questionnairE_DESCRIPTION;
    this.puzzleService.createOrUpdateQuestionnaire(this.questionnaire).subscribe((response) => {
      if (response.isSuccess) {
        this.dialogRef.close({
          isSuccess: true,
          message: response.message
        });
      } else {
        this.sending = false;
        this.dialogRef.close({
          isSuccess: false,
          message: response.message
        });
      }
    }, (error) => {
      this.dialogRef.close(
        {
          isSuccess: false,
          message: 'Error duplicating questionnaire'
        }
      );
    });
  }

  getJobList() {
    this.puzzleService.getJob(this.pageSize, this.pageIndex, this.id, null, true).subscribe((jobs) => {
      this.jobList = jobs;
      if (this.jobList.length <= 0) {
        this.dialogRef.close(
          {
            isSuccess: false,
            message: 'No jobs found'
          }
        );
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  selectJob(index: number) {
    const job = this.jobList[index];
    job.checked = !job.checked;
  }

  openResponseDialog(response: string, message?: string, extra?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }
}
