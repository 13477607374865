import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
    constructor(
        private translateService: TranslateService
    ) {
        super();
    }
  itemsPerPageLabel = this.translateService.instant('ITEMS_PER_PAGE');
  nextPageLabel = this.translateService.instant('NEXT_PAGE');
  previousPageLabel = this.translateService.instant('PREVIOUS_PAGE');
  firstPageLabel = this.translateService.instant('FIRST_PAGE');
  lastPageLabel = this.translateService.instant('LAST_PAGE');

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }
}
