<div class="p-0 role-modal">
    <div class="ml-auto w-full text-right justify-end -mt-1 -mr-3">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>

    <div class="pb-2 text-2xl color-label">
        {{ isNew ? ('catalogueRoleDialogCreate' | translate) : ('catalogueRoleDialogEdit' | translate) }}
    </div>

    <div class="px-0">
        <form autocomplete="off" [formGroup]="formGroup">
            <div class="flex md:flex-row flex-col items-end justify-between pb-4">
                <div class="flex flex-col w-full lg:w-1/3">
                    <label class="pt-2 pb-2 pr-0 float-left flex justify-start text-left edit-text color-label"
                        for="name">{{
                        'addDialogName' | translate }}:</label>
                    <input id="addDialogNameRole" formControlName="name" class="p-2 w-full borde" type="text"
                        name="name" maxlength="50" oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
                    <div *ngIf="formGroup.get('name').invalid && (formGroup.get('name').dirty || formGroup.get('name').touched)"
                        class="text-red-500 text-xs mt-1">
                        <span *ngIf="formGroup.get('name').errors['required']">
                            {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('addDialogName' | translate) } }}
                        </span>
                        <span *ngIf="formGroup.get('name').errors['noSpecialCharacters']">
                            {{ 'invalidCharactersKeyFormCatalogs' | translate }}
                        </span>
                    </div>
                </div>
                <p class="edit-text color-label hidden lg:block">
                    {{ 'catalogueRoleDialogSelectPermissions' | translate }}
                </p>
            </div>
            <div class="toggle-container">
                <div class="w-full mb-2">
                    <p class="green-title">{{ 'roleDashboardTitle' | translate }}</p>
                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-center">
                        <mat-slide-toggle id="dsh001" formControlName="dsh001" color="primary" class="flex items-center"
                            [matTooltip]="'seeDashboardAnalitycsTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'dsh001')" (change)="onSeeCompany($event, 'dsh001')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal">{{ 'seeDashboardAnalitycs' | translate }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="w-full">
                    <p class="green-title">{{ 'catalogueComponentLogo' | translate }}</p>
                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-center">
                        <mat-slide-toggle id="m001" formControlName="m001" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCompanySeeTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'm001')" (change)="onSeeCompany($event, 'm001')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal"
                                [innerHTML]="companySee">{{ companySee
                                }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="m002" formControlName="m002" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCompanyEditTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'm002')" (change)="onSeeCompany($event, 'm002')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal"
                                [innerHTML]="companyEdit">{{ companyEdit }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="w-full mt-3">
                    <p class="green-title">{{ 'catalogueCompany' | translate }}</p>
                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-center">
                        <mat-slide-toggle id="c001" formControlName="c001" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCatalogueSeeTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'c001')" (change)="onSeeCompany($event, 'c001')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogSeeAll' |
                                translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="c002" formControlName="c002" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCatalogueEditTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'c002')" (change)="onSeeCompany($event, 'c002')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogEditAll' |
                                translate }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="w-full mt-4">
                    <p class="green-title">{{ 'catalogueComponentRoles' | translate }}</p>
                    <div
                        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-4 md:gap-y-2 items-center">
                        <mat-slide-toggle id="r001" formControlName="r001" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogRoleSeeTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'r001')" (change)="onSeeCompany($event, 'r001')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogSee' |
                                translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="r002" formControlName="r002" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogRoleAddTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'r002')" (change)="onSeeCompany($event, 'r002')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogAddAll' |
                                translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="r003" formControlName="r003" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogRoleEditTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'r003')" (change)="onSeeCompany($event, 'r003')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogEditAll' |
                                translate }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="w-full mt-4">
                    <p class="green-title">{{ 'userCatalogSideBarButton' | translate }}</p>
                    <div
                        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-4 md:gap-y-2 items-center">
                        <mat-slide-toggle id="u004" formControlName="u004" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogUserSeeTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'u004')" (change)="onSeeCompany($event, 'u004')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogSeeAll' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="u01" formControlName="u01" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogUserAddTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'u01')" (change)="onSeeCompany($event, 'u01')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogAddUsers' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="u02" formControlName="u02" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogUserEditTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'u02')" (change)="onSeeCompany($event, 'u02')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogEditUsers' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="u005" formControlName="u005" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogUserDeleteTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'u005')" (change)="onSeeCompany($event, 'u005')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogDeleteUsers' | translate }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="w-full mt-4">
                    <p class="green-title">{{ 'vacancyListButton' | translate }}</p>
                    <div
                        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-4 md:gap-y-2 items-center">
                        <mat-slide-toggle id="v005" formControlName="v005" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueSeeDialogVacancyAddTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'v005')" (change)="onSeeCompany($event, 'v005')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogSeeAll' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="v006" formControlName="v006" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueSeeDialogVacancyAddTooltip2' | translate"
                            (keydown.enter)="onEnterPress($event, 'v006')" (change)="onSeeCompany($event, 'v006')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogSeeParticipation' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="v001" formControlName="v001" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogVacancyAddTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'v001')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogAddAll' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="v002" formControlName="v002" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogVacancyEditTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'v002')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogEditAll' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="v003" formControlName="v003" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogVacancyDeleteTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'v003')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogDeleteAll' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="v004" formControlName="v004" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogVacancyLinkTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'v004')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-left">{{
                                'catalogueRoleDialogGenerateLink' | translate }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="w-full mt-4">
                    <p class="green-title">{{ 'candidateButton' | translate }}</p>
                    <div
                        class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-4 md:gap-y-2 items-center">
                        <mat-slide-toggle id="cd001" formControlName="cd001" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateSeeAllTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd001')" (change)="onSeeCompany($event, 'cd001')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogSeeAll' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd012" formControlName="cd012" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateSeeTooltip2' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd012')" (change)="onSeeCompany($event, 'cd012')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogSeeParticipationCandidates' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd002" formControlName="cd002" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateSeeTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd002')" (change)="onSeeCompany($event, 'cd002')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogSeeContracted' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd003" formControlName="cd003" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateEditTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd003')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogEditCandidates' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd004" formControlName="cd004" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateAddTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd004')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogAddCandidates' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd005" formControlName="cd005" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateStepTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd005')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogChangeStep' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd006" formControlName="cd006" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateStatusTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd006')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogChangeStatus' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd007" formControlName="cd007" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateDeleteTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd007')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogDeleteCandidates' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd008" formControlName="cd008" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateDocumentsTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd008')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogUploadCandidates' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd009" formControlName="cd009" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateEvalTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd009')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogEvalCandidates' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd010" formControlName="cd010" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateExpedientTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd010')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogDownloadCandidates' | translate }}</span>
                        </mat-slide-toggle>
                        <mat-slide-toggle id="cd011" formControlName="cd011" color="primary" class="flex items-center"
                            [matTooltip]="'catalogueRoleDialogCandidateLinkTooltip' | translate"
                            (keydown.enter)="onEnterPress($event, 'cd011')">
                            <span class="edit-text toggle-line-text color-label whitespace-normal text-right">{{
                                'catalogueRoleDialogGenerateLinkCandidates' | translate }}</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="w-full mt-6">
                    <div class="flex justify-center">
                        <app-custom-primary-button id="addRoleSaveDialog" type="button" size="role-save" color="primary"
                            class="text-center w-48" (click)="onSubmit()" [disabled]="sending">
                            <span *ngIf="!sending">{{ 'addJobSave' | translate }}</span>
                            <div *ngIf="sending" class="w-full flex justify-center">
                                <mat-spinner diameter="20" color="primary"></mat-spinner>
                            </div>
                        </app-custom-primary-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>