<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="text-center title-text-modal">
  {{"passwordDialogTitle" | translate}}
</div>
<div class="pt-6 pb-3 px-2 flex items-center gap-3">
  <label class="gris font-medium" for="usuario" style="color: #718096">
    {{"passwordDialogNameUser" | translate}}:</label>
  <div name="usuario" style="color: #718096">
    {{ data.username }}
  </div>
</div>
<form (ngSubmit)="sendPass()" autocomplete="off" [formGroup]="myForm" class="w-full">
  <!-- Nueva Contraseña -->
  <div class="w-full  px-2 mb-4 ">
    <div class="flex flex-col">
      <label class="title-input-text green-subtitle" for="password">
        {{ 'passwordDialogNewPassword' | translate }}
      </label>
      <div class="input-container mobile-responsive relative" style="height: 48px;">
        <input id="firstPassInputDialog" formControlName="password" class="custom-input pr-10"
          [type]="hide ? 'password' : 'text'" name="password" oninput="this.value = this.value.trim();" />
        <mat-icon
          class="cursor-pointer gray-me p-1.5 icon-password absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
          matSuffix (click)="hide = !hide">
          {{ hide ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <div *ngIf="myForm.get('password').invalid && (myForm.get('password').dirty || myForm.get('password').touched)"
        class="text-red-500 text-xs mt-1">
        <span *ngIf="myForm.get('password').errors['required']">
          {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('passwordDialogNewPassword' | translate) } }}
        </span>
        <span *ngIf="myForm.get('password').errors['minlength']">
          {{ 'addDialogPasswordAlertMin' | translate }}
        </span>
      </div>
    </div>
  </div>

  <!-- Confirmar Nueva Contraseña -->
  <div class="w-full  px-2 mb-4">
    <div class="flex flex-col">
      <label class="title-input-text green-subtitle" for="password2">
        {{ 'passwordDialogConfirmPassword' | translate }}
      </label>
      <div class="input-container mobile-responsive relative" style="height: 48px;">
        <input id="secondPassInputDialog" formControlName="password2" class="custom-input pr-10"
          [type]="hide2 ? 'password' : 'text'" name="password2" oninput="this.value = this.value.trim();" />
        <mat-icon
          class="cursor-pointer gray-me p-1.5 icon-password absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
          matSuffix (click)="hide2 = !hide2">
          {{ hide2 ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <div *ngIf="myForm.get('password2').invalid && (myForm.get('password2').dirty || myForm.get('password2').touched)"
        class="text-red-500 text-xs mt-1">
        <span *ngIf="myForm.get('password2').errors['required']">
          {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('passwordDialogConfirmPassword' | translate) } }}
        </span>
        <span *ngIf="!myForm.get('password2').errors['required'] && myForm.get('password2').errors['noIguales']">
          {{ 'passwordDialogPasswordMatch' | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="ml-auto w-full text-right justify-end pr-2">
    <app-custom-primary-button id="passwordDialogSaveBtn" type="submit" size="small" color="primary">
      <span *ngIf="!sending">{{"passwordDialogSave" | translate}}</span>
      <div *ngIf="sending" class="w-full flex justify-center">
        <mat-spinner diameter="20" color="primary"></mat-spinner>
      </div>
    </app-custom-primary-button>
  </div>
</form>