<!-- <div mat-dialog-title></div> -->
<mat-dialog-content id="register">
    <div *ngIf="step == 0;" class="text-center pt-4">
        <div class="green-title text-xl mb-4" [innerHTML]="'registerHaibuJobs' | translate">
        </div>
        <div class="text-gray-500 text-base mb-1" [innerHTML]="'publishHaibuJobs' | translate">
        </div>
    </div>
    <div *ngIf="step == 1;">

        <form [formGroup]="miFormulario">
            <div class="mb-4">
                <div class="green-subtitle mb-2">
                    {{ 'companyRegisterInfo' | translate }}
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    <div class="flex flex-col">
                        <label class="login-input-label" for="companyName">{{ 'companyNameRegister' | translate
                            }}</label>
                        <div class="flex">
                            <input id="companyName" formControlName="companyName" class="p-2 w-full mt-1 borde"
                                type="text" name="companyName" maxlength="100"
                                placeholder="{{ 'companyNameRegister' | translate }}"
                                oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
                        </div>
                        <div *ngIf="miFormulario.get('companyName').invalid && (miFormulario.get('companyName').dirty || miFormulario.get('companyName').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('companyName').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyNameRegister' | translate) } }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="login-input-label" for="companyReason">{{ 'companyReasonRegister' | translate
                            }}</label>
                        <div class="flex">
                            <input id="companyReason" formControlName="companyReason" class="p-2 w-full mt-1 borde"
                                type="text" name="companyReason" maxlength="100"
                                placeholder="{{ 'companyReasonRegister' | translate }}"
                                oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
                        </div>
                        <div *ngIf="miFormulario.get('companyReason').invalid && (miFormulario.get('companyReason').dirty || miFormulario.get('companyReason').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('companyReason').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyReasonRegister' | translate) } }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="login-input-label" for="companyRFC">{{ 'companyRFCRegister' | translate }}</label>
                        <div class="flex">
                            <input id="companyRFC" formControlName="companyRFC" class="p-2 w-full mt-1 borde"
                                type="text" name="companyRFC" maxlength="13"
                                placeholder="{{ 'companyRFCRegister' | translate }}"
                                oninput="this.value = this.value.replace(/[^a-zA-ZÑñ\d]/g, '').toUpperCase();" />
                        </div>
                        <div *ngIf="miFormulario.get('companyRFC').invalid && (miFormulario.get('companyRFC').dirty || miFormulario.get('companyRFC').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('companyRFC').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyRFCRegister' | translate) } }}
                            </span>
                            <span
                                *ngIf="!miFormulario.get('companyRFC').errors['required'] && miFormulario.get('companyRFC').errors['minlength']">
                                {{ 'invalidAtLeastLengthKeyForm' | translate: { key: ('companyRFCRegister' | translate),
                                number: 12 } }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col w-full">
                        <label class="login-input-label" for="companyCountry">{{ 'companyCountryRegister' | translate
                            }}</label>
                        <mat-select id="companyCountry" name="companyCountry" formControlName="companyCountry"
                            class="borde p-3 centered-text brown-border" (focus)="onFocus($event)"
                            (selectionChange)="changeCountry($event)"
                            placeholder="{{ 'companyCountryRegister' | translate}}" (blur)="onBlur($event)"
                            [disabled]="loadingCountries">
                            <mat-option *ngFor="let country of countries" [value]="country">
                                {{ country.ctR_NAME }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="miFormulario.get('companyCountry').invalid && (miFormulario.get('companyCountry').dirty || miFormulario.get('companyCountry').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('companyCountry').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyCountryRegister' | translate) }
                                }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col w-full"
                        *ngIf="miFormulario.get('companyCountry').value?.ctR_TWO_CHAR_CODE == 'MX'; else otherState">
                        <label class="login-input-label" for="companyState">{{ 'companyStateRegister' | translate
                            }}</label>
                        <mat-select id="companyState" name="companyState" formControlName="companyState"
                            (selectionChange)="loadCities($event)" class="borde p-3 centered-text brown-border"
                            (focus)="onFocus($event)" placeholder="{{ 'companyStateRegister' | translate }}"
                            (blur)="onBlur($event)" [disabled]="loadingCountries">
                            <mat-option *ngFor="let state of states; let i = index" [value]="state">
                                {{ state.stS_NAME }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="miFormulario.get('companyState').invalid && (miFormulario.get('companyState').dirty || miFormulario.get('companyState').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('companyState').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyStateRegister' | translate) } }}
                            </span>
                        </div>
                    </div>
                    <ng-template #otherState>
                        <div class="flex flex-col">
                            <label class="login-input-label" for="companyState">{{ 'companyStateRegister' | translate
                                }}</label>
                            <div class="flex">
                                <input id="companyState" formControlName="companyState" class="p-2 w-full mt-1 borde"
                                    type="text" name="companyState" maxlength="100"
                                    placeholder="{{ 'companyStateRegister' | translate }}"
                                    oninput='this.value = this.value.trimStart();' />
                            </div>
                            <div *ngIf="miFormulario.get('companyState').invalid && (miFormulario.get('companyState').dirty || miFormulario.get('companyState').touched)"
                                class="text-red-500 text-xs mt-1">
                                <span *ngIf="miFormulario.get('companyState').errors['required']">
                                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyStateRegister' | translate) }
                                    }}
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="flex flex-col w-full"
                        *ngIf="miFormulario.get('companyCountry').value?.ctR_TWO_CHAR_CODE == 'MX'; else otherCity">
                        <label class="login-input-label" for="companyCity">{{ 'companyCityRegister' | translate
                            }}</label>
                        <mat-select id="companyCity" name="companyCity" formControlName="companyCity"
                            class="borde p-3 centered-text brown-border" (focus)="onFocus($event)"
                            placeholder="{{ 'companyCityRegister' | translate }}" (blur)="onBlur($event)"
                            [disabled]="loadingCountries">
                            <mat-option *ngFor="let city of cities" [value]="city">
                                {{ city.ctY_NAME }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="miFormulario.get('companyCity').invalid && (miFormulario.get('companyCity').dirty || miFormulario.get('companyCity').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('companyCity').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyCityRegister' | translate) } }}
                            </span>
                        </div>
                    </div>
                    <ng-template #otherCity>
                        <div class="flex flex-col">
                            <label class="login-input-label" for="companyCity">{{ 'companyCityRegister' | translate
                                }}</label>
                            <div class="flex">
                                <input id="companyCity" formControlName="companyCity" class="p-2 w-full mt-1 borde"
                                    type="text" name="companyCity" maxlength="100"
                                    placeholder="{{ 'companyCityRegister' | translate }}"
                                    oninput='this.value = this.value.trimStart();' />
                            </div>
                            <div *ngIf="miFormulario.get('companyCity').invalid && (miFormulario.get('companyCity').dirty || miFormulario.get('companyCity').touched)"
                                class="text-red-500 text-xs mt-1">
                                <span *ngIf="miFormulario.get('companyCity').errors['required']">
                                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('companyCityRegister' | translate) }
                                    }}
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <div class="w-full col-span-1 md:col-span-2">
                        <label for="area" class="login-input-label">{{"clientDetailArea" | translate}}</label>
                        <mat-select id="clientDetailArea" name="area" formControlName="area"
                            class="borde p-3 centered-text brown-border" (focus)="onFocus($event)"
                            (blur)="onBlur($event)">
                            <mat-option *ngFor="let area of areas; let i = index" [value]="area.experienceAreaId">
                                {{ area.experienceAreaKey | translate }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="miFormulario.get('area').invalid && (miFormulario.get('area').dirty || miFormulario.get('area').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('area').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('areaRegister' |
                                translate) } }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="green-subtitle mb-2">
                    {{ 'companyUserInfo' | translate }}
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                    <div class="flex flex-col">
                        <label class="login-input-label" for="nombre">{{ 'userNameUser' | translate }}</label>
                        <div class="flex">
                            <input id="nombre" formControlName="userName" class="p-2 w-full mt-1 borde" type="text"
                                name="nombre" maxlength="100" placeholder="{{ 'userNameUser' | translate }}"
                                oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
                        </div>
                        <div *ngIf="miFormulario.get('userName').invalid && (miFormulario.get('userName').dirty || miFormulario.get('userName').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('userName').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userNameUser' | translate) } }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="login-input-label" for="userLastName">{{ 'userLastNameRegister' | translate
                            }}</label>
                        <div class="flex">
                            <input id="userLastName" formControlName="userLastName" class="p-2 w-full mt-1 borde"
                                type="text" name="userLastName" maxlength="100"
                                placeholder="{{ 'userLastNameRegister' | translate }}"
                                oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
                        </div>
                        <div *ngIf="miFormulario.get('userLastName').invalid && (miFormulario.get('userLastName').dirty || miFormulario.get('userLastName').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('userLastName').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userLastNameRegister' | translate) } }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="login-input-label" for="userSecondLastName">{{ 'userSecondLastNameRegister' |
                            translate }}</label>
                        <div class="flex">
                            <input id="userSecondLastName" formControlName="userSecondLastName"
                                class="p-2 w-full mt-1 borde" type="text" name="userSecondLastName" maxlength="100"
                                placeholder="{{ 'userSecondLastNameRegister' | translate }}"
                                oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
                        </div>
                        <div *ngIf="miFormulario.get('userSecondLastName').invalid && (miFormulario.get('userSecondLastName').dirty || miFormulario.get('userSecondLastName').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('userSecondLastName').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userSecondLastNameRegister' | translate)
                                } }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="login-input-label" for="userName">{{ 'userEmailUser' | translate }}</label>
                        <div class="flex">
                            <input id="userEmail" formControlName="userEmail" class="p-2 w-full mt-1 borde" type="text"
                                name="userName" maxlength="100" placeholder="{{ 'userEmailUser' | translate }}"
                                oninput='this.value = this.value.trimStart();' />
                        </div>
                        <div *ngIf="miFormulario.get('userEmail').invalid && (miFormulario.get('userEmail').dirty || miFormulario.get('userEmail').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('userEmail').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userEmailUser' | translate) } }}
                            </span>
                            <span *ngIf="miFormulario.get('userEmail').errors['email']">
                                {{ 'invalidEmailKeyForm' | translate }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="login-input-label" for="userPassword">{{ 'userPasswordRegister' | translate
                            }}</label>

                        <div class="flex items-center relative">
                            <div class="flex w-full">
                                <input id="userPassword" formControlName="userPassword" class="p-2 w-full mt-1 borde"
                                    [type]="hide2 ? 'password' : 'text'" name="userPassword" maxlength="100"
                                    placeholder="{{ 'userPasswordRegister' | translate }}"
                                    oninput='this.value = this.value.trimStart();' />
                            </div>
                            <!-- Ícono de visibilidad, colocado de manera absoluta -->
                            <mat-icon class="cursor-pointer icon-password" (click)="hide2 = !hide2"
                                style="position: absolute; right: 10px; top: 55%; transform: translateY(-50%);">
                                {{ hide2 ? 'visibility_off' : 'visibility' }}
                            </mat-icon>
                        </div>
                        <div *ngIf="miFormulario.get('userPassword').invalid && (miFormulario.get('userPassword').dirty || miFormulario.get('userPassword').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('userPassword').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userPasswordRegister' | translate) } }}
                            </span>
                            <span *ngIf="miFormulario.get('userPassword').errors['minlength']">
                                {{ 'addDialogPasswordAlertMin' | translate }}
                            </span>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label class="login-input-label" for="userConfirmPassword">{{ 'userConfirmPasswordRegister' |
                            translate }}</label>
                        <div class="flex items-center relative">
                            <div class="flex w-full">
                                <input id="userConfirmPassword" formControlName="userConfirmPassword"
                                    class="p-2 w-full mt-1 borde" [type]="hide ? 'password' : 'text'"
                                    name="userConfirmPassword" maxlength="100"
                                    placeholder="{{ 'userConfirmPasswordRegister' | translate }}"
                                    oninput='this.value = this.value.trimStart();' />
                            </div>
                            <!-- Ícono de visibilidad, colocado de manera absoluta -->
                            <mat-icon class="cursor-pointer icon-password" (click)="hide = !hide"
                                style="position: absolute; right: 10px; top: 55%; transform: translateY(-50%);">
                                {{ hide ? 'visibility_off' : 'visibility' }}
                            </mat-icon>
                        </div>
                        <div *ngIf="miFormulario.get('userConfirmPassword').invalid && (miFormulario.get('userConfirmPassword').dirty || miFormulario.get('userConfirmPassword').touched)"
                            class="text-red-500 text-xs mt-1">
                            <span *ngIf="miFormulario.get('userConfirmPassword').errors['required']">
                                {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userConfirmPasswordRegister' |
                                translate) } }}
                            </span>
                            <span
                                *ngIf="!miFormulario.get('userConfirmPassword').errors['required'] && miFormulario.get('userConfirmPassword').errors['noIguales']">
                                {{ 'passwordDialogPasswordMatch' | translate: { key: ('userConfirmPasswordRegister' |
                                translate) } }}
                            </span>
                        </div>
                    </div>
                    <!-- Numero -->
                    <div class="w-full col-span-1 md:col-span-2">
                        <div class="flex flex-col">
                            <label class="title-input-text" for="phone">
                                {{ 'userPhoneUser' | translate }}
                            </label>
                            <div class="w-full">
                                <app-phone-lada-select [phone]="miFormulario.get('userPhone').value"
                                    [placeholder]="'userPhoneUser' | translate"
                                    (phoneval)="miFormulario.get('userPhone').setValue($event)"></app-phone-lada-select>
                                <div *ngIf="miFormulario.get('userPhone').invalid && (miFormulario.get('userPhone').dirty || miFormulario.get('userPhone').touched)"
                                    class="text-red-500 text-xs mt-1">
                                    <span *ngIf="miFormulario.get('userPhone').errors['required']">
                                        {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userPhoneUser' | translate) } }}
                                    </span>
                                    <span *ngIf="miFormulario.get('userPhone').errors['minlength']">
                                        {{ 'candidatePhoneLength' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="step == 2;">
        <form [formGroup]="codeForm" class="text-center flex flex-col items-center">
            <div class="mb-3">
                <div class="green-title mb-2">
                    {{ 'companyCodeTitle' | translate }}
                </div>
                <div class="text-gray-400 text-xs">
                    {{ 'companyCodeMessage' | translate }}
                </div>
            </div>
            <div class="flex flex-col text-left w-full">
                <label class="login-input-label" for="code">{{ 'codeRegister' | translate
                    }}</label>
                <div class="flex">
                    <input id="code" formControlName="code" class="p-2 w-full mt-1 borde" type="text" name="code"
                        maxlength="100" placeholder="{{ 'codeRegister' | translate }}"
                        oninput='this.value = this.value.trimStart();' />
                </div>
                <div *ngIf="codeForm.get('code').invalid && (codeForm.get('code').dirty || codeForm.get('code').touched)"
                    class="text-red-500 text-xs mt-1">
                    <span *ngIf="codeForm.get('code').errors['required']">
                        {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('codeRegister' | translate) } }}
                    </span>
                </div>
            </div>
            <div class="mt-3 text-left text-sm"
                [ngClass]="isButtonDisabled ? 'text-gray-400' : 'text-gray-500 hover:text-gray-700'">
                <a (click)="resendCode()">{{ isButtonDisabled ? ('sendCodeAgain' | translate) + counter + ('secondsCode'
                    | translate) : ('sendCodeAgainBtn' | translate) }}</a>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="flex items-center w-full py-1 flex-col md:flex-row gap-y-2" [ngClass]="step == 0 ? '' : 'pb-5'"
        [ngClass]="step == 1 ? 'justify-center md:justify-between' : 'justify-center'">
        <div *ngIf="step == 1" class="flex flex-col items-center md:items-start">
            <div class="scale-center">
                <re-captcha #captchaComponent (resolved)="resolved($event)" name="captcha"
                    siteKey="6LdhgoYpAAAAAKlDW2aRdu6nH_Gmk0KTTU7tcoMr"></re-captcha>
            </div>
            <div *ngIf="miFormulario.get('recaptcha').invalid && (miFormulario.get('recaptcha').dirty || miFormulario.get('recaptcha').touched)"
                class="text-red-500 text-xs mt-1">
                <span *ngIf="miFormulario.get('recaptcha').errors['required']">
                    {{ 'recaptchaRegister' | translate }}
                </span>
            </div>
        </div>
        <div>
            <app-custom-primary-button id="createAccount" size="small" color="primary" (click)="onSubmit()">
                <span *ngIf="!sending && step == 0">{{"RegisterHaibuBtn" | translate}}</span>
                <span *ngIf="!sending && step == 1">{{"createAccountBtn" | translate}}</span>
                <span *ngIf="!sending && step == 2">{{"sendCodeVerify" | translate}}</span>
                <div *ngIf="sending" class="w-full flex justify-center">
                    <mat-spinner diameter="20" color="primary"></mat-spinner>
                </div>
            </app-custom-primary-button>
        </div>
    </div>
    <div *ngIf="step == 0;" class="text-center w-full">
        <div class="login-text-miss-password py-2">
            {{"haveAccountLogin" | translate}}
        </div>

        <p id="registerNew" class="login-recover-password" (click)="openLoginModal()">{{"loginAccount" | translate}}
        </p>
    </div>
</mat-dialog-actions>