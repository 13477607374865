import { AfterViewChecked, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Candidate } from 'src/app/interfaces/Candidate';
import { CandidateComment } from 'src/app/interfaces/CandidateComment';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocuViewerComponent } from '../docu-viewer/docu-viewer.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messager-chat',
  templateUrl: './messager-chat.component.html',
  styleUrls: ['./messager-chat.component.css']
})
export class MessagerChatComponent implements OnInit, AfterViewChecked {

  length = 0;
  pageSize = 1000;
  pageIndex = 0;
  candidate: Candidate;
  currentUser: string;
  commentList: CandidateComment[];
  userPermissions: string[];
  usuarios = [];
  id: string;
  mentionControl = new FormControl();
  usuariosFiltrados = this.usuarios;
  selectedUserNames: string[] = [];
  mentionedUserIds: string[] = [];
  formattedComment: string = '';
  isAutocompleteOpen: boolean = false;
  sendingComment: boolean = false;
  private hasScrolledToBottomOnce = false;
  selectedFile: File | null = null;
  selectedFileName: string | null = null;
  selectedFileType: string | null = null;
  userImage;
  sendIcon: SafeResourceUrl = '../../../assets/icons/send-icon.svg';
  cvLogo: SafeResourceUrl = '../../../assets/icons/cv-icon.svg';
  nocvLogo: SafeResourceUrl = '../../../assets/icons/noCV-icon.svg';
  phoneLogo: SafeResourceUrl = '../../../assets/icons/smartphone-candidate-icon.svg';
  whatsappLogo: SafeResourceUrl = '../../../assets/icons/whatsapp-icon-gray.svg';
  defaultpfp: SafeResourceUrl = '../../../assets/icons/default-pfp-icon.svg';
  params = new FormGroup({
    progress: new FormControl(),
    status: new FormControl(),
    name: new FormControl()
  });
  private readonly permittedExtensions = ['.jpg', '.jpeg', '.png', '.pdf'];
  private readonly maxFileSize = 15 * 1024 * 1024; // 15MB

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  @ViewChild('myTextArea') myTextArea: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('imageFileInput') imageFileInput: ElementRef;
  @ViewChild('pdfFileInput') pdfFileInput: ElementRef;

  constructor(public dialog: MatDialog,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<MessagerChatComponent>,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) { data }) {
    this.candidate = data;
    if (data?.candidateImageInfo?.rpC_IMG_ROUTE) {
      this.userImage = this.sanitizer.bypassSecurityTrustResourceUrl(environment.imageUrl + data.candidateImageInfo?.rpC_IMG_ROUTE);
    }
    const tokencito = this.localService.getJsonValue('token')
    this.currentUser = tokencito.data.id;
    this.userPermissions = tokencito.data.permissions.map(permission => permission.code);
  }

  ngOnInit(): void {
    this.usuariosFiltrados = [];
    this.getComments();
    this.hasScrolledToBottomOnce = true;
  }

  getUsers(): void {
    if (this.usuarios.length === 0) {
      this.puzzleService.getAllUsers(this.pageSize, this.pageIndex, null, 'Activos', this.id, null, true).subscribe(users => {

        this.usuarios = users
          .filter(user => user.id !== this.currentUser)
          .map(user => ({
            id: user.id,
            nombre: user.anU_FULL_NAME,
            email: user.email
          }));

        this.usuariosFiltrados = [...this.usuarios];

        try {
          this.length = users[0].totaL_COUNT;
        } catch (error) {
          this.length = 0;
        }
      }, error => {
        // Manejo de errores
      });
    }
  }


  openCv() {
    let dialog = this.dialog.open(DocuViewerComponent, {
      panelClass: 'custom-dialog-cv',
      data: { data: this.candidate, header: 'Curriculum vitáe' },
    });

    dialog.afterClosed().subscribe((result) => { });
  }

  openImage(imageUrl: string) {
    imageUrl = imageUrl['changingThisBreaksApplicationSecurity'];
    let dialog = this.dialog.open(DocuViewerComponent, {
      panelClass: 'custom-dialog-cv',
      maxWidth: '100vw',
      data: { data: imageUrl },
    });

    dialog.afterClosed().subscribe((result) => {
    });
  }

  closeAndNavigate() {
    this.dialog.closeAll();
  }

  getDateTimeFormat() {
    return this.translate.currentLang === 'eng' ? 'MM/dd/yy HH:mm' : 'dd/MM/yy HH:mm';
  }

  onInput(event: any): void {
    let currentText = this.mentionControl.value || ''; // Asegúrate de que `currentText` no sea `null`
    // hacer split por @ y tomar el último elemento
    if (currentText?.split(' ')?.length > 1) {
      currentText = currentText.split(' ').pop();
    }
    this.checkAndUpdateSelectedUsers(currentText);
    const cursorPosition = event.target.selectionStart;
    const textBeforeCursor = currentText.substring(0, cursorPosition);
    const lastAtPos = textBeforeCursor.lastIndexOf('@');

    if (lastAtPos !== -1) {
      this.isAutocompleteOpen = true; // Indica que el panel debe estar abierto
      this.autocompleteTrigger.openPanel(); // Abre el panel de autocompletado
      const wordAfterAt = currentText.substring(lastAtPos + 1, cursorPosition);
      if (this.usuarios.length === 0) {
        this.getUsers();
      }
      this.usuariosFiltrados = [...this.usuarios];
      // filtrar lista de usuarios
      this.usuariosFiltrados = this.usuarios.filter(usuario =>
        usuario.nombre.toLowerCase().includes(currentText.substring(lastAtPos + 1, cursorPosition).toLowerCase())
      );
    } else {
      this.usuariosFiltrados = [];
      this.isAutocompleteOpen = false; // Indica que el panel debe estar cerrado
      this.autocompleteTrigger.closePanel(); // Cierra el panel de autocompletado
    }

    if (this.mentionControl.value) {
      this.formatComment(this.mentionControl.value);
    }
  }

  formatComment(comment: string): string {
    if (comment) {
      let formatted = comment.replace(/^\n+|\n+$/g, '');
      // checar si el texto contiene un @
      const hasAt = formatted.includes('@');
      if (!hasAt) {
        this.formattedComment = formatted;
        return formatted;
      }

      // reemplazar los nombres de usuario con un span
      this.usuarios.forEach(usuario => {
        //actualizar mentionedUserIds
        if (formatted.includes(`@${usuario.nombre.trim()}`) && !this.mentionedUserIds.includes(usuario.id)) {
          this.mentionedUserIds.push(usuario.id);
        }
        const regex = new RegExp(`@${this.escapeRegExp(usuario.nombre.trim())}`, 'g');
        formatted = formatted.replace(regex, `<span class="text-primary">@${usuario.nombre.trim()}</span>`);
      });

      this.formattedComment = formatted;
      return formatted;
    } else {
      this.formattedComment = '';
      return '';
    }
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapa caracteres especiales
  }

  onEnterPress(event: KeyboardEvent): void {
    if (this.isAutocompleteOpen && this.usuariosFiltrados.length > 0) {
      const firstUser = this.usuariosFiltrados[0];
      this.onUserSelect(firstUser.nombre);
      event.preventDefault();
      this.isAutocompleteOpen = false; // Indica que el panel debe estar cerrado
      this.autocompleteTrigger.closePanel(); // Cierra el panel de autocompletado
    }
  }

  tempDisableAutocomplete(): void {
    this.mentionControl.disable();
    setTimeout(() => {
      this.mentionControl.enable();
    }, 400);
  }

  onUserSelect(selectedUserName: string): void {
    let textarea: HTMLTextAreaElement = this.myTextArea.nativeElement;
    let start = textarea.selectionStart;
    let end = textarea.selectionEnd;
    let text = this.mentionControl.value;
    let lastAtPos = text.lastIndexOf('@', start - 1)
    let beforeText = text.substring(0, lastAtPos);
    let afterText = text.substring(start, text.length);
    let newText = `${beforeText}@${selectedUserName.trim()}${afterText}`;

    this.selectedUserNames.push(selectedUserName.trim());
    const selectedUser = this.usuarios.find(user => user.nombre.trim() === selectedUserName.trim());
    if (selectedUser && !this.mentionedUserIds.includes(selectedUser.id)) {
      this.mentionedUserIds.push(selectedUser.id);
    }

    // this.updateFilteredUsers();
    setTimeout(() => {
      this.mentionControl.setValue(newText, { emitEvent: true });
      let newCursorPosition = beforeText.length + selectedUserName.length + 2;
      textarea.focus();
      textarea.selectionStart = textarea.selectionEnd = newCursorPosition;
    }, 0);

    this.isAutocompleteOpen = false; // Indica que el panel debe estar cerrado
    this.autocompleteTrigger.closePanel(); // Cierra el panel de autocompletado
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  ngAfterViewChecked() {
    if (!this.hasScrolledToBottomOnce) {
      this.scrollToBottom();
      this.hasScrolledToBottomOnce = true;
    }
  }

  onFileSelect(fileType: string): void {
    this.selectedFileType = fileType;
    if (fileType === 'image') {
      this.imageFileInput.nativeElement.click(); // Abre el selector de imágenes
      this.imageFileInput.nativeElement.value = null; // Limpiar el valor para permitir seleccionar la misma imagen
    } else if (fileType === 'pdf') {
      this.pdfFileInput.nativeElement.click(); // Abre el selector de PDFs
      this.pdfFileInput.nativeElement.value = null; // Limpiar el valor para permitir seleccionar el mismo PDF
    }
  }

  onFileChange(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      const fileSize = file.size;

      if (!this.permittedExtensions.includes(fileExtension)) {
        this.translate.get('fileTypeNotAllowed', { extensions: this.permittedExtensions.join(', ') }).subscribe((message: string) => {
          this.openResultDialog(0, message); // Mostrar diálogo de error
        });
        this.clearSelectedFile(); // Limpiar archivo no permitido
        return;
      }

      if (fileSize > this.maxFileSize) {
        this.translate.get('fileSizeExceeded', { maxSize: '15MB' }).subscribe((message: string) => {
          this.openResultDialog(0, message); // Mostrar diálogo de error
        });
        this.clearSelectedFile(); // Limpiar archivo que excede el tamaño
        return;
      }

      this.selectedFile = file;
      this.selectedFileName = file.name;
      this.selectedFileType = file.type.startsWith('image') ? 'image' : 'pdf';
    }
  }

  sendComment() {
    if (!this.sendingComment) {
      this.autocompleteTrigger.closePanel(); // Cerrar el panel de autocompletado si está abierto
      this.isAutocompleteOpen = false; // Indica que el panel debe estar cerrado
      let commentText = this.formatComment(this.mentionControl.value);
      this.mentionControl.setValue('');
      if (commentText != null && commentText.trim() != "") {
        this.sendingComment = true;
        let sc = {} as CandidateComment;
        sc.ccoM_CANDIDATE_ID = this.candidate.cdT_ID;
        sc.ccoM_COMMENT = commentText;
        sc.MentionedUserIds = this.mentionedUserIds;

        this.puzzleService.createCandidateComment(sc).subscribe(response => {
          if (response.isSuccess) {
            this.mentionedUserIds = [];
            this.mentionControl.setValue('');
            this.getComments();
          } else {
            this.openResultDialog(0, response.message);
          }
          this.sendingComment = false;
        });

        if (this.selectedFile && this.selectedFileName) {
          this.sendFileComment(this.selectedFile); // Enviar el archivo desde selectedFile
        } else {
          this.clearSelectedFile(); // Limpiar si no hay archivo válido para evitar confusiones
        }
      } else if (this.selectedFile && this.selectedFileName) {
        this.sendFileComment(this.selectedFile); // Enviar el archivo desde selectedFile
      } else {
        this.clearSelectedFile(); // Limpiar si no hay archivo válido para evitar confusiones
      }
    }
  }

  sendFileComment(file: File) {
    const formData: FormData = new FormData();
    formData.append('File', file);
    formData.append('CandidateId', this.candidate.cdT_ID);

    this.sendingComment = true;
    this.puzzleService.uploadCommentFile(formData).subscribe(response => {
      if (response.isSuccess) {
        this.getComments();
        this.clearSelectedFile();
      } else {
        this.openResultDialog(0, response.message);
      }
      this.sendingComment = false; // Restablecer la bandera después de enviar el archivo
    });
  }


  removeSelectedFile(event: MouseEvent): void {
    event.stopPropagation();
    this.clearSelectedFile();
  }

  clearSelectedFile(): void {
    this.selectedFile = null;
    this.selectedFileName = null;
    this.selectedFileType = null;
  }

  getComments() {
    if (!this.candidate?.cdT_ID) {
      this.dialogRef.close();
    }
    this.puzzleService.getCandidateComments(this.candidate.cdT_ID).subscribe(response => {
      this.commentList = response.map(comment => {
        let original = comment.ccoM_COMMENT.split(' - ')[0];
        let translatedText = this.translate.instant(original);
        // unir con el resto del comentario
        let newText = translatedText + comment.ccoM_COMMENT.substring(original.length);
        comment.ccoM_COMMENT = newText;

        if (comment.ccoM_IS_IMAGE) {
          // Generar URL segura para la imagen
          comment.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.imageUrl + comment.ccoM_COMMENT);
        }
        return comment;
      });
      this.hasScrolledToBottomOnce = false;
    });
  }

  checkAndUpdateSelectedUsers(currentText: string): void {
    let newSelectedUsers = [];
    for (let userName of this.selectedUserNames) {
      const userMention = `@${userName}`;
      if (currentText.includes(userMention)) {
        newSelectedUsers.push(userName);
      }
    }

    this.selectedUserNames = newSelectedUsers;
    this.mentionedUserIds = this.usuarios
      .filter(u => this.selectedUserNames.includes(u.nombre))
      .map(u => u.id);
    // this.updateFilteredUsers();
  }

  updateFilteredUsers(): void {
    this.usuariosFiltrados = this.usuarios.filter(usuario =>
      !this.selectedUserNames.includes(usuario.nombre)
    );
  }

  openResultDialog(mode: number, message?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0: {
        data.push('Error');
        data.push(message);
        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
      } break;
      case 1: {
        data.push('Exito');
        data.push(message);

        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          /* height: '400px', */
          data: data,
        });
      } break;
    }
  }

  getPermission(permission: string | string[]): boolean {
    if (typeof permission === 'string') {
      return this.userPermissions.includes(permission);
    } else {
      for (let i = 0; i < permission.length; i++) {
        if (this.userPermissions.includes(permission[i])) {
          return true;
        }
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const items = event.clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          if (blob) {
            const fileExtension = blob.name.substring(blob.name.lastIndexOf('.')).toLowerCase();
            const fileSize = blob.size;

            if (!this.permittedExtensions.includes(fileExtension)) {
              this.translate.get('fileTypeNotAllowed', { extensions: this.permittedExtensions.join(', ') }).subscribe((message: string) => {
                this.openResultDialog(0, message); // Mostrar diálogo de error
              });
              return;
            }

            if (fileSize > this.maxFileSize) {
              this.translate.get('fileSizeExceeded', { maxSize: '15MB' }).subscribe((message: string) => {
                this.openResultDialog(0, message); // Mostrar diálogo de error
              });
              return;
            }

            this.selectedFile = blob;
            this.selectedFileName = blob.name;
            this.selectedFileType = blob.type.startsWith('image') ? 'image' : 'pdf';
            this.onFileChange({ target: { files: [blob] } }); // Simular cambio de archivo
          }
        }
      }
    }
  }


  handlePastedImage(file: File): void {
    this.selectedFile = file;
    this.selectedFileName = file.name;
    this.selectedFileType = 'image';

    this.selectedFile = file;
    this.selectedFileName = file.name;
    this.selectedFileType = 'image';
  }

}
