<div tourAnchor="home.candidatelist.shareModal">
  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

  <div class="pb-2 text-center title-text-modal">
    {{ 'candidateShareMultiple' | translate }}
  </div>

  <div class="md:px-6 px-0 pt-3">
    <p class="descriptionDuplicateText">
      {{ 'candidateShareMultipleMessage' | translate }}
    </p>

    <div class="my-1">
      <form [formGroup]="miFormulario">
        <div class="py-1 w-full">
          <label class="pr-6 input-text">
            {{ 'changeStatusSubject' | translate }}
          </label>
          <!-- Contenedor flex para el input y el icono de edición -->
          <div class="flex justify-between items-center pb-4 w-full">
            <!-- Input que está deshabilitado por defecto -->
            <input id="changeStatusSubject" class="borde p-3 centered-text brown-border w-full"
              [placeholder]="'placeholderMessage' | translate" maxlength="2000" [disabled]="!editSubjectMode"
              oninput="this.value = this.value.trimStart();" formControlName="subject">

            <!-- Ícono de edición a la derecha -->
            <div class="cursor-pointer flex items-center ml-2" (click)="toggleEditSubject()">
              <img [src]="editIcon" alt="Icono" class="w-6 h-6">
            </div>
          </div>

          <!-- Mostrar error si es necesario -->
          <mat-error class="text-xs mt-1"
            *ngIf="miFormulario.get('subject')?.hasError('required') && miFormulario.get('subject').touched">
            {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('changeStatusSubject' | translate) } }}
          </mat-error>
        </div>
        <div class="py-1 w-full">
          <label class="pr-6 input-text">
            {{ 'changeStatusMessage' | translate }}
          </label>
          <div class="flex flex-col pb-4 w-full">
            <input id="changeStatusMessage" class="borde p-3 centered-text brown-border w-full"
              [placeholder]="'placeholderMessage' | translate" maxlength="2000"
              oninput="this.value = this.value.trimStart();" formControlName="message">
            <mat-error class="text-xs mt-1"
              *ngIf="miFormulario.get('message').hasError('required') && miFormulario.get('message').touched">
              {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('changeStatusMessage' | translate) } }}
            </mat-error>
          </div>
        </div>

        <!-- Participantes -->
        <div class="flex flex-col w-full">
          <div class="pb-1 title-input-text flex items-center">
            <span class="title-input-text">{{'usersOfYourCompany' | translate}}</span>
            <mat-icon [matTooltip]="'usersOfYourCompanyTooltip' | translate" class="help-icon">help</mat-icon>
          </div>
          <div>
            <mat-select id="addParticipantsShare" *ngIf="usuariosFiltrados.length > 0" [ngModel]="null"
              name="participantSelection" class="borde brown-border p-3 w-full centered-text"
              (selectionChange)="onParticipantSelected($event)" (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario">
                {{usuario.nombre}}
              </mat-option>
            </mat-select>
            <mat-error class="text-xs" *ngIf="emptyUsers && selectedUsers.length <= 0">
              {{ 'requiredFieldParticipants' | translate }}
            </mat-error>
          </div>
        </div>

        <!-- Participantes seleccionados -->
        <div class="flex flex-wrap">
          <span *ngFor="let user of selectedUsers;"
            class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
            <span>{{ user.nombre }}</span>
            <div class="flex items-center justify-center">
              <mat-icon id="removeUserVacancyDialog3" (click)="removeUser(user)"
                class="cursor-pointer close-x">cancel</mat-icon>
            </div>
          </span>
        </div>
      </form>
    </div>
  </div>

  <div class="md:px-6 px-0 flex justify-center pt-4">
    <app-custom-primary-button id="saveQuestions" type="submit" size="filter-size" color="primary" class="text-center"
      [textPadding]="'20px'" [textAlign]="'inherit'" (click)="shareCandidates()">
      <div class="w-full flex justify-center gap-2">
        <mat-spinner *ngIf="sending" diameter="20" color="primary"></mat-spinner>
        <span class="btn-label">{{ 'saveShareCandidates' | translate }}</span>
      </div>
    </app-custom-primary-button>
  </div>
</div>
