<div class="sticky w-full flex flex-col md:flex-row background-colorr">
    <div class="flex items-center justify-center w-full md:w-1/5">
        <a routerLink="/"><img class="h-16 w-auto mx-4" [src]="haibuIcon" alt=""></a>
    </div>
    <div class="flex justify-end w-full md:w-4/5 py-8 pr-4">
        <select (change)="changeLanguage($event.target.value)" [value]="currentLang"
            class="block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-2 py-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option value="es" [selected]="currentLang === 'esp'">Español</option>
            <option value="en" [selected]="currentLang === 'eng'">English</option>
        </select>
    </div>
</div>
<div class="py-6 px-6 md:px-20 text-justify">
    <div class="pb-4 flex flex-col items-center justify-center">
        <div class="font-medium text-center text-3xl">
            {{ 'cookiesTitle' | translate }}
        </div>
    </div>
    <div>
        <div [innerHTML]="part1"></div>
        <!-- Tabla de cookies -->
        <div>
            <div class="hidden md:block my-3">
                <mat-table [dataSource]="dataSource">

                    <!-- Nombre Column -->
                    <ng-container matColumnDef="nombre">
                        <mat-header-cell *matHeaderCellDef> {{ 'nameCookie' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.nombre }} </mat-cell>
                    </ng-container>

                    <!-- Tipo Column -->
                    <ng-container matColumnDef="tipo">
                        <mat-header-cell *matHeaderCellDef> {{ 'typeCookie' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.tipo }} </mat-cell>
                    </ng-container>

                    <!-- Proveedor Column -->
                    <ng-container matColumnDef="proveedor">
                        <mat-header-cell *matHeaderCellDef> {{ 'providerCookie' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.proveedor }} </mat-cell>
                    </ng-container>

                    <!-- Caduca Column -->
                    <ng-container matColumnDef="caduca">
                        <mat-header-cell *matHeaderCellDef> {{ 'expireCookie' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.caduca }} </mat-cell>
                    </ng-container>

                    <!-- Descripción Column -->
                    <ng-container matColumnDef="descripcion">
                        <mat-header-cell *matHeaderCellDef class="expand"> {{ 'descriptionCookie' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="expand"> {{ element.descripcion }} </mat-cell>
                    </ng-container>

                    <!-- Header and Row Definitions -->
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </div>
            <!-- Contenedor para móviles -->
            <div class="block md:hidden my-3">
                <div *ngFor="let element of dataSource" class="border rounded-lg shadow-md p-4 mb-4 bg-white">
                    <!-- Nombre -->
                    <div class="flex justify-between">
                        <span class="font-bold">{{ 'nameCookie' | translate }}:</span>
                        <span>{{ element.nombre }}</span>
                    </div>
                    <!-- Tipo -->
                    <div class="flex justify-between mt-2">
                        <span class="font-bold">{{ 'typeCookie' | translate }}:</span>
                        <span>{{ element.tipo }}</span>
                    </div>
                    <!-- Proveedor -->
                    <div class="flex justify-between mt-2">
                        <span class="font-bold">{{ 'providerCookie' | translate }}:</span>
                        <span>{{ element.proveedor }}</span>
                    </div>
                    <!-- Caduca -->
                    <div class="flex justify-between mt-2">
                        <span class="font-bold">{{ 'expireCookie' | translate }}:</span>
                        <span>{{ element.caduca }}</span>
                    </div>
                    <!-- Descripción -->
                    <div class="mt-2">
                        <span class="font-bold block">{{ 'descriptionCookie' | translate }}:</span>
                        <p class="text-sm text-gray-700">{{ element.descripcion }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div [innerHTML]="part2"></div>
    </div>
</div>