import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-features-dialog',
  templateUrl: './features-dialog.component.html',
  styleUrls: ['./features-dialog.component.css']
})
export class FeaturesDialogComponent implements OnInit {

  actualPlan: string;

  constructor(
    private dialogRef: MatDialogRef<FeaturesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.plan) {
      this.actualPlan = data.plan;
    }
  }

  ngOnInit(): void {
  }

  closeModal(value: boolean = false) {
    this.dialogRef.close(value);
  }
}
