import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '../../services/constants';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.css']
})
export class RegisterDialogComponent implements OnInit {

  miFormulario: FormGroup = this.fb.group({
    companyName: ['', [Validators.required]],
    companyReason: ['', Validators.required],
    companyRFC: ['', [Validators.required, Validators.minLength(12)]],
    companyCountry: ['', Validators.required],
    companyState: ['', Validators.required],
    companyCity: ['', Validators.required],
    userName: ['', [Validators.required]],
    userLastName: ['', [Validators.required]],
    userSecondLastName: ['', [Validators.required]],
    userEmail: ['', [Validators.required, Validators.email]],
    userPhone: ['', [Validators.required, Validators.minLength(10)]],
    userPassword: ['', [Validators.required, Validators.minLength(6)]],
    userConfirmPassword: ['', [Validators.required]],
    recaptcha: ['', Validators.required],
    area: ['', Validators.required],
  },
    {
      validators: [
        this.validatorService.camposIguales('userPassword', 'userConfirmPassword'),
      ],
    });

  codeForm: FormGroup = this.fb.group({
    code: ['', [Validators.required]]
  });

  @ViewChild('captchaComponent') captchaComponent: RecaptchaComponent;

  countries = [];
  loadingCountries = false;
  states = [];
  cities = [];
  customstatesandcities = false;
  sending = false;
  step = 1;
  isButtonDisabled = false;
  counter: number = 30;
  interval$: Subscription | undefined;
  email: string;
  hide: boolean = true;
  hide2: boolean = true;
  areas: any[] = [];
  isJobs = false;
  plan: string;

  constructor(
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private validatorService: ValidatorService,
    private renderer: Renderer2,
    private localService: LocalService,
    private dialogRef: MatDialogRef<RegisterDialogComponent>,
    public dialog: MatDialog,
    private translate: TranslateService,
    private router: Router,
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.email) {
      this.email = data.email;
    }
    if (data?.prev) {
      this.step = 0;
    }
    if (data?.isJobs) {
      this.isJobs = true;
    }

    if (data?.plan) {
      this.plan = data.plan;
    }
  }

  ngOnInit(): void {
    this.getCountries();
    if (this.step == 0) {
      this.dialogRef.updateSize('500px');
    }
    if (this.email) {
      this.step = 2;
      this.dialogRef.updateSize('400px');
      this.miFormulario.get('userEmail').setValue(this.email);
    }
    this.puzzleService.getCandidateExperienceAreas().subscribe(areas => {
      this.areas = areas;
    });
  }

  getCountries() {
    this.loadingCountries = true;
    this.puzzleService.getCountries().subscribe((response: any) => {
      this.countries = response;
      this.loadStates(141);
      // buscar el ctR_TWO_CHAR_CODE MX y seleccionarlo
      const mexico = this.countries.find(country => country.ctR_TWO_CHAR_CODE === 'MX');
      this.miFormulario.get('companyCountry').setValue(mexico);
      this.loadingCountries = false;
    });
  }

  loadStates(id: number) {
    // this.company.comP_ADDRESS_ID_INFO.adR_CITY_CUSTOM = null;
    // this.company.comP_ADDRESS_ID_INFO.adR_STATE_CUSTOM = null;
    // this.company.comP_ADDRESS_ID_INFO.adR_CITY_ID = null;
    // this.company.comP_ADDRESS_ID_INFO.adR_STATE_ID = null;

    if (this.countries[id].ctR_CUSTOM_INFO_ACTIVE == true) {
      this.customstatesandcities = true;
    } else {
      this.customstatesandcities = false;
      this.states = this.countries[id].ctR_STATES;
    }
  }

  changeCountry(event: any) {
    this.miFormulario.get('companyState').setValue('');
    this.miFormulario.get('companyCity').setValue('');
    const country = event.value;
    const id = this.countries.findIndex(c => c.ctR_ID == country.ctR_ID);
    this.loadStates(id);
  }

  loadCities(event: any) {
    this.miFormulario.get('companyCity').setValue('');
    const state = event.value;
    const id = this.states.findIndex(s => s.stS_ID == state.stS_ID);
    this.cities = this.states[id].stS_CITIES;
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

  resolved(captchaResponse: string) {
    this.miFormulario.get('recaptcha').setValue(captchaResponse);
  }

  onSubmit() {
    if (this.sending) {
      return;
    }

    if (this.step == 0) {
      this.step = 1;
      this.dialogRef.updateSize('700px');
    } else if (this.step == 1) {
      if (this.miFormulario.invalid) {
        this.miFormulario.markAllAsTouched();
        return;
      }

      this.sending = true;
      let stateId = false;
      let cityId = false;

      let country = this.miFormulario.get('companyCountry').value;
      if (country.ctR_ID) {
        country = country.ctR_ID;
      }

      let state = this.miFormulario.get('companyState').value;
      if (state.stS_ID) {
        state = state.stS_ID;
        stateId = true;
      }

      let city = this.miFormulario.get('companyCity').value;
      if (city.ctY_ID) {
        city = city.ctY_ID;
        cityId = true;
      }

      const registerData = {
        company: {
          COMP_NAME: this.miFormulario.get('companyName').value,
          COMP_BUSINESS_NAME: this.miFormulario.get('companyReason').value,
          COMP_RFC: this.miFormulario.get('companyRFC').value,
          COMP_AREA_ID: this.miFormulario.get('area').value
        },
        address: {
          ADR_COUNTRY_ID: country,
          ADR_STATE_ID: stateId ? state : null,
          ADR_CITY_ID: cityId ? city : null,
          ADR_STATE_CUSTOM: stateId ? null : state,
          ADR_CITY_CUSTOM: cityId ? null : city,
        },
        user: {
          ANU_NAME: this.miFormulario.get('userName').value,
          ANU_LAST_NAME: this.miFormulario.get('userLastName').value,
          ANU_SECOND_LAST_NAME: this.miFormulario.get('userSecondLastName').value,
          Email: this.miFormulario.get('userEmail').value,
          LanguagePreference: this.localService.getJsonValue('lang') || 'es',
          PhoneNumber: this.miFormulario.get('userPhone').value,
          Password: this.miFormulario.get('userPassword').value,
          ConfirmPassword: this.miFormulario.get('userConfirmPassword').value
        },
        recaptcha: this.miFormulario.get('recaptcha').value
      }

      if (!this.plan || this.isJobs) {
        if (this.isJobs) {
          this.puzzleService.registerFreeJobs(registerData).subscribe(response => {
            if (response.isSuccess) {
              this.step = 2;
              // actualizar tamaño del dialog 400px de anchura
              this.dialogRef.updateSize('400px');
            } else {
              if (response.message == 'User already exists' || (response?.data && response.data[0]?.code == 'DuplicateUserName')) {
                this.openResponseDialog('Error', this.translate.instant('registerDuplicateEmail'));
              } else if (response.message == 'Captcha verification failed.') {
                this.openResponseDialog('Error', this.translate.instant('registerCaptchaError'));
                this.captchaComponent.reset();
              } else {
                this.openResponseDialog('Error', this.translate.instant('registerError'));
              }
            }
            this.sending = false;
          },
            error => {
              if (error.error?.message == 'Captcha verification failed.') {
                this.openResponseDialog('Error', this.translate.instant('registerCaptchaError'));
                this.captchaComponent.reset();
              } else {
                this.openResponseDialog('Error', this.translate.instant('registerError'));
              }
              this.sending = false;
            }
          );
        } else {
          this.puzzleService.registerFreeUser(registerData).subscribe(response => {
            if (response.isSuccess) {
              this.step = 2;
              // actualizar tamaño del dialog 400px de anchura
              this.dialogRef.updateSize('400px');
            } else {
              if (response.message == 'User already exists' || (response?.data && response.data[0]?.code == 'DuplicateUserName')) {
                this.openResponseDialog('Error', this.translate.instant('registerDuplicateEmail'));
              } else if (response.message == 'Captcha verification failed.') {
                this.openResponseDialog('Error', this.translate.instant('registerCaptchaError'));
                this.captchaComponent.reset();
              } else {
                this.openResponseDialog('Error', this.translate.instant('registerError'));
              }
            }
            this.sending = false;
          },
            error => {
              if (error.error?.message == 'Captcha verification failed.') {
                this.openResponseDialog('Error', this.translate.instant('registerCaptchaError'));
                this.captchaComponent.reset();
              } else {
                this.openResponseDialog('Error', this.translate.instant('registerError'));
              }
              this.sending = false;
            }
          );
        }
      } else {
        // add COMP_CURRENT_PLAN to company object
        registerData.company['COMP_CURRENT_PLAN'] = this.plan;

        this.puzzleService.registerFreeTrialCompany(registerData).subscribe(response => {
          if (response.isSuccess) {
            this.step = 2;
            // actualizar tamaño del dialog 400px de anchura
            this.dialogRef.updateSize('400px');
          } else {
            if (response.message == 'User already exists' || (response?.data && response.data[0]?.code == 'DuplicateUserName')) {
              this.openResponseDialog('Error', this.translate.instant('registerDuplicateEmail'));
            } else if (response.message == 'Captcha verification failed.') {
              this.openResponseDialog('Error', this.translate.instant('registerCaptchaError'));
              this.captchaComponent.reset();
            } else {
              this.openResponseDialog('Error', this.translate.instant('registerError'));
            }
          }
          this.sending = false;
        },
          error => {
            if (error.error?.message == 'Captcha verification failed.') {
              this.openResponseDialog('Error', this.translate.instant('registerCaptchaError'));
              this.captchaComponent.reset();
            } else {
              this.openResponseDialog('Error', this.translate.instant('registerError'));
            }
            this.sending = false;
          }
        );
      }
    } else {
      if (this.codeForm.invalid) {
        this.codeForm.markAllAsTouched();
        return;
      }

      this.sending = true;

      const verifyData = {
        Email: this.miFormulario.get('userEmail').value,
        VerificationCode: this.codeForm.get('code').value
      }

      this.puzzleService.verifyAccount(verifyData).subscribe(response => {
        if (response.isSuccess) {
          this.openResponseDialog('Exito', this.translate.instant('registerSuccess'));
          this.localService.setJsonValue('token', response);
          let token = this.localService.getJsonValue('token');
          this.puzzleService.setToken(token);

          if (token.data.roleId == constants.superadmin) {
            this.router.navigate(['home/clientlist']);
          } else {
            this.router.navigate(['home/dashboard']);
          }
          this.dialogRef.close();
        } else {
          this.openResponseDialog('Error', this.translate.instant('registerErrorCode'));
        }
        this.sending = false;
      });
    }
  }

  openResponseDialog(response: string, message?: string, extra?: string) {
    let data: string[] = [];
    if (response == 'Error') {
      data.push('Error');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    } else if (response == 'Exito') {
      data.push('Exito');
      data.push(message);
      if (extra) data.push(extra);
      const dialogRef = this.dialog.open(ResponseDialogComponent, {
        width: '500px',
        /* height: '400px', */
        data: data,
      });
    }
  }

  resendCode() {
    if (this.isButtonDisabled) {
      return;
    }
    // Acción de reenviar código aquí
    this.startTimer();
    const resendData = {
      Email: this.miFormulario.get('userEmail').value
    }
    this.puzzleService.sendVerificationCode(resendData).subscribe(response => {
      if (!response.isSuccess) {
        this.openResponseDialog('Error', this.translate.instant('registerResendCodeError'));
      }
    });
  }

  startTimer() {
    this.isButtonDisabled = true;
    this.counter = 120; // Temporizador de 120 segundos

    this.interval$ = interval(1000).subscribe(() => {
      this.counter--;
      if (this.counter === 0) {
        this.isButtonDisabled = false;
        this.interval$?.unsubscribe(); // Detener el temporizador
      }
    });
  }

  openLoginModal() {
    this.loginService.openLoginModal(this.isJobs);
    this.dialogRef.close();
  }
}
