<div class="ml-auto w-full text-right justify-end -mt-5 -mr-5">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<div class="text-xl text-center" style="color: #28727D;">
    <div>{{ 'paymentSuccessFeatures' | translate }}</div>
</div>

<div class="mt-4 mb-5">
    <div class="text-modal">{{ 'planGainedFeatures' | translate: { plan: (actualPlan | translate) } }}</div>
    <!-- Features -->
    <div class="text-left text-primary text-sm font-medium mt-4">
        <ul *ngIf="actualPlan?.includes('Entry')" class="list-disc pl-5 space-y-1">
            <li>{{ 'features.1' | translate }}</li>
            <li>{{ 'features.2' | translate }}</li>
            <li>{{ 'features.3' | translate }}*</li>
            <li>{{ 'features.4' | translate }}*</li>
            <li>{{ 'features.6' | translate }}</li>
            <li>{{ 'features.7' | translate }}</li>
            <li>{{ 'features.13' | translate }}</li>
            <li>{{ 'features.16' | translate }}</li>
        </ul>
        <ul *ngIf="actualPlan?.includes('Head Hunter') && !actualPlan?.includes('Mid')" class="list-disc pl-5 space-y-1">
            <li>{{ 'features.1' | translate }}</li>
            <li>{{ 'features.2' | translate }}</li>
            <li>{{ 'features.3' | translate }}*</li>
            <li>{{ 'features.4' | translate }}*</li>
            <li>{{ 'features.6' | translate }}</li>
            <li>{{ 'features.7' | translate }}</li>
            <li>{{ 'features.13' | translate }}</li>
            <li>{{ 'features.10' | translate }}</li>
            <li>{{ 'features.11' | translate }}</li>
            <li>{{ 'features.12' | translate }}</li>
            <li>{{ 'features.14' | translate }}</li>
            <li>{{ 'features.16' | translate }}</li>
        </ul>
        <ul *ngIf="actualPlan?.includes('Mid') && !actualPlan?.includes('Head Hunter')" class="list-disc pl-5 space-y-1">
            <li>{{ 'features.1' | translate }}</li>
            <li>{{ 'features.2' | translate }}</li>
            <li>{{ 'features.3' | translate }}*</li>
            <li>{{ 'features.4' | translate }}*</li>
            <li>{{ 'features.6' | translate }}</li>
            <li>{{ 'features.7' | translate }}</li>
            <li>{{ 'features.13' | translate }}</li>
            <li>{{ 'features.8' | translate }}</li>
            <li>{{ 'features.17' | translate }}</li>
            <li>{{ 'features.18' | translate }}</li>
            <li>{{ 'features.15' | translate }}</li>
            <li>{{ 'features.16' | translate }}</li>
        </ul>
        <ul *ngIf="actualPlan?.includes('Mid + Head Hunter')" class="list-disc pl-5 space-y-1">
            <li>{{ 'features.1' | translate }}</li>
            <li>{{ 'features.2' | translate }}</li>
            <li>{{ 'features.3' | translate }}*</li>
            <li>{{ 'features.4' | translate }}*</li>
            <li>{{ 'features.6' | translate }}</li>
            <li>{{ 'features.7' | translate }}</li>
            <li>{{ 'features.13' | translate }}</li>
            <li>{{ 'features.8' | translate }}</li>
            <li>{{ 'features.17' | translate }}</li>
            <li>{{ 'features.18' | translate }}</li>
            <li>{{ 'features.15' | translate }}</li>
            <li>{{ 'features.10' | translate }}</li>
            <li>{{ 'features.11' | translate }}</li>
            <li>{{ 'features.12' | translate }}</li>
            <li>{{ 'features.14' | translate }}</li>
            <li>{{ 'features.16' | translate }}</li>
        </ul>
    </div>
</div>

<div class="flex justify-center pb-2 px-2 md:px-0">
    <div class="w-full md:w-auto flex flex-col items-center justify-center">
        <app-custom-primary-button id="responseAnchorDialogAccept" color="primary" size="small" class="mx-auto"
            (click)="closeModal()">
            {{ 'responseDialogAccept' | translate }}
        </app-custom-primary-button>
    </div>
</div>

<div class="text-center mt-1">
    <div class="text-note">
        {{ 'priceNote2' | translate }}
    </div>
</div>