<div class="container mx-auto p-4">
  <div class="flex flex-col">
    <h1 class="text-left title-text mb-2">{{ 'updateTitle' | translate }}</h1>

    <h2 class="text-left title-text mb-1">{{ 'updateDate27' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription59' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription60' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate26' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription57' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription58' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate25' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription55' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription56' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate24' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription53' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription54' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate23' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription51' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription52' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate22' | translate }}</h2>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription50' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate21' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription48' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription49' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate20' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription46' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription47' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate19' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription44' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription45' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate18' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription42' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription43' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate17' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription40' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription41' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate16' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription38' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription39' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate15' | translate }}</h2>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription36' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription37' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate14' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription34' | translate }}</h3>
    <h3 class="text-left color-counter text-md" [innerHTML]="'updateDescription35' | translate"></h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate13' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription32' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription33' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate12' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription30' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription31' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate11' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription27' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription28' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription29' | translate }}</h3>


    <h2 class="text-left title-text mb-1">{{ 'updateDate10' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription26' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate9' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription23' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription24' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate8' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription21' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription22' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate7' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription19' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription20' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate6' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription13' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription14' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription15' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription16' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription17' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription18' | translate }}</h3>


    <h2 class="text-left title-text mb-1">{{ 'updateDate5' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription11' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription12' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate4' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription10' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate3' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription8' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription9' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate2' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription5' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription6' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription7' | translate }}</h3>

    <h2 class="text-left title-text mb-1">{{ 'updateDate' | translate }}</h2>

    <h3 class="text-left color-counter text-md">{{ 'updateDescription1' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription2' | translate }}</h3>
    <h3 class="text-left color-counter text-md">{{ 'updateDescription4' | translate }}</h3>

  </div>
</div>
