<div class="w-full h-full relative">

  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

  <div class="pb-2 text-xl w-3/4 candidate-title">
    {{ 'messagerChatTitle' | translate }}
  </div>

  <div class="flex items-center gap-4 pb-2">
    <!-- Foto perfil -->
    <div class="flex">
      <div class="w-14 h-14 relative">
        <!-- Borde redondo externo -->
        <div class="w-14 h-14 absolute rounded-full border image-container z-10"></div>
        <!-- Imagen del perfil del usuario -->
        <img *ngIf="userImage" class="w-14 h-14 absolute rounded-full object-cover" [src]="userImage" alt="">
        <img *ngIf="!userImage" class="w-14 h-14 absolute rounded-full object-cover" [src]="defaultpfp" alt="">
      </div>
    </div>
    <!-- Foto perfil END -->

    <div class="flex flex-col justify-center w-full">
      <div class="text-xl w-3/4 candidate-name ">
        <a *ngIf="candidate" [routerLink]="['/home/candidatedetail']" [queryParams]="{ id: candidate.cdT_ID }"
          (click)="closeModal()">
          {{candidate.cdT_NAME}} {{candidate.cdT_PATERNAL_SURNAME}} {{candidate.cdT_MATERNAL_SURNAME}}
        </a>
      </div>

      <div class="text-xl w-full candidate-job flex justify-between items-center">
        <span *ngIf="candidate">{{candidate.cdT_JOB_INFO.joB_NAME}}</span>
        <ng-container *ngIf="candidate.cv && candidate.cv.cdoC_ACTIVE; else noCv">
          <img id="openCvMessageChat" [src]="cvLogo" class="cursor-pointer action-icons" (click)="openCv()"
            [matTooltip]="'candidateSeeCV' | translate:{name: candidate.cdT_NAME}">
        </ng-container>
        <ng-template #noCv>
          <div class="icon-container cv-link-container ml-4 flex items-center justify-center option-hover-effect">
            <a id="closeAndNavigateCandidate" routerLink="/home/candidatedetail"
              [queryParams]="{id: candidate.cdT_ID, select: 'button2'}"
              [matTooltip]="'candidateAddCV' | translate:{name: candidate.cdT_NAME}" (click)="closeAndNavigate()">
              <img [src]="nocvLogo" class="cursor-pointer action-icons">
            </a>
          </div>
        </ng-template>
      </div>
      <!--     <div class="pb-2 text-xl w-1/4 text-right">
      N°: <span *ngIf="candidate">{{candidate.cdT_NUMBER}}</span>
    </div> -->
    </div>
  </div>

  <div #scrollMe class="mt-2 container-chat"
    style="max-height: 50vh; height: 50vh; overflow-y: auto; scroll-behavior: smooth;">
    <div *ngIf="commentList" class="w-full h-full">
      <div *ngFor="let comment of commentList" class="text-xs">
        <!-- Comentarios del usuario actual (currentUser) -->
        <div *ngIf="comment.ccoM_CREATE_BY==currentUser"
          class="w-3/5 my-1 float-right current-user-bubble chat-text text-white  px-4 py-1 talk-bubble tri-right right-top">
          <div class="pb-2">
            <span class="chat-text">{{comment.ccoM_CREATE_BY_INFO.anU_FULL_NAME}} <span class="date-text">
                {{comment.ccoM_CREATED_DATE | date:getDateTimeFormat()}} hrs
              </span></span>
          </div>

          <!-- Mostrar imagen o icono de PDF si es un archivo -->
          <div *ngIf="comment.ccoM_IS_IMAGE; else textContent">
            <ng-container *ngIf="comment.imageUrl && comment.imageUrl.toString().includes('.pdf'); else showImage">
              <div class="file-indicator cursor-pointer" (click)="openImage(comment.imageUrl)">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>File.pdf</span>
              </div>
            </ng-container>
            <ng-template #showImage>
              <img [src]="comment.imageUrl" alt="Imagen Comentario" class="comment-image-thumbnail cursor-pointer"
                (click)="openImage(comment.imageUrl)">
            </ng-template>
          </div>

          <!-- Mostrar texto del comentario -->
          <ng-template #textContent>
            <pre class="comment-text" [innerHTML]="comment.ccoM_COMMENT | linkify"></pre>
          </ng-template>
        </div>

        <!-- Comentarios de otros usuarios -->
        <div *ngIf="comment.ccoM_CREATE_BY!=currentUser"
          class="rounded-md w-3/5 my-1 other-user-bubble chat-text px-4 py-1 talk-bubble tri-right left-top">
          <div class="pb-2 flex items-start md:items-center flex-col md:flex-row">
            <span class="other-user-name">{{comment.ccoM_CREATE_BY_INFO.anU_FULL_NAME}}
              <span class="other-user-date-text">
                {{comment.ccoM_CREATED_DATE | date:getDateTimeFormat()}} hrs
              </span>
            </span>
            <app-phone-dropdown *ngIf="comment.ccoM_CREATE_BY_INFO.phoneNumber; else noPhoneNumber"
              [phone]="comment.ccoM_CREATE_BY_INFO.phoneNumber"
              [tooltip]="'myProfileUserPhoneCall' | translate:{name: comment.ccoM_CREATE_BY_INFO.anU_FULL_NAME}">
              <ng-template>
                <div class="flex items-center md:pl-2">
                  <img [src]="phoneLogo" class="cursor-pointer action-icons">
                  <span class="other-user-date-text cursor-pointer action-icons">{{'myProfileUserPhoneCall' |
                    translate}}</span>
                </div>
              </ng-template>
            </app-phone-dropdown>
            <ng-template #noPhoneNumber>
              <div class="flex items-center pl-2">
                <span>{{'myProfileUserNoPhone' | translate}}</span>
              </div>
            </ng-template>
          </div>
          <!-- Mostrar imagen o icono de PDF si es un archivo -->
          <div *ngIf="comment.ccoM_IS_IMAGE; else textContentOther">
            <ng-container *ngIf="comment.imageUrl && comment.imageUrl.toString().includes('.pdf'); else showImageOther">
              <div class="file-indicator cursor-pointer" (click)="openImage(comment.imageUrl)">
                <mat-icon>picture_as_pdf</mat-icon>
                <span>File.pdf</span>
              </div>
            </ng-container>
            <ng-template #showImageOther>
              <img [src]="comment.imageUrl" alt="Imagen Comentario" class="comment-image-thumbnail cursor-pointer"
                (click)="openImage(comment.imageUrl)">
            </ng-template>
          </div>

          <!-- Mostrar texto del comentario -->
          <ng-template #textContentOther>
            <pre class="comment-text" [innerHTML]="comment.ccoM_COMMENT | linkify"></pre>
          </ng-template>
        </div>
      </div>
    </div>
    <div *ngIf="!commentList" class="w-full h-full flex items-center justify-center">
      <div class="loader"></div>
    </div>
  </div>
  <div class="text-xs text-gray-600 whitespace-normal break-words bg-primary10 rounded-lg px-2 py-1.5 my-2">
    {{ 'tagUsersTooltip' | translate }}
  </div>
  <div class="flex items-center text-center justify-center">
    <mat-form-field [formGroup]="params" appearance="outline" class="flex-grow rounded-lg custom-textarea">
      <div class="relative textarea-container brown-border">
        <textarea id="messagerChatTextArea" #myTextArea #autoTrigger="matAutocompleteTrigger" matInput [matAutocomplete]="auto" [formControl]="mentionControl"
          [placeholder]="'messagerChatPlaceHolder' | translate" (input)="onInput($event)"
          (keydown.enter)="onEnterPress($event)" (paste)="onPaste($event)" [maxlength]="2000" (focus)="onInput($event)">
        </textarea>

        <!-- Ícono de archivo seleccionado -->
        <div *ngIf="selectedFileName" class="file-indicator" matTooltip="Archivo seleccionado: {{selectedFileName}}">
          <mat-icon>{{ selectedFileType === 'image' ? 'image' : 'picture_as_pdf' }}</mat-icon>
          <span>{{ selectedFileName }}</span>

          <!-- Ícono de cancelar archivo -->
          <div class="cancel-icon" (click)="removeSelectedFile($event)">
            <mat-icon>close</mat-icon>
          </div>
        </div>
      </div>

      <mat-autocomplete id="usuariosFiltradosChat" #auto="matAutocomplete" class="custom-autocomplete-panel"
        (opened)="mentionControl.value == '' ? '' : isAutocompleteOpen = true" (closed)="isAutocompleteOpen = false">
        <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario.nombre"
          (onSelectionChange)="onUserSelect(usuario.nombre)">
          {{usuario.nombre}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button mat-icon-button [matMenuTriggerFor]="fileMenu" class="center-icon">
      <mat-icon>attach_file</mat-icon>
    </button>

    <mat-menu #fileMenu="matMenu">
      <button mat-menu-item (click)="onFileSelect('image')" class="padding-force">
        <mat-icon>image</mat-icon>
        <span class="select-file">{{ 'messagerChatImage' | translate }}</span>
      </button>
      <button mat-menu-item (click)="onFileSelect('pdf')" class="padding-force-right">
        <mat-icon>picture_as_pdf</mat-icon>
        <span class="select-file">{{ 'messagerChatPDF' | translate }}</span>
      </button>
    </mat-menu>

    <button id="sendCommentChat" type="button" class="btn ml-2" [disabled]="sendingComment" (click)="sendComment()">
      <img [src]="sendIcon" alt="send" width="24" height="24">
    </button>

  </div>
</div>
<input type="file" #imageFileInput accept="image/*" (change)="onFileChange($event)" style="display: none;" />

<!-- Input para seleccionar PDFs -->
<input type="file" #pdfFileInput accept=".pdf" (change)="onFileChange($event)" style="display: none;" />