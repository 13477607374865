import { Component, OnInit } from '@angular/core';
import { PuzzleService } from '../services/puzzle.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LocalService } from '../services/local.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})
export class NoticeComponent implements OnInit {

  terms: any;
  spin: boolean = false;
  currentLang: string;
  haibuIcon: SafeResourceUrl = '../../assets/icons/haibu-icon.svg';

  constructor(private puzzleService: PuzzleService, private translate: TranslateService, private localService: LocalService) { }


  ngOnInit() {
    this.initializeLanguage();
    this.loadTerms();
  }

  loadTerms() {
    const appLang = this.translate.currentLang === 'esp' ? 'es_privacy' : 'en_privacy';
    this.puzzleService.getTerms(appLang).subscribe(data => {
      this.terms = data;
    });
  }

  changeLanguage(lang: string) {
    const newLang = lang === 'es' ? 'esp' : 'eng';
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.loadTerms();
  }

  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
    this.loadTerms();
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }

}
