<div #tourOverlay *ngIf="isOverlayVisible" id="tourOverlay">
    <!-- Cuatro secciones para el overlay alrededor del elemento resaltado -->
    <div class="overlay">
        <div class="overlay-top" [ngStyle]="getOverlaySectionStyles('top')"></div>
        <div class="overlay-left" [ngStyle]="getOverlaySectionStyles('left')"></div>
        <div class="overlay-right" [ngStyle]="getOverlaySectionStyles('right')"></div>
        <div class="overlay-bottom" [ngStyle]="getOverlaySectionStyles('bottom')"></div>
    </div>

    <!-- Cuadro del tour debajo del elemento resaltado -->
    <div class="tour-box" *ngIf="currentStep && highlightedElement" [ngStyle]="getHighlightStyles().tourBox">
        <div class="flex gap-3 items-center justify-between">
            <div class="w-full text-base font-medium text-gray-700">{{ currentStep.title }}</div>
            <mat-icon id="closeModalChangeStatus2" class="close-x-primary cursor-pointer -mr-3 -mt-7"
                (click)="closeOverlay()">close</mat-icon>
        </div>

        <p class="text-sm text-gray-500">{{ currentStep.content }}</p>

        <div class="tour-controls">
            <button (click)="previous()" *ngIf="!currentStep.disableBack" class="text-gray-700">{{ 'previous' |
                translate }}</button>
            <button *ngIf="currentStep.disableBack" class="text-gray-400">{{ 'previous' | translate }}</button>
            <button *ngIf="!currentStep.final" (click)="next()" class="text-gray-700">{{ 'next' | translate }}</button>
            <button *ngIf="currentStep.final" (click)="closeOverlay()" class="text-gray-700">{{ 'endTour' | translate
                }}</button>
        </div>
    </div>
</div>