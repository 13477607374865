<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>
<div *ngIf="candidate else loadingSkeleton">
  <div class="talent-detail-container">
    <!-- Imagen del candidato -->
    <div class="detail-header">
      <img [src]="candidate.profilePicture?.profilePictureRoute || defaultpfp" (error)="onImageError($event)"
        alt="{{candidate.name}}" class="profile-img" />
    </div>

    <!-- Información del candidato -->
    <div class="detail-body">
      <!-- Nombre del candidato -->
      <div class="candidate-header">
        <h2 class="candidate-account-name">
          {{ candidate.name }} {{ candidate.paternalSurname }} {{ candidate.maternalSurname }}
        </h2>
        <div class="candidate-icons">
          <img [src]="candidate.cv?.cvDocumentRoute ? cvLogo : nocvLogo" alt="CV Icon" class="icon option-hover-effect"
            (click)="candidate.cv?.cvDocumentRoute ? openCv(candidate.cv.cvDocumentRoute) : null">
        </div>
      </div>

      <!-- Mapeo de Áreas de experiencia -->
      <div class="my-2">
        <span class="candidate-experience-area">{{'talentExperienceArea' | translate}}:</span>
        <div *ngIf="candidate.experienceAreas && candidate.experienceAreas.length > 0"
          class="flex flex-wrap gap-2 max-h-24 overflow-auto">
          <div class="candidate-experience" *ngFor="let area of candidate.experienceAreas"
            [matTooltip]="area.experienceAreaKey | translate">
            {{ area.experienceAreaKey | translate }}
          </div>
        </div>
      </div>

      <div class="contact-info">
        <div class="contact-item">
          <img [src]="mailIcon" alt="Email Icon" class="icon" />
          <div class="item-text">
            <app-email-dropdown id="emailMenuCandidateDetail"
              [email]="candidate.email || 'candidateEmailDefault' | translate" [hover]="true">
            </app-email-dropdown>
          </div>
        </div>
        <div class="contact-item">
          <img [src]="phoneLogo" alt="Phone Icon" class="icon" />
          <div class="item-text">
            <app-phone-dropdown id="phoneMenuCandidateDetail"
              [phone]="candidate.phoneNumber ? '+' + candidate.phoneNumber : 'candidatePhoneDefault' | translate"
              [hover]="true">
            </app-phone-dropdown>
          </div>
        </div>
        <div class="location-item">
          <img [src]="locationLogo" alt="Location Icon" class="icon" />
          <div class="item-text">
            {{ candidate.stateCustom && candidate.cityCustom
            ? candidate.stateCustom + ', ' + candidate.cityCustom
            : 'candidateLocationDefault' | translate }}
          </div>
        </div>
      </div>

      <div *ngIf="!isAddingMode">
        <div class="green-title pb-2">
          <div>{{ 'candidateDetailPersonalInfo' | translate }}</div>
        </div>
        <div class="additional-info">
          <div class="info-row">
            <div class="info-item">
              <div class="item-title">{{'candidateDetailGender' | translate}}</div>
              {{ candidate.genderName || 'candidateDetailGenderDefault' | translate }}
            </div>
            <div class="info-item">
              <div class="item-title">{{'candidateDetailBirthdate' | translate}}</div>
              {{ candidate.birthDate ? (candidate.birthDate | dateFormat) : ('candidateDetailGenderDefault' | translate)
              }}
            </div>
            <div class="info-item">
              <div class="item-title">{{'candidateStudyLevel' | translate}}</div>
              {{ candidate.scholarshipName || 'candidateDetailGenderDefault' | translate }}
            </div>
          </div>
          <div class="info-row">
            <div class="info-item">
              <div class="item-title">{{'candidateYearsOfExperience' | translate}}</div>
              {{ candidate.yearsExperienceName ? ('experience.' + candidate.yearsExperienceName | translate) :
              ('candidateDetailGenderDefault' | translate) }}
            </div>
            <div class="info-item">
              <div class="item-title">{{'candidateDetailDetailSalaryExpectation' | translate}}</div>
              {{ candidate.salaryExpected ? (candidate.salaryExpected | currency) : ('candidateDetailGenderDefault' |
              translate) }}
            </div>
            <div class="info-item">
              <div class="item-title">{{'candidatePortfolio' | translate}}</div>
              <a *ngIf="candidate.portfolio" id="portfolioLink" href="{{ candidate.portfolio }}" target="_blank">{{
                'candidatePortfolio' |
                translate }}</a>
              <span *ngIf="!candidate.portfolio">{{ 'candidateDetailGenderDefault' | translate }}</span>
            </div>
          </div>
        </div>
        <!-- Listado completo de skills -->
        <div class="skills-list" *ngIf="candidate.skills && candidate.skills.length > 0">
          <div class="green-title pb-2">Skills:</div>
          <div class="skills-scroll">
            <div class="skill-tag" *ngFor="let skill of candidate.skills">
              {{ skill.skillName }}
            </div>
          </div>
        </div>
      </div>

      <!-- Vista para agregar al candidato (solo se muestra si isSearchMode es true) -->
      <div class="search-body" *ngIf="isAddingMode">
        <div class="md:px-6 px-0 pt-3">
          <p class="descriptionDuplicateText">
            {{ 'candidateDuplicateMessage' | translate }}
          </p>
          <div class="pb-3 flex flex-col">
            <label for="selectCompany" class="title-input-text">
              {{ 'yourCompaniesDuplicate' | translate }}
            </label>
            <mat-select [(ngModel)]="selectedCompany" class="borde brown-border p-3 w-full centered-text"
              name="selectCompany" (selectionChange)="getVacancies($event)">
              <mat-option *ngFor="let company of companies" [value]="company.value">
                {{ company.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="companyRequiredError" class="text-xs">
              {{ companyRequiredError }}
            </mat-error>
            <mat-error *ngIf="companyEmptyError" class="text-xs">
              {{ companyEmptyError }}
            </mat-error>
            <mat-error *ngIf="selectedCompany && !canCreate">
              {{ 'cantAddCandidateError' | translate }}
            </mat-error>
          </div>
          <div *ngIf="selectedCompany && vacancies && vacancies.length > 0" class="pb-3 flex flex-col">
            <label for="selectedVacancy" class="title-input-text">
              {{ 'companyVacanciesDuplicate' | translate }}
            </label>
            <mat-select [(ngModel)]="selectedVacancy" class="borde brown-border p-3 w-full centered-text"
              name="selectedVacancy">
              <mat-option *ngFor="let vacancy of vacancies" [value]="vacancy.value">
                {{ vacancy.label }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="vacancyRequiredError" class="text-xs">
              {{ vacancyRequiredError }}
            </mat-error>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- Botones -->
  <div *ngIf="!isAddingMode" class="flex justify-center pt-4">
    <button id="customButton" class="custom-button w-full md:w-auto" (click)="toggleSearchMode()">
      {{ 'candidateListNormalRegister' | translate }}
    </button>
  </div>
  <div *ngIf="isAddingMode" class="flex justify-center pt-4 pb-2 md:pb-0 gap-2 md:gap-6">
    <button id="backButton" class="custom-white-button w-1/2 md:w-1/4" (click)="toggleSearchMode()">
      {{ 'confirmDialogDelete' | translate }}
    </button>
    <button id="confirmButton" class="custom-button w-1/2 md:w-1/4" (click)="confirmAction()" [disabled]="sending">
      {{ 'resetPasswordConfirm' | translate }}
    </button>
  </div>
</div>

<!-- Skeleton de carga -->
<ng-template #loadingSkeleton>
  <div class="talent-detail-container animate-pulse">
    <div class="detail-header">
      <div class="h-24 w-24 bg-gray-300 rounded-full"></div>
    </div>
    <div class="detail-body">
      <div class="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
      <div class="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
      <div class="contact-info space-y-2">
        <div class="h-4 bg-gray-300 rounded w-1/3"></div>
        <div class="h-4 bg-gray-300 rounded w-1/3"></div>
        <div class="h-4 bg-gray-300 rounded w-1/3"></div>
      </div>
    </div>
  </div>
</ng-template>