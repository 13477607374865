<div class="flex flex-col md:pt-0 md:px-0 md:justify-center items-center border-gray-50 bg-white min-w-full no-zoom">
    <a href="https://wa.me/5215542004088" target="_blank"
        class="fixed bottom-4 right-4 flex items-center justify-center text-white  py-2 px-4 rounded-lg gap-3 whatsapp-container cursor-pointer ">
        <img class="h-5 w-5" [src]="whatsappImage" fill="none">
        <span>{{"loginWhatsappMessage" | translate}}</span>
    </a>

    <div class="fixed h-10 z-50 w-full flex justify-end items-center p-4 md:px-0 info-navbar md:pr-5 nav-transition"
        [ngClass]="scrolled ? '-top-10' : 'top-0'">
        <div class="flex items-center text-right px-2">
            <a id="callToPhone" href="tel:+525542004088" class="flex items-center whitespace-nowrap">
                <img [src]="smartPhoneImage" alt="Smartphone Icon" class="w-4 h-4 mr-2">
                <span class="sticky-navbar-text">(+52) 5542 004088</span>
            </a>
        </div>
        <div class="flex items-center text-right px-2">
            <a id="whatsToPhone" href="https://wa.me/5215542004088" class="flex items-center whitespace-nowrap"
                target="_blank">
                <img [src]="whatsappImage" alt="WhatsApp Icon" class="w-4 h-4 mr-2">
                <span class="sticky-navbar-text">(+52) 5542 004088</span>
            </a>
        </div>
        <div class="relative mr-1" #dropdownMenu appClickOutside (clickOutside)="dropdownOpen = false">
            <button id="toggleDropdownLogin" (click)="toggleDropdown()"
                class="sticky-navbar-text p-1 rounded flex items-center">
                <img [src]="languageImage" alt="Language Icon" class="w-4 h-4 ml-2 mr-2">
                {{ currentLangShort }}
            </button>
            <div *ngIf="dropdownOpen" class="absolute right-0 mt-2 w-40 shadow-lg rounded selector-container">
                <div id="changeLanguageEng" (click)="changeLanguage('eng')"
                    class="sticky-navbar-text p-2 dropdown-container cursor-pointer">English
                </div>
                <div id="changeLanguageEsp" (click)="changeLanguage('esp')"
                    class="sticky-navbar-text p-2 dropdown-container cursor-pointer">Español
                </div>
            </div>
        </div>
    </div>

    <div id="navbar" class="fixed z-40 h-16 w-full flex flex-col lg:flex-row items-center bg-white nav-transition"
        [ngClass]="scrolled ? 'top-0' : 'top-10'">
        <div class="flex items-center justify-between w-full lg:hidden">
            <div class="lg:hidden px-4 py-2 ">
                <button id="toggleMenuSide" (click)="toggleMenu()">
                    <img [src]="menuIcon" alt="Menú" width="32px" height="32px" />
                </button>
            </div>

            <div class="flex items-center justify-start w-full lg:w-1/5 px-4 lg:pl-5">
                <img class="haibu-logo my-2" [src]="haibuImage" alt="">
            </div>

            <div class="mobile-menu nav-transition" [class.active]="isMenuOpen"
                [ngClass]="scrolled ? 'top-0' : 'top-10'">

                <div class="menu-header">
                    <div class="menu-close-button">
                        <button id="toggleMenuClose" (click)="toggleMenu()">
                            <img [src]="closeIcon" alt="Cerrar" width="32px" height="32px">
                        </button>
                    </div>
                    <img class="menu-haibu-logo pl-4" [src]="haibuImage" alt="Haibu Logo" />
                </div>
                <div class="flex flex-col justify-center p-4">

                    <div class="menu-title pb-3">
                        Menú
                    </div>
                    <div
                        class="flex flex-col lg:flex-row justify-center lg:justify-end items-center space-y-2 lg:space-y-0 lg:space-x-4">
                        <div id="scrollToElementRompe"
                            class="hover:bg-gray-100 rounded-md edit-text cursor-pointer text-center py-2 lg:py-0 lg:px-2 whitespace-nowrap"
                            (click)="scrollToElement('rompe')">
                            {{"loginPuzzle" | translate}}
                        </div>
                        <div id="scrollToElementModules"
                            class="hover:bg-gray-100 rounded-md edit-text cursor-pointer text-center py-2 lg:py-0 lg:px-2 whitespace-nowrap"
                            (click)="scrollToElement('modules')">
                            {{"loginModules" | translate}}
                        </div>
                        <div id="scrollToElementPrice"
                            class="hover:bg-gray-100 rounded-md edit-text cursor-pointer text-center py-2 lg:py-0 lg:px-2 whitespace-nowrap"
                            (click)="scrollToElement('price')">
                            {{"loginPrices" | translate}}
                        </div>
                        <div id="scrollToElementDemo"
                            class="hover:bg-gray-100 rounded-md edit-text cursor-pointer text-center py-2 lg:py-0 lg:px-2 whitespace-nowrap"
                            (click)="scrollToElement('demo')">
                            {{"loginAskDemo" | translate}}
                        </div>
                        <div id="redirectToBlog"
                            class="hover:bg-gray-100 rounded-md edit-text cursor-pointer text-center py-2 pb-6 lg:py-0 lg:px-2 whitespace-nowrap"
                            (click)="scrollToElement('blog')">
                            {{"loginBlog" | translate}}
                        </div>
                    </div>
                    <app-custom-primary-button id="openLoginModal" (click)="openLoginModal()" color="secondary"
                        size="small" class="w-full lg:w-fit pb-1 whitespace-nowrap overflow-hidden flex items-center justify-center">
                        {{"loginEnter" | translate}}
                    </app-custom-primary-button>
                </div>

                <div class="footer-section flex flex-col justify-center items-center mt-auto">
                    <div class="footer-text mb-4">© 2023 Haibu. All rights reserved</div>
                    <div class="additional-text mb-4">{{'loginPrivacyAdvice' | translate}}</div>
                    <div class="social-icons flex space-x-4">
                        <a id="linkedinMobileSideMenu" href="https://www.linkedin.com/company/haibuhr/about/"
                            target="_blank">
                            <img [src]="linkedinIcon" alt="Linkedin">
                        </a>
                        <a id="facebookMobileSideMenu" href="https://www.facebook.com/haibu.hr" target="_blank">
                            <img [src]="fbIcon" alt="Facebook">
                        </a>
                        <a id="instagramMobileSideMenu" href="https://www.instagram.com/haibu.hr/" target="_blank">
                            <img [src]="igIcon" alt="Instagram">
                        </a>
                        <a id="youtubeMobileSideMenu" href="" target="_blank">
                            <img [src]="ytIcon" alt="Youtube">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden lg:flex flex-grow justify-between items-center rounded-md p-0 lg:px-4 lg:pr-5 ">
            <div class="flex items-center justify-start w-full lg:w-1/5 p-0 md:pl-1 lg:px-4 lg:pl-5">
                <img class="haibu-logo my-2" [src]="haibuImage" alt="">
            </div>
            <div class="flex-grow flex justify-center lg:justify-end items-center">
                <div id="scrollToElementRompe2"
                    class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
                    (click)="scrollToElement('rompe')">
                    {{"loginPuzzle" | translate}}
                </div>
                <div id="scrollToElementModules2"
                    class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
                    (click)="scrollToElement('modules')">
                    {{"loginModules" | translate}}
                </div>
                <div id="scrollToElementPrice2"
                    class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
                    (click)="scrollToElement('price')">
                    {{"loginPrices" | translate}}
                </div>
                <div id="scrollToElementDemo2"
                    class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
                    (click)="scrollToElement('demo')">
                    {{"loginAskDemo" | translate}}
                </div>
                <div id="redirectToBlog2"
                    class="hover:bg-gray-100 rounded-md lg:px-3 edit-text cursor-pointer text-center py-2 md:py-0 md:px-2 lg:mr-4 whitespace-nowrap"
                    (click)="scrollToElement('blog')">
                    {{"loginBlog" | translate}}
                </div>
            </div>
            <div class="w-full md:w-auto md:pr-2">
                <app-custom-primary-button id="openLoginModal2" (click)="openLoginModal()" color="secondary"
                    size="small" class="w-full md:w-fit pb-1 whitespace-nowrap overflow-hidden">
                    {{"loginEnter" | translate}}
                </app-custom-primary-button>
            </div>
        </div>
    </div>
</div>