import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LocalService } from 'src/app/services/local.service';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private localService: LocalService) { }

  transform(value: any, showTime: boolean = false): any {
    const tokencito = this.localService.getJsonValue('token');

    // Define el formato base para cada idioma, usando 12 horas con AM/PM cuando showTime es true
    const formatEsp = showTime ? 'dd/MM/yyyy hh:mm a' : 'dd/MM/yyyy';
    const formatEng = showTime ? 'MM/dd/yyyy hh:mm a' : 'MM/dd/yyyy';

    if (tokencito?.data?.languagePreference === 'esp') {
      return this.datePipe.transform(value, formatEsp);
    } else if (tokencito?.data?.languagePreference === 'eng') {
      return this.datePipe.transform(value, formatEng);
    } else {
      return this.datePipe.transform(value, formatEsp); // Por defecto en formato español
    }
  }
}
