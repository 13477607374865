import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PuzzleService } from 'src/app/services/puzzle.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';

@Component({
  selector: 'app-questionnaire-viewer',
  templateUrl: './questionnaire-viewer.component.html',
  styleUrls: ['./questionnaire-viewer.component.css']
})
export class QuestionnaireViewerComponent implements OnInit {

  loading: boolean = false;
  userImage;
  candidate: any;
  questionnaire: any;
  selectedValueRadio: boolean = true;
  numberOfNeedsReview: number = 0;
  defaultpfp: SafeResourceUrl = '../../../assets/icons/default-pfp-icon.svg';

  constructor(
    private dialogRef: MatDialogRef<QuestionnaireViewerComponent>,
    public sanitizer: DomSanitizer,
    private puzzleService: PuzzleService,
    public dialog: MatDialog,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.candidate = data.candidate;
    this.questionnaire = data.quest;
    if (this.candidate?.candidateImageInfo?.rpC_IMG_ROUTE) {
      this.userImage = this.sanitizer.bypassSecurityTrustResourceUrl(environment.imageUrl + this.candidate.candidateImageInfo?.rpC_IMG_ROUTE);
    }
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  getRadioClass(option: any) {
    if (option.isSelected && option.isCorrect) {
      return 'correctselected';
    } else if (option.isSelected && !option.isCorrect) {
      return 'incorrectselected';
    } else {
      return 'padding-check';
    }
  }

  getResponesToEvaluate() {
    const responsesToEvaluate = this.questionnaire.questions.filter((question: any) => question.needsReview);
    return responsesToEvaluate.length;
  }

  saveResponses() {
    const responsesToEvaluate = this.questionnaire.questions.filter((question: any) => question.needsReview && question.needsReviewPass);

    if (responsesToEvaluate.length != this.getResponesToEvaluate()) {
      this.openResultDialog(0, this.translate.instant('candidateEvalErrorTitle'), this.translate.instant('candidateEvalErrorMessage'));
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: [
        this.translate.instant('candidateDetailTestEvalTitle'),
        this.translate.instant('candidateDetailTestEvalMessage'),
      ],
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        // solo las que tenga needsReview
        this.loading = true;

        const responsesToSend = responsesToEvaluate.map((question: any) => {
          return {
            CandidateAnswerId: question.answerId,
            IsCorrect: question.isCorrect ? question.isCorrect : false
          };
        });

        this.puzzleService.saveResponseResult(responsesToSend).subscribe((response: any) => {
          if (response.isSuccess) {
            this.loading = false;
            this.dialogRef.close({
              isSuccess: true
            });
          } else {
            this.loading = false;
            this.dialogRef.close({
              isSuccess: false
            });
          }
        }, error => {
          console.error('Error al guardar respuestas:', error);
        });
      }
    });
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
            disableClose: true
          });
        }
        break;
    }
  }
}
