import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaLoaderService {

  private recaptchaReadySubject = new BehaviorSubject<boolean>(false);

  constructor() {
    window['onRecaptchaLoad'] = () => this.recaptchaReadySubject.next(true);
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=6LdhgoYpAAAAAKlDW2aRdu6nH_Gmk0KTTU7tcoMr`;
    document.head.appendChild(script);
  }

  get isRecaptchaReady(): Observable<boolean> {
    return this.recaptchaReadySubject.asObservable();
  }
}
