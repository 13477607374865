import { Component, OnInit, Inject } from '@angular/core';
import { Evaluation } from '../../interfaces/evaluation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeComponent } from '../../home/home.component';
import { PuzzleService } from '../../services/puzzle.service';
import { BassetClient } from '../../interfaces/basset-client';
import { RS } from '../../interfaces/rs';
import { Console } from 'console';
import { Candidate } from '../../interfaces/Candidate';
import { forkJoin, Observable } from 'rxjs';
import { of } from 'rxjs';
import { PP } from '../../interfaces/prueba-psicometrica';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-evaluation',
  templateUrl: './new-evaluation.component.html',
  styleUrls: ['./new-evaluation.component.css'],
})
export class NewEvaluationComponent implements OnInit {
  constructor(
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Candidate
  ) { }

  primerPaso: boolean = true;
  veSegundoPaso: boolean = false;
  segundoPaso: boolean = false;
  confirmaEvaluaciones: boolean = false;
  loading: boolean = false;

  pruebasPsicometricas: boolean = false;
  estudioSocioeconomico: boolean = false;

  canYouPruebasPsicometricas: boolean = false;
  canYouEstudioSocioeconomico: boolean = false;

  sendingObject = new FormData();

  clients: BassetClient[] = [];
  requestedEvaluations: Evaluation[] = [];
  razonesSociales: RS[] = [];

  selectedClient: string = '';
  selectedRazon: string = '';

  selectedEvaluation: string = '';

  // availablePruebasPsicometricas = {
  //   ciesman: false,
  //   cacter: false,
  //   cibain: false,
  //   competian: false,
  //   coefiv: false,
  //   genlinder: false,
  //   inceval: false,
  //   coleibor: false,
  //   evaluador: false
  // }

  extraEstudiosSocioeconomicos = {
    investigacionlaboral: false,
    urgente: false,
  };

  availablePruebasPsicometricas: PP[] = [];

  evaluador: boolean = false;

  onCheckEvaluador(event) {
    if (event.checked) {
      for (let i = 0; i < this.availablePruebasPsicometricas.length; i++) {
        this.availablePruebasPsicometricas[i].selected = false;
      }
    }
  }

  evaluations: Evaluation[] = [
    {
      id: '1',
      name: 'Estudio socioeconómico',
    },
    {
      id: '2',
      name: 'Referencia laboral',
    },
    {
      id: '3',
      name: 'Perfiles de integridad',
    },
    {
      id: '4',
      name: 'Investigación laboral',
    },
    {
      id: '5',
      name: 'Pruebas psicométricas',
    },
  ];

  ngOnInit(): void {
    var id = this.data.cdT_COMPANY_ID;
    this.puzzleService
      .getRompeCabezasBassetClientsDropdown(id)
      .subscribe((resp) => {
        if (resp.length == 0) {
          const noContractMessage = this.translate.instant('newEvaluationBussinesRegisterError');
          const contactLinkMessage = this.translate.instant('newEvaluationBussinesRegisterError2');
          const sendEmailMessage = this.translate.instant('newEvaluationBussinesRegisterMail');

          const fullMessage = `
            ${noContractMessage}<br><br>
            ${contactLinkMessage}<br>
            <a class="blueTitle" target="_blank" href="https://basset.com.mx/contacto">https://basset.com.mx/contacto</a><br><br>
            ${sendEmailMessage}<br>
            <a class="blueTitle" href="mailto:ventas@basset.com.mx">ventas@basset.com.mx</a>
          `;

          this.cerrar(false, fullMessage);
        }
        else this.clients = resp;
      });

    this.puzzleService.getPP().subscribe((resp) => {
      this.availablePruebasPsicometricas = resp.PP;
      for (let i = 0; i < this.availablePruebasPsicometricas.length; i++) {
        this.availablePruebasPsicometricas[i].selected = false;
      }
    });
  }

  availableSocialReasons() {
    return !(this.razonesSociales.length > 0);
  }

  availableClients() {
    return !(this.clients.length > 0);
  }

  availableEvaluations() {
    return !(this.requestedEvaluations.length > 0);
  }

  cerrar(option: boolean, action?: any) {
    if (option) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(action);
    }
  }

  addEvaluation() {
    var foundStudy = this.evaluations.find(
      (e) => e.id == this.selectedEvaluation
    );
    var newArray = this.evaluations.filter(
      (e) => e.id != this.selectedEvaluation
    );
    this.evaluations = newArray;

    var evaluation: Evaluation = {
      id: foundStudy.id,
      name: foundStudy.name,
    };
    this.requestedEvaluations.push(evaluation);
    this.selectedEvaluation = '';
  }

  deleteSelectedEvaluation(evaluation: Evaluation) {
    this.evaluations.push(evaluation);
    var newSelectedEvaluations = this.requestedEvaluations.filter(
      (e) => e.id != evaluation.id
    );
    this.requestedEvaluations = newSelectedEvaluations;
  }

  muestraMensajeRazones: boolean = false;

  getSelectedClient(bassetClient: BassetClient) {
    this.selectedRazon = '';
    this.razonesSociales = [];
    var id = bassetClient.bssC_BASSET_CLIENT_ID;
    var formData = new FormData();
    formData.append('client_id', '' + id);
    this.puzzleService.getBassetRazonesSociales(formData).subscribe((resp) => {
      if (resp.RS.length > 0) {
        this.razonesSociales = resp.RS;
        this.muestraMensajeRazones = false;
      } else {
        this.razonesSociales = [];
        this.muestraMensajeRazones = true;
      }
    });
  }

  regresaPruebas() {
    this.pruebasPsicometricas = false;
    if (this.requestedEvaluations.some((el) => el.id == '1'))
      this.estudioSocioeconomico = true;
    else this.segundoPaso = true;
  }

  regresaEstudio() { }

  checaTercerPaso() {
    this.segundoPaso = false;
    if (this.requestedEvaluations.some((el) => el.id == '1'))
      this.estudioSocioeconomico = true;
    else if (this.requestedEvaluations.some((el) => el.id == '5'))
      this.pruebasPsicometricas = true;
    else this.confirmaEvaluaciones = true;
  }

  checkPruebasPsicometricas() {
    this.estudioSocioeconomico = false;
    if (this.requestedEvaluations.some((el) => el.id == '5'))
      this.pruebasPsicometricas = true;
    else this.confirmaEvaluaciones = true;
  }

  canYouFinishRequest() {
    let v = false;
    if (this.evaluador) v = true;
    else {
      for (let i = 0; i < this.availablePruebasPsicometricas.length; i++) {
        if (this.availablePruebasPsicometricas[i].selected) v = true;
      }
    }
    return !v;
  }

  requestEvaluations() {
    this.confirmaEvaluaciones = false;
    this.loading = true;
    let observables: Observable<any>[] = [];
    let formData = new FormData();
    formData.append('cliente_id', this.selectedClient);
    formData.append('cliente_rs', this.selectedRazon);
    formData.append('candidateId', this.data.cdT_ID);
    if (this.requestedEvaluations.some((e) => e.id == '1')) {
      var localFormData = formData;
      localFormData.append(
        'include_investigacion',
        this.extraEstudiosSocioeconomicos.investigacionlaboral ? '1' : '0'
      );
      localFormData.append(
        'urgente_flag',
        this.extraEstudiosSocioeconomicos.urgente ? '1' : '0'
      );
      observables.push(this.puzzleService.requestES(localFormData));
    } else {
      observables.push(of(null));
    }
    if (this.requestedEvaluations.some((e) => e.id == '2')) {
      observables.push(this.puzzleService.requestRL(formData));
    } else {
      observables.push(of(null));
    }
    if (this.requestedEvaluations.some((e) => e.id == '3')) {
      observables.push(this.puzzleService.requestPI(formData));
    } else {
      observables.push(of(null));
    }
    if (this.requestedEvaluations.some((e) => e.id == '4')) {
      observables.push(this.puzzleService.requestIL(formData));
    } else {
      observables.push(of(null));
    }

    var requestedPruebas;

    if (this.requestedEvaluations.some((e) => e.id == '5')) {

      var localFormData = formData;

      localFormData.append('pertinentes', this.evaluador ? '1' : '0');

      if (!this.evaluador) {
        var requested = this.availablePruebasPsicometricas.filter(
          (e) => e.selected == true
        );

        var sendingArray: { evp_psicometrica_prueba_id: string }[] = [];

        for (let i = 0; i < requested.length; i++) {
          sendingArray.push({
            evp_psicometrica_prueba_id: requested[i].evp_psicometrica_prueba_id,
          });
        }

        let sendingObject = {};

        for (let i = 0; i < sendingArray.length; i++) {
          sendingObject[`${i}`] = sendingArray[i]
        }

        requestedPruebas = JSON.stringify(sendingObject)

        localFormData.append('pruebas', JSON.stringify(sendingObject));
      }

      observables.push(this.puzzleService.requestPP(localFormData));
    } else {
      observables.push(of(null));
    }

    let returnObject = {
      cliente_id: this.selectedClient,
      cliente_rs: this.selectedRazon,
      requestedEvaluations: this.requestedEvaluations,
      evaluador: this.evaluador,
      requestedPruebas: requestedPruebas,
      investigacionlaboral: this.extraEstudiosSocioeconomicos.investigacionlaboral,
      urgente: this.extraEstudiosSocioeconomicos.urgente
    }


    if (this.data.cdT_ID) {
      forkJoin(observables).subscribe(
        (response) => {
          let error: boolean = false;
          let errorString: string = '';
          for (let i = 0; i < response.length; i++) {
            if (response[i]) {
              if (response[i].isSuccess == false) {
                error = true;
                errorString += response[i].message;
              }
            }
          }
          if (error) this.cerrar(false, errorString);
          else this.cerrar(true);
        },
        (error) => {
          this.cerrar(false);
        }
      );
    } else {

      this.cerrar(false, returnObject)
    }
  }
}
