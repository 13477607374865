export class UpdateCandidateStaPro{
    cdT_ID:string;
    cdT_STATUS_CANDIDATE_ID:string;
    cdT_STATUS_ID:string;
    ccOM_COMMENT:string;
    cdT_ACTIVE:boolean;
    DROP_SOURCE_ID?:string;
    CDT_ENTRY_DATE?:Date;
    CDT_NOTIFICATION_NOW?:boolean;
    MentionedUserIds?:any;
}