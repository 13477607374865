import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CustomTourService, TourStep } from 'src/app/services/custom-tour.service';

@Component({
  selector: 'app-tour-dialog',
  templateUrl: './tour-dialog.component.html',
  styleUrls: ['./tour-dialog.component.css']
})
export class TourDialogComponent implements OnInit, AfterViewInit {
  highlightedElement: HTMLElement | null = null;
  isOverlayVisible = false;
  currentStep: TourStep | null = null;

  constructor(
    private tourService: CustomTourService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // Observa los cambios en el paso actual y la visibilidad de la superposición
    this.tourService.currentStep$.subscribe(() => {
      this.highlightedElement = null;
      const step = this.tourService.getCurrentStep();
      if (step) {
        this.highlightElement(step.anchorId);
        this.currentStep = step;
        this.isOverlayVisible = true;
      } else {
        this.isOverlayVisible = false;
        this.currentStep = null;
      }
    });
  }

  ngAfterViewInit() {
    // Retrasa el cálculo de estilos para asegurarse de que el DOM está actualizado
    setInterval(() => {
      this.cdr.detectChanges(); // Refuerza cualquier cambio adicional
    }, 50); // Ajusta el tiempo si es necesario
  }

  highlightElement(anchorId: string) {
    const maxAttempts = 10; // Número máximo de intentos
    let attempts = 0;

    const interval = setInterval(() => {
      this.highlightedElement = document.querySelector(`[idtour="${anchorId}"]`);

      if (this.highlightedElement || attempts >= maxAttempts) {
        clearInterval(interval); // Detiene el intervalo si encuentra el elemento o se alcanzan los intentos máximos

        if (this.highlightedElement) {
          // Desplaza al elemento y aplica cambios
          this.highlightedElement.scrollIntoView({ block: 'center' });
          this.cdr.detectChanges();
        } else {
          console.warn(`Element with idtour="${anchorId}" not found after ${maxAttempts} attempts.`);
        }
      }

      attempts++;
    }, 200); // Intentos cada 200 ms
  }

  next() {
    this.tourService.next();
  }

  previous() {
    this.tourService.previous();
  }

  closeOverlay() {
    document.documentElement.style.overflow = 'auto';
    this.tourService.end();
    this.isOverlayVisible = false;
  }

  getHighlightStyles() {
    if (!this.highlightedElement) {
      return { tourBox: {} };
    }

    const rect = this.highlightedElement.getBoundingClientRect();
    const padding = 10; // Define el padding para el espacio entre el elemento y el tourBox

    // Tamaño del viewport y del tourBox
    const screenWidth = window.innerWidth;
    const box = document.getElementsByClassName('tour-box')[0] as HTMLElement;
    const boxWidth = box.clientWidth;
    const boxHeight = box.clientHeight;

    // Espacios disponibles alrededor del elemento
    const availableSpaceBelow = document.documentElement.scrollHeight - (rect.bottom + padding);
    const availableSpaceRight = screenWidth - (rect.right + padding);

    // Posición inicial (debajo del elemento)
    let top = rect.bottom + padding;
    let left = rect.left;

    if (boxHeight > availableSpaceBelow) {
      // Si no hay suficiente espacio debajo, colocarlo arriba
      top = rect.top - boxHeight - padding;

      // Si no hay espacio arriba, colocarlo a la derecha
      if (top < 0 && availableSpaceRight >= boxWidth) {
        top = rect.top;
        left = rect.right + padding;
      }
    }

    // Asegurar que no se salga horizontalmente del viewport
    if (left + boxWidth > screenWidth) {
      left = screenWidth - boxWidth - padding;
    }
    if (left < padding) {
      left = padding;
    }

    // Asegurar que no se salga verticalmente del viewport
    if (top + boxHeight > document.documentElement.scrollHeight) {
      top = document.documentElement.scrollHeight - boxHeight - padding;
    }
    if (top < padding) {
      top = padding;
    }

    return {
      tourBox: {
        top: `${top}px`,
        left: `${left}px`,
      },
    };
  }


  getOverlaySectionStyles(position: string) {
    if (!this.highlightedElement) {
      return {};
    }

    const rect = this.highlightedElement.getBoundingClientRect();
    const overlayRect = document.documentElement.getBoundingClientRect();
    const padding = 5; // Define el padding en píxeles

    // Establece las variables CSS para cada sección del overlay, ajustando con el padding
    document.documentElement.style.setProperty('--highlight-top', `${rect.top - padding}px`);
    document.documentElement.style.setProperty('--highlight-bottom', `${overlayRect.height - rect.bottom - padding}px`);
    document.documentElement.style.setProperty('--highlight-bottom2', `${rect.bottom + padding}px`);
    document.documentElement.style.setProperty('--highlight-left', `${rect.left - padding}px`);
    document.documentElement.style.setProperty('--highlight-right', `${overlayRect.width - rect.right - padding}px`);

    /* if (this.isOverlayVisible) {
      // Quitar scroll
      document.documentElement.style.overflow = 'hidden';
    } else {
      // Restaurar scroll
      document.documentElement.style.overflow = 'auto';
    } */

    return {};
  }
}