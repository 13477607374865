import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { AbcOrderToggleComponent } from './components/catalogs/abc-order-toggle/abc-order-toggle.component';
import { CustomInputComponent } from './components/catalogs/custom-input/custom-input.component';
import { CustomInputPasswordComponent } from './components/catalogs/custom-input-password/custom-input-password.component';
import { CustomDropdownComponent } from './components/catalogs/custom-dropdown/custom-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomCheckboxComponent } from './components/catalogs/custom-checkbox/custom-checkbox.component';
import { CustomRadioButtonComponent } from './components/catalogs/custom-radio-button/custom-radio-button.component';
import { CustomToggleSwitchComponent } from './components/catalogs/custom-toggle-switch/custom-toggle-switch.component';
import { CustomDatePickerComponent } from './components/catalogs/custom-date-picker/custom-date-picker.component';
import { CustomPrimaryButtonComponent } from './components/catalogs/custom-primary-button/custom-primary-button.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PermissionDirective } from './directives/permission.directive';
import { PhoneDropdownComponent } from './components/phone-dropdown/phone-dropdown.component';
import { FormatCurrencyDirectiveDirective } from './directives/format-currency-directive.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DashboardTooltipComponent } from './components/dashboard-tooltip/dashboard-tooltip.component';
import { PhoneLadaSelectComponent } from './components/phone-lada-select/phone-lada-select.component';
import { EmailDropdownComponent } from './components/email-dropdown/email-dropdown.component';
import { LangDatepickerComponent } from './components/lang-datepicker/lang-datepicker.component';
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { QuestionFormCardComponent } from './components/question-form-card/question-form-card.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HaibuBannerComponent } from './components/haibu-banner/haibu-banner.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { LangDatepickerV2Component } from './components/lang-datepicker-v2/lang-datepicker-v2.component';
import { ClientDetailComponent } from '../home/client-detail/client-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxColorsModule } from 'ngx-colors';
import { QuillModule } from 'ngx-quill';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './custom-paginator-intl';

@NgModule({
  declarations: [
    AbcOrderToggleComponent,
    CustomInputComponent,
    ClickOutsideDirective,
    CustomInputPasswordComponent,
    CustomDropdownComponent,
    CustomCheckboxComponent,
    CustomRadioButtonComponent,
    CustomToggleSwitchComponent,
    CustomDatePickerComponent,
    CustomPrimaryButtonComponent,
    PermissionDirective,
    PhoneDropdownComponent,
    FormatCurrencyDirectiveDirective,
    DateFormatPipe,
    DashboardTooltipComponent,
    PhoneLadaSelectComponent,
    EmailDropdownComponent,
    LangDatepickerComponent,
    MaintenanceBannerComponent,
    QuestionFormCardComponent,
    LinkifyPipe,
    NavbarComponent,
    HaibuBannerComponent,
    LocalDatePipe,
    LangDatepickerV2Component,
    ClientDetailComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TourMatMenuModule,
    TranslateModule,
    NgxColorsModule,
    QuillModule.forRoot(),
  ],
  exports: [
    AbcOrderToggleComponent,
    CustomInputComponent,
    CustomInputPasswordComponent,
    CustomDropdownComponent,
    CustomCheckboxComponent,
    CustomRadioButtonComponent,
    CustomToggleSwitchComponent,
    CustomDatePickerComponent,
    CustomPrimaryButtonComponent,
    ClickOutsideDirective,
    PermissionDirective,
    PhoneDropdownComponent,
    DashboardTooltipComponent,
    FormatCurrencyDirectiveDirective,
    DateFormatPipe,
    PhoneLadaSelectComponent,
    EmailDropdownComponent,
    LangDatepickerComponent,
    MaintenanceBannerComponent,
    QuestionFormCardComponent,
    LinkifyPipe,
    NavbarComponent,
    HaibuBannerComponent,
    LocalDatePipe,
    LangDatepickerV2Component,
    ClientDetailComponent,
    NgxColorsModule,
    QuillModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }
  ]
})
export class SharedComponentsModule { }
