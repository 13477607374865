import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LocalService } from '../services/local.service';
import { TranslateService } from '@ngx-translate/core';
import { APIResponse } from '../interfaces/response';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  currentLang: string;
  companyName: string = 'CLIENTEDEHR';
  token: APIResponse = this.localService.getJsonValue('token');
  haibuIcon: SafeResourceUrl = '../../assets/icons/haibu-icon.svg';

  displayedColumns: string[] = ['nombre', 'tipo', 'proveedor', 'caduca', 'descripcion'];
  dataSource = [];

  part1: string = '';
  part2: string = '';

  constructor(
    private localService: LocalService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.companyName = this.token?.data?.companyName.trim() || 'CLIENTEDEHR';
    this.initializeLanguage();
    this.setTableData();
    this.setCookiesText();
  }

  setTableData() {
    forkJoin({
      analitycsCookies: this.translate.get('analitycsCookies'),
      preferencesCookies: this.translate.get('preferencesCookies'),
      adCookies: this.translate.get('adCookies'),
      yearsCookies: this.translate.get('yearsCookies'),
      daysCookies: this.translate.get('daysCookies'),
      yearCookies: this.translate.get('yearCookies'),
      gaCookies: this.translate.get('gaCookies'),
      ga2Cookies: this.translate.get('ga2Cookies'),
      gclCookies: this.translate.get('gclCookies'),
      i18nCookies: this.translate.get('i18nCookies')
    }).subscribe(translations => {
      this.dataSource = [
        {
          nombre: '_ga',
          tipo: translations.analitycsCookies,
          proveedor: 'Google',
          caduca: `2 ${translations.yearsCookies}`,
          descripcion: translations.gaCookies
        },
        {
          nombre: '_ga_BQ980LL9JX',
          tipo: translations.analitycsCookies,
          proveedor: 'Google',
          caduca: `2 ${translations.yearsCookies}`,
          descripcion: translations.ga2Cookies
        },
        {
          nombre: '_gcl_au',
          tipo: translations.adCookies,
          proveedor: 'Google',
          caduca: `90 ${translations.daysCookies}`,
          descripcion: translations.gclCookies
        },
        {
          nombre: 'i18n_redirected',
          tipo: translations.preferencesCookies,
          proveedor: 'Haibu',
          caduca: `1 ${translations.yearCookies}`,
          descripcion: translations.i18nCookies
        }
      ];
    });
  }


  changeLanguage(lang: string) {
    const newLang = lang === 'es' ? 'esp' : 'eng';
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.setTableData();
    this.setCookiesText();
    this.localService.setJsonValue('lang', newLang);
  }

  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
    this.setTableData();
    this.setCookiesText();
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }

  setCookiesText() {
    if (this.currentLang === 'esp') {
      this.part1 = `
    <div>Este sitio es proporcionado por Haibu en cooperación con ${this.companyName}. ${this.companyName} y Haibu
    ("nosotros",
    "nos")
    utilizamos cookies, web beacons y tecnologías similares (denominadas conjuntamente "cookies") en este
    Micrositio De Empleo. Explica qué son las cookies, por qué se utilizan y sus opciones con respecto a su uso.
    Para obtener más información sobre la forma en que ${this.companyName} trata los datos personales en este
    Micrositio
    De Empleo, visita la Política de privacidad <a class="underline text-primary" href="/notice" target="_blank">aquí</a>.
    <div class="mt-5 mb-3">
        <b>¿Qué son las cookies?</b>
    </div>
    <div>Una cookie es un archivo pequeño que contiene una cadena de caracteres que se envía a tu ordenador
        cuando
        visitas un sitio web. Cuando visitas el sitio web, la cookie permite que ese sitio reconozca tu
        navegador.
        De
        este modo, se puede recuperar la información que proporcionaste anteriormente, para que puedas utilizar
        fácilmente las funciones personalizadas. Las cookies pueden almacenar las preferencias del usuario y
        otra
        información para mejorar su experiencia en el Micrositio De Empleo o usarse para rastrearte cuando
        navegas a
        otros sitios que también utilizan servicios de cookies de terceros.</div>
    <div class="mt-5 mb-3">
        <b>¿Qué tipos de cookies se utilizan en este Micrositio De Empleo y cómo se utilizan?</b>
    </div>
    <ul class="list-disc pl-5">
        <li>Cookies estrictamente necesarias: Estas cookies son necesarias para para ofrecerte los servicios
            disponibles en el Micrositio De Empleo.</li>
        <li>Cookies analíticas: Estas cookies recopilan información que se utiliza bien en forma agregada para
            ayudarnos a entender cómo se utiliza el Micrositio De Empleo o qué tan eficaces son las campañas de
            marketing, o para ayudarnos a personalizar el Micrositio De Empleo para ti.</li>
        <li>Cookies de marketing: Estas cookies se utilizan para que los mensajes publicitarios sean más
            relevantes
            para ti. Realizan funciones como evitar que el mismo anuncio vuelva a aparecer continuamente,
            garantizar
            que los anuncios se muestren correctamente para los anunciantes y, en algunos casos, seleccionar
            anuncios que se basan en tus intereses.</li>
        <li>Cookies de preferencia: Estas cookies permiten que el Micrositio De Empleo recuerde información que
            cambia la forma en que el Micrositio De Empleo se comporta o se ve, como tu idioma preferido o la
            región
            en la que te encuentras. La pérdida de la información almacenada en una cookie de preferencias puede
            hacer que la experiencia sea menos funcional, pero no evitará que funcione.</li>
    </ul>
    <div class="mt-3">
        La lista completa de las cookies activas que se atienden a través de este Micrositio De Empleo y los
        propósitos
        que cumplen se enumeran a continuación:
    </div>
  `;

      this.part2 = `<div>A continuación se presenta información más específica sobre algunas de las cookies que ofrece este
            Micrositio De
            Empleo, en caso de que que se encuentran en la lista de arriba.</div>
        <div class="mt-5 mb-3">
            <b>Google Analytics</b>
        </div>
        <div>
            Las cookies analíticas de terceros de Google (Google Analytics) son utilizadas por {{ companyName }} y
            Haibu con
            la
            misma finalidad. En consecuencia, se nos considera conjuntamente responsables de el uso de estas
            cookies.
            Al utilizar Google Analytics, los datos serán compartidos con Google, por favor encuentra más
            información
            <a class="underline text-primary"
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">aquí</a>
            y
            también consulta la política de privacidad de Google Analytics <a class="underline text-primary"
                href="https://policies.google.com/privacy">aquí</a> para obtener información sobre cómo
            tratan
            los datos personales.
        </div>
        <div class="mt-5 mb-3">
            <b>Cookies de YouTube</b>
        </div>
        <div>
            Este Micrositio De Empleo puede insertar vídeos de YouTube utilizando el modo de privacidad mejorada de
            YouTube.
            Este modo puede establecer cookies de marketing en tu ordenador cuando haces clic en el reproductor de
            vídeo
            de
            YouTube.
            Ten en cuenta que no tenemos ningún control sobre cómo se utiliza la información de estas cookies ni
            tenemos
            acceso a ella.
            Para obtener más información, visita la página de inserción de vídeos de YouTube página de información
            <a class="underline text-primary" href="https://support.google.com/youtube/answer/171780">aquí</a>
            para
            obtener información sobre cómo tratan los datos personales. <a class="underline text-primary"
                href="https://policies.google.com/privacy">aquí</a>
        </div>
        <div class="mt-5 mb-3">
            <b>Cookies de redes sociales</b>
        </div>

        <div>
            Este Micrositio De Empleo puede integrarse con las siguientes plataformas de redes sociales que, de
            forma
            independiente, pueden establecer cookies de marketing en tu ordenador:

            <ul class="my-3 list-disc pl-5">
                <li>
                    <a class="underline text-primary"
                        href="https://www.linkedin.com/legal/privacy-policy?trk=%7Berror-page%7D-privacy-policy"
                        target="_blank">Linkedin</a>
                </li>
                <li>
                    <a class="underline text-primary"
                        href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0"
                        target="_blank">Facebook</a>
                </li>
                <li>
                    <a class="underline text-primary"
                        href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect"
                        target="_blank">Instagram</a>
                </li>
                <li>
                    <a class="underline text-primary" href="https://x.com/en/privacy" target="_blank">X</a>
                </li>
            </ul>

            Ten en cuenta que no tenemos ningún control sobre cómo se utiliza la información de estas cookies ni
            tenemos
            acceso a ella.
        </div>

        <div class="mt-5 mb-3">
            <b>Cookies de Hotjar</b>
        </div>
        <div>
            Este Micrositio De Empleo utiliza Hotjar para comprender mejor la experiencia del usuario y optimizar nuestras páginas. Hotjar utiliza cookies analíticas para recopilar información sobre el comportamiento de los usuarios, como clics, movimientos del mouse y desplazamientos. Estos datos son completamente anónimos y se recopilan únicamente para mejorar la experiencia de navegación.
        </div>
        <div>
            Ten en cuenta que no tenemos ningún control sobre cómo Hotjar utiliza los datos recopilados, ni tenemos acceso directo a esta información. Para obtener más detalles sobre cómo Hotjar gestiona los datos personales y sus cookies, visita su <a class="underline text-primary" href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information" target="_blank">página de información sobre cookies</a> y su <a class="underline text-primary" href="https://www.hotjar.com/legal/policies/privacy/" target="_blank">política de privacidad</a>.
        </div>

        <div class="mt-5 mb-3">
            <b>Tu control de las cookies</b>
        </div>

        <div>
            Tienes derecho a decidir si aceptas o rechazas las cookies. Puedes ejercer tus preferencias relativas a
            las
            cookies indicándolo en el banner de cookies que se muestra cuando ingresas a este Micrositio De Empleo.
            Configurar Cookies
            Además de ejercer tus preferencias de cookies a través del banner de cookies, puedes restringir
            (bloquear o
            eliminar) las cookies modificando la configuración de tu navegador. Si restringes el uso de cookies, no
            podrás
            utilizar todas las funciones interactivas de este Micrositio De Empleo. La forma de restringir las
            cookies
            varía
            de un navegador a otro. Por lo tanto, debe visitar el menú de ayuda de tu navegador para obtener más
            información.
        </div>

        <div class="mt-5 mb-3">
            <b>Contáctanos</b>
        </div>

        <div>
            Si tienes alguna pregunta sobre esta política de cookies, por favor envía un correo electrónico a Haibu
            a
            <a class="underline text-primary" href="mailto:contacto&#64;haibu.com">contacto&#64;haibu.com</a>.
        </div>`;
    } else {
      this.part1 = `
      <div>This site is provided by Haibu in cooperation with ${this.companyName}. ${this.companyName} and Haibu
      ("we",
      "us")
      use cookies, web beacons, and similar technologies (collectively referred to as "cookies") on this
      Employment Microsite. It explains what cookies are, why they are used, and your options regarding their use.
      For more information on how ${this.companyName} handles personal data on this
      Employment Microsite, please visit the Privacy Policy <a class="underline text-primary" href="/notice" target="_blank">here</a>.
      <div class="mt-5 mb-3">
          <b>What are cookies?</b>
      </div>
      <div>A cookie is a small file that contains a string of characters sent to your computer when
          you visit a website. When you visit the website again, the cookie allows that site to recognize your
          browser.
          In
          this way, the information you provided previously can be retrieved, enabling you to use
          customized features more easily. Cookies can store user preferences and
          other
          information to enhance your experience on the Employment Microsite or be used to track you when
          browsing to
          other sites that also use third-party cookie services.</div>
      <div class="mt-5 mb-3">
          <b>What types of cookies are used on this Employment Microsite and how are they used?</b>
      </div>
      <ul class="list-disc pl-5">
          <li>Strictly necessary cookies: These cookies are essential to provide you with the services
              available on the Employment Microsite.</li>
          <li>Analytical cookies: These cookies collect information used in aggregate form to
              help us understand how the Employment Microsite is used or how effective marketing campaigns are,
              or to help us customize the Employment Microsite for you.</li>
          <li>Marketing cookies: These cookies are used to make advertising messages more
              relevant
              to you. They perform functions like preventing the same ad from appearing continuously,
              ensuring
              that ads are displayed correctly for advertisers and, in some cases, selecting
              ads based on your interests.</li>
          <li>Preference cookies: These cookies allow the Employment Microsite to remember information that
              changes the way the Employment Microsite behaves or looks, such as your preferred language or the
              region
              you are in. Losing the information stored in a preference cookie may
              make the experience less functional but will not prevent it from working.</li>
      </ul>
      <div class="mt-3">
          The complete list of active cookies served through this Employment Microsite and their purposes
          are listed below:
      </div>
    `;

      this.part2 = `
      <div>Below is more specific information about some of the cookies served by this
      Employment Microsite, if they are on the list above.</div>
      <div class="mt-5 mb-3">
          <b>Google Analytics</b>
      </div>
      <div>
          Third-party analytical cookies from Google (Google Analytics) are used by {{ companyName }} and Haibu
          for the same purpose. Consequently, we are jointly responsible for the use of these
          cookies.
          When using Google Analytics, data will be shared with Google. For more information, please see
          <a class="underline text-primary"
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">here</a>
          and
          also refer to Google Analytics' privacy policy <a class="underline text-primary"
              href="https://policies.google.com/privacy">here</a> for information on how
          they handle
          personal data.
      </div>
      <div class="mt-5 mb-3">
          <b>YouTube Cookies</b>
      </div>
      <div>
          This Employment Microsite may embed YouTube videos using YouTube's enhanced privacy mode.
          This mode may set marketing cookies on your computer when you click on the YouTube video player.
          Please note that we have no control over how the information from these cookies is used, nor
          do we have access to it.
          For more information, visit YouTube's video embedding information page
          <a class="underline text-primary" href="https://support.google.com/youtube/answer/171780">here</a>
          and
          YouTube's privacy policy <a class="underline text-primary" href="https://policies.google.com/privacy">here</a>.
      </div>
      <div class="mt-5 mb-3">
          <b>Social Media Cookies</b>
      </div>
      <div>
          This Employment Microsite may integrate with the following social media platforms, which
          independently, may set marketing cookies on your computer:
          <ul class="my-3 list-disc pl-5">
              <li>
                  <a class="underline text-primary"
                      href="https://www.linkedin.com/legal/privacy-policy?trk=%7Berror-page%7D-privacy-policy"
                      target="_blank">LinkedIn</a>
              </li>
              <li>
                  <a class="underline text-primary"
                      href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0"
                      target="_blank">Facebook</a>
              </li>
              <li>
                  <a class="underline text-primary"
                      href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect"
                      target="_blank">Instagram</a>
              </li>
              <li>
                  <a class="underline text-primary" href="https://x.com/en/privacy" target="_blank">X</a>
              </li>
          </ul>
          Please note that we have no control over how the information from these cookies is used, nor
          do we have access to it.
      </div>
      <div class="mt-5 mb-3">
          <b>Hotjar Cookies</b>
      </div>
      <div>
          This Employment Microsite uses Hotjar to better understand the user experience and optimize our pages. Hotjar uses analytical cookies to collect information about user behavior, such as clicks, mouse movements, and scrolls. This data is completely anonymous and is collected solely to improve the browsing experience.
      </div>
      <div>
          Please note that we have no control over how Hotjar uses the collected data, nor do we have direct access to this information. For more details on how Hotjar handles personal data and cookies, visit their <a class="underline text-primary" href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information" target="_blank">Cookie Information page</a> and their <a class="underline text-primary" href="https://www.hotjar.com/legal/policies/privacy/" target="_blank">Privacy Policy</a>.
      </div>
      <div class="mt-5 mb-3">
          <b>Your control of cookies</b>
      </div>
      <div>
          You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by indicating them in the cookie banner shown when you enter this Employment Microsite.
          Configure Cookies
          In addition to exercising your cookie preferences through the cookie banner, you can restrict
          (block or delete) cookies by changing your browser settings. If you restrict the use of cookies, you may not be able to use all the interactive features of this Employment Microsite. The way to restrict cookies varies
          from browser to browser. Therefore, you should visit your browser's help menu for more information.
      </div>
      <div class="mt-5 mb-3">
          <b>Contact us</b>
      </div>
      <div>
          If you have any questions about this cookie policy, please email Haibu at
          <a class="underline text-primary" href="mailto:contacto&#64;haibu.com">contacto&#64;haibu.com</a>.
      </div>
  `;
    }
  }
}
