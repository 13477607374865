export const environment = {
  production: true,
  apiUrl: 'https://haibuhr.com/develop',
  imageUrl: 'https://webapi.haibuhr.com/',
  candidateUrl: 'https://haibuhr.com/',
  candidateUrlExternal: 'https://haibuhr.com/',
  apiUrlUnsafe: 'https://webapi.haibuhr.com',
  apiDevelBassetUrl: "https://mi.basset.com.mx/",
  stripe: {
    monthly: {
      entry: 'price_1QYE7y06Uk7hPRLEaYBo7UbF',
      mid: 'price_1QYE7v06Uk7hPRLEIHZLNMrD',
      entryHH: 'price_1QYE7x06Uk7hPRLEeaFRU8gC',
      midHH: 'price_1QYE7r06Uk7hPRLEsyhRwLuk'
    },
    yearly: {
      entry: 'price_1QYE7p06Uk7hPRLEn64FGdmZ',
      mid: 'price_1QYE7k06Uk7hPRLEn6rtfN9A',
      entryHH: 'price_1QYE7n06Uk7hPRLErZBiqUuK',
      midHH: 'price_1QYE7g06Uk7hPRLEAMKGRkVV'
    }
  },

  /* apiUrl: 'https://devel.haibuhr.com/develop',
  imageUrl: 'https://webapi-devel.haibuhr.com/',
  candidateUrl: 'https://devel.haibuhr.com/',
  candidateUrlExternal: 'https://devel.haibuhr.com/',
  apiUrlUnsafe: 'https://webapi-devel.haibuhr.com',
  apiDevelBassetUrl: "https://devel.portal.basset.com.mx",
  stripe: {
    monthly: {
      entry: 'price_1QSlxR06Uk7hPRLEcawoRWho',
      mid: 'price_1QSm8606Uk7hPRLEC67ONSej',
      entryHH: 'price_1QSm5E06Uk7hPRLEUcoOcda9',
      midHH: 'price_1QSm8v06Uk7hPRLEbB1Q2K3x'
    },
    yearly: {
      entry: 'price_1QSnQT06Uk7hPRLEeu6UR395',
      mid: 'price_1QSnSb06Uk7hPRLEPE3d5s9q',
      entryHH: 'price_1QSnRw06Uk7hPRLESfn2lQXy',
      midHH: 'price_1QSnTA06Uk7hPRLE3XLYhE8s'
    }
  }, */
  recaptcha: {
    siteKey: '6LeycoYpAAAAANGS63LdXC02trwbg05LvESNM455',
  }
};

