<!-- <div #datepickerContainer class="custom-datepicker">
  <div class="datepicker-flex-container">
    <input type="text" class="dropdown-selected"
      [value]="control.value | date: 'mediumDate'"
      readonly (click)="datepicker.open()" />
    <img src="../../../../../assets/icons/calendar-icon.svg" class="dropdown-icon"
      (click)="datepicker.open()" (click)="$event.stopPropagation()"/>
  </div>
  <input matInput [matDatepicker]="datepicker" [formControl]="control" class="hidden-date-picker" (click)="$event.stopPropagation()">
  <mat-datepicker #datepicker (click)="$event.stopPropagation()"></mat-datepicker>
</div>
 -->
<div class="datepicker-flex-container" [ngClass]="{'full-width': isFullWidth}">
  <input type="date" class="dropdown-selected" [formControl]="control" [min]="minDate" [max]="maxDate"
    (change)="onDateChange($event)" (focusout)="onFocusOut()">
</div>