import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PlansDialogComponent } from '../plans-dialog/plans-dialog.component';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-freemium-dialog',
  templateUrl: './freemium-dialog.component.html',
  styleUrls: ['./freemium-dialog.component.css']
})
export class FreemiumDialogComponent implements OnInit {

  title: string = '';
  icon: string = 'assets/img/premium.png';
  secondAction: string;
  action: number = 0;
  action2: number = 0;
  btnText: string = 'newFeaturesBtn';
  actualPlan: string;
  canceling: boolean = false;
  testHaibu: boolean = false;
  token: APIResponse = this.localService.getJsonValue('token');

  constructor(
    private localService: LocalService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<FreemiumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = this.translate.instant('getNewFeatures') + '<br>' + this.translate.instant('getNewFeatures2');

    if (this.token?.data?.comP_SUBSCRIPTION_STATUS == 'Trial') {
      this.testHaibu = true;
    }

    if (data && data.secondAction) {
      this.secondAction = data.secondAction;
      // el otro icono es 'assets/img/lupa.png'
      this.icon = 'assets/img/lupa.png';
    }

    if (data && data.icon) {
      this.icon = data.icon;
    }

    if (data && data.title) {
      this.title = data.title;
    }

    if (data && data.action) {
      this.action = data.action;
    }

    if (data && data.btnText) {
      this.btnText = data.btnText;
    }

    if (data && data.action2) {
      this.action2 = data.action2;
    }

    if (data && data.plan) {
      this.actualPlan = data.plan;
    }

    if (data && data.canceling) {
      this.canceling = data.canceling;
    }
  }

  ngOnInit(): void {
  }

  scheduleMeet() {
    window.open('https://meet.goodtime.io/w/haibuhrcom/jgasteasoro/video-call/intro', '_blank');
  }

  closeModal(value: boolean = false) {
    this.dialogRef.close(value);
  }

  openPlansModal() {
    if (this.action === 1) {
      this.scheduleMeet();
      return;
    }

    const planModal = this.dialog.open(PlansDialogComponent, {
      panelClass: 'plans-dialog',
      width: '1200px',
      maxWidth: '95vw',
    });

    planModal.afterClosed().subscribe(result => {
      const salesModal = this.dialog.open(FreemiumDialogComponent, {
        panelClass: 'freemium-dialog',
        width: '500px',
        maxWidth: '95vw',
        data: {
          title: this.translate.instant('notReady'),
          secondAction: this.translate.instant('continueFreemium', { plan: this.translate.instant(this.actualPlan) + (this.testHaibu ? this.translate.instant('Trial') : '') }),
          action: 1,
          btnText: 'contactSales'
        }
      });

      salesModal.afterClosed().subscribe(result => {
        if (this.actualPlan != 'Freemium') {
          this.dialog.open(FreemiumDialogComponent, {
            panelClass: 'freemium-dialog',
            width: '500px',
            maxWidth: '95vw',
            data: {
              title: this.translate.instant('loosedFeatures'),
              secondAction: this.translate.instant('continueFreemium', { plan: this.translate.instant(this.actualPlan) + (this.testHaibu ? this.translate.instant('Trial') : '') }),
              icon: 'none',
              plan: this.actualPlan
            }
          });
        }
        this.dialogRef.close();
      });
    });

    this.dialogRef.close();
  }
}
