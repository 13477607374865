import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HomeComponent } from 'src/app/home/home.component';
import { userRole } from 'src/app/interfaces/role';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-add-or-edit-role',
  templateUrl: './add-or-edit-role.component.html',
  styleUrls: ['./add-or-edit-role.component.css']
})
export class AddOrEditRoleComponent implements OnInit {

  isNew: boolean = true;
  isChecked: boolean = false;
  role: userRole = {
    id: '',
    name: '',
    normalizedName: '',
    rlS_TYPE: '',
    companyID: '',
    createdBy: '',
    creatorName: '',
    creatorLastName: '',
    createdAt: '',
    updatedBy: '',
    updatedAt: '',
    number_Rol: 0,
    totaL_COUNT: 0,
    totaL_PAGES: 0
  };
  formGroup: FormGroup;
  companySee = '';
  companyEdit = '';
  company = '';
  sending: boolean = false;

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { 'whitespace': true };
  }

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<HomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: userRole | string
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    this.company = id;

    this.companySee = this.translate.instant('catalogueRoleDialogCompanySee');
    this.companyEdit = this.translate.instant('catalogueRoleDialogCompanyEdit');

    if (typeof this.data == 'string') {
      this.formGroup.get('cd012').setValue(false);
      this.formGroup.get('v006').setValue(false);

      this.formGroup.get('cd001').setValue(true);
      this.formGroup.get('v005').setValue(true);
    } else {
      this.puzzleService.getRoleById(this.data.id).subscribe(res => {
        this.role = res['rol'];
        const permissions = res['permissions'];
        this.isNew = false;

        this.formGroup.get('name').setValue(this.transformValue(this.getTranslatedRole(this.role.name)));

        permissions.forEach(permission => {
          const control = this.formGroup.get(permission.prS_CODE);
          if (control) {
            control.setValue(permission.hasPermission);
          }
        });

        if (!this.formGroup.get('cd001').value && !this.formGroup.get('cd012').value) {
          this.formGroup.get('cd001').setValue(true);
        }
        if (!this.formGroup.get('v005').value && !this.formGroup.get('v006').value) {
          this.formGroup.get('v005').setValue(true);
        }
      });
    }
  }

  initForm() {
    this.formGroup = this.fb.group(
      {
        name: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator, this.noSpecialCharactersValidator]),
        dsh001: new FormControl(true),
        m001: new FormControl(true),
        m002: new FormControl(true),
        c001: new FormControl(true),
        c002: new FormControl(true),
        r001: new FormControl(true),
        r002: new FormControl(true),
        r003: new FormControl(true),
        u004: new FormControl(true),
        u01: new FormControl(true),
        u02: new FormControl(true),
        u005: new FormControl(true),
        v001: new FormControl(true),
        v002: new FormControl(true),
        v003: new FormControl(true),
        v004: new FormControl(true),
        v005: new FormControl(true),
        v006: new FormControl(true),
        cd001: new FormControl(true),
        cd002: new FormControl(false),
        cd003: new FormControl(true),
        cd004: new FormControl(true),
        cd005: new FormControl(true),
        cd006: new FormControl(true),
        cd007: new FormControl(true),
        cd008: new FormControl(true),
        cd009: new FormControl(true),
        cd010: new FormControl(true),
        cd011: new FormControl(true),
        cd012: new FormControl(true),
      }
    );
  }

  campoNoValido(campo: string) {
    return (
      (this.formGroup.get(campo)?.invalid &&
        this.formGroup.get(campo)?.touched)
    );
  }

  onEnterPress(event: KeyboardEvent, controlName: string) {
    if (event.key === 'Enter') {
      this.formGroup.get(controlName).setValue(!this.formGroup.get(controlName).value);
    }
    event.preventDefault();
  }

  onSeeCompany(event, controlName: string) {
    switch (controlName) {
      case 'm001':
        if (!this.formGroup.get('m001').value)
          this.formGroup.get('m002').setValue(this.formGroup.get('m001').value);
        break;
      case 'm002':
        if (this.formGroup.get('m002').value)
          this.formGroup.get('m001').setValue(this.formGroup.get('m002').value);
        break;
      case 'c001':
        if (!this.formGroup.get('c001').value)
          this.formGroup.get('c002').setValue(this.formGroup.get('c001').value);
        break;
      case 'c002':
        if (this.formGroup.get('c002').value)
          this.formGroup.get('c001').setValue(this.formGroup.get('c002').value);
        break;
      case 'r001':
        if (!this.formGroup.get('r001').value) {
          this.formGroup.get('r002').setValue(this.formGroup.get('r001').value);
          this.formGroup.get('r003').setValue(this.formGroup.get('r001').value);
        }
        break;
      case 'r002':
        if (this.formGroup.get('r002').value)
          this.formGroup.get('r001').setValue(this.formGroup.get('r002').value);
        break;
      case 'r003':
        if (this.formGroup.get('r003').value)
          this.formGroup.get('r001').setValue(this.formGroup.get('r003').value);
        break;
      case 'u004':
        if (!this.formGroup.get('u004').value) {
          this.formGroup.get('u01').setValue(this.formGroup.get('u004').value);
          this.formGroup.get('u02').setValue(this.formGroup.get('u004').value);
          this.formGroup.get('u005').setValue(this.formGroup.get('u004').value);
        }
        break;
      case 'u01':
        if (this.formGroup.get('u01').value)
          this.formGroup.get('u004').setValue(this.formGroup.get('u01').value);
        break;
      case 'u02':
        if (this.formGroup.get('u02').value)
          this.formGroup.get('u004').setValue(this.formGroup.get('u02').value);
        break;
      case 'u005':
        if (this.formGroup.get('u005').value)
          this.formGroup.get('u004').setValue(this.formGroup.get('u005').value);
        break;
      case 'cd001':
        this.formGroup.get('cd012').setValue(!this.formGroup.get('cd001').value);
        this.formGroup.get('v005').setValue(this.formGroup.get('cd001').value);
        this.formGroup.get('v006').setValue(!this.formGroup.get('cd001').value);
        break;
      case 'cd012':
        this.formGroup.get('v005').setValue(!this.formGroup.get('cd012').value);
        this.formGroup.get('cd001').setValue(this.formGroup.get('v005').value);
        this.formGroup.get('v006').setValue(this.formGroup.get('cd012').value);
      case 'v005':
        this.formGroup.get('v006').setValue(!this.formGroup.get('v005').value);
        this.formGroup.get('cd001').setValue(this.formGroup.get('v005').value);
        this.formGroup.get('cd012').setValue(!this.formGroup.get('v005').value);
        break;
      case 'v006':
        this.formGroup.get('v005').setValue(!this.formGroup.get('v006').value);
        this.formGroup.get('cd001').setValue(this.formGroup.get('v005').value);
        this.formGroup.get('cd012').setValue(!this.formGroup.get('v005').value);
        break;
      default:
        break;
    }
    event.preventDefault();
  }

  onSubmit() {
    if (this.sending) return;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      const obligatoryFieldsMessage = this.translate.instant('candidateDetailAlert');
      this.openResultDialog(0, obligatoryFieldsMessage);
      return;
    }
    this.sentRole();
  }

  sentRole() {
    this.sending = true;
    const role = {
      Name: this.formGroup.get('name').value,
      CompanyId: this.company,
      PermissionsJson: '',
      RlsType: 'WBAPP'
    };

    if (role.Name === 'Administrator') {
      role.Name = 'Administrador';
    } else if (role.Name === 'Executive') {
      role.Name = 'Ejecutivo';
    }

    if (this.role.id) {
      role['RoleId'] = this.role.id;
    }

    const permissions = []
    for (const key in this.formGroup.controls) {
      if (key === 'name') continue;
      permissions.push({ code: key, isActive: this.formGroup.get(key).value });
    }
    role.PermissionsJson = JSON.stringify(permissions);

    const translatedMessage = this.translate.instant('roleUpdateWait');
    this.puzzleService.createOrEditRole(role).subscribe(
      response => {
        const translatedMessage = this.translate.instant(response.message);
        this.dialogRef.close([{ isSuccess: response.isSuccess, message: translatedMessage }]);
      },
      error => {
        const errorMessage = this.translate.instant('addRoleAlert');
        this.dialogRef.close([{ isSuccess: false, message: errorMessage }]);
      }
    ),
      () => {
        this.sending = false;
      };
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
            disableClose: true
          });
        }
        break;
    }
  }

  getTranslatedRole(role: string) {
    if (role === 'Administrador' || role === 'Ejecutivo' || role === 'Cliente') {
      return this.translate.instant(role.toLowerCase());
    }
    return role;
  }

  get nameErrorMsg(): string {
    const errors = this.formGroup.get('name')?.errors;
    if (errors?.required) {
      return this.translate.instant('addDialogNameAlertObligatory');
    } else if (errors?.maxlength) {
      return this.translate.instant('addDialogNameAlertMax');
    } else {
      return this.translate.instant('addDialogNameAlertInvalid');
    }
    return '';
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]/g, '').trimStart();
  }
}
