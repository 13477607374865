import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { LocalService } from 'src/app/services/local.service';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isDate } from 'moment';

@Component({
  selector: 'app-lang-datepicker',
  templateUrl: './lang-datepicker.component.html',
  styleUrls: ['./lang-datepicker.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class LangDatepickerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() date: string | Date;
  @Input() min: string | Date;
  @Input() max: string | Date;
  @Input() lang: string;
  @Input() required: boolean = false;
  @Input() tabindex: number = 0;
  @Input() disabled: boolean = false;
  @Input() height: string = 'altura-48';
  @Input() borde: string = '#BDBDBD';
  @Output() datechange = new EventEmitter<string>();
  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();

  selectedDate: Date;
  borderColor: string = '#BDBDBD';

  @Input() clickOutsideDirective: ClickOutsideDirective;
  @ViewChild('dp') datepicker: MatDatepicker<Date>;

  days: number[] = [];
  months = [
    { value: 1, name: 'Enero' },
    { value: 2, name: 'Febrero' },
    { value: 3, name: 'Marzo' },
    { value: 4, name: 'Abril' },
    { value: 5, name: 'Mayo' },
    { value: 6, name: 'Junio' },
    { value: 7, name: 'Julio' },
    { value: 8, name: 'Agosto' },
    { value: 9, name: 'Septiembre' },
    { value: 10, name: 'Octubre' },
    { value: 11, name: 'Noviembre' },
    { value: 12, name: 'Diciembre' }
  ];
  years: number[] = [];
  languageSelected: string = 'en';
  editForm!: FormGroup;

  constructor(
    private _adapter: DateAdapter<any>,
    private localService: LocalService,
    private fb: FormBuilder,
    private renderer: Renderer2,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.borde) {
      this.borderColor = changes.borde.currentValue;
    }

    if (changes['date']) {
      const newDateValue = changes['date'].currentValue;

      if (newDateValue instanceof Date) {
        this.selectedDate = newDateValue;
      } else if (typeof newDateValue === 'string') {
        if (newDateValue.includes('T')) {
          this.date = newDateValue.split('T')[0];
        }
        const [year, month, day] = this.date.toString().split('-');
        this.selectedDate = new Date(+year, +month - 1, +day);
      }
    }

    if (changes['lang']) {
      let lang = changes['lang'].currentValue
      if (lang == 'esp') {
        lang = 'es';
      } else if (lang == 'eng') {
        lang = 'en';
      }
      this.languageSelected = lang;
    }

    if (changes['min']) {
      this.initDatePickers();
      this.updateMonths();
      this.updateDaysInMonth();
      this.checkIfDateIsInRange();
    }

    if (changes['max']) {
      this.initDatePickers();
      this.updateMonths();
      this.updateDaysInMonth();
      this.checkIfDateIsInRange();
    }
  }

  ngAfterViewInit() {
    /* if (this.clickOutsideDirective) {
      this.datepicker.openedStream.subscribe(() => {
        this.clickOutsideDirective.setDatepickerOpen(true);
      });
      this.datepicker.closedStream.subscribe(() => {
        this.clickOutsideDirective.setDatepickerOpen(false);
      });
    } */

    if (this.tabindex) {
      // renderizar todos los mat-select con el tabindex
      const elements = document.querySelectorAll('.date-select');
      elements.forEach((element: HTMLElement) => {
        element.setAttribute('tabindex', this.tabindex.toString());
      });
    }
  }

  ngOnInit(): void {
    this.borderColor = this.borde;
    let lang = 'es'
    let tokencito = this.localService.getJsonValue('token');
    if (tokencito?.data?.languagePreference) {
      lang = this.mapBrowserLangToAppLang(tokencito?.data?.languagePreference);
    } else if (this.localService.getJsonValue('lang')) {
      lang = this.mapBrowserLangToAppLang(this.localService.getJsonValue('lang'));
    }
    this._adapter.setLocale(lang);
    this.languageSelected = lang;

    this.editForm = this.fb.group({
      day: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required]
    });

    this.initDatePickers();
    this.updateDaysInMonth();

    if (this.date) {
      if (this.date instanceof Date) {
        // Si es de tipo Date, simplemente lo asignamos a selectedDate
        this.selectedDate = this.date;
        // set the form values
        this.editForm?.get('day')?.setValue(this.selectedDate.getDate());
        this.editForm?.get('month')?.setValue(this.selectedDate.getMonth() + 1);
        this.editForm?.get('year')?.setValue(this.selectedDate.getFullYear());
      } else if (typeof this.date === 'string') {
        // Si es un string, verificamos si contiene 'T' y lo procesamos
        if (this.date.includes('T')) {
          this.date = this.date.split('T')[0];
        }

        const [year, month, day] = this.date.split('-');
        this.selectedDate = new Date(+year, +month - 1, +day);
        // set the form values
        this.editForm?.get('day')?.setValue(this.selectedDate.getDate());
        this.editForm?.get('month')?.setValue(this.selectedDate.getMonth() + 1);
        this.editForm?.get('year')?.setValue(this.selectedDate.getFullYear());
      } else {
        console.warn('El formato de fecha no es válido.');
      }
    }
  }

  updateMonths() {
    const year = this.editForm?.get('year')?.value;

    // Inicializar con todos los meses
    this.months = [
      { value: 1, name: 'Enero' },
      { value: 2, name: 'Febrero' },
      { value: 3, name: 'Marzo' },
      { value: 4, name: 'Abril' },
      { value: 5, name: 'Mayo' },
      { value: 6, name: 'Junio' },
      { value: 7, name: 'Julio' },
      { value: 8, name: 'Agosto' },
      { value: 9, name: 'Septiembre' },
      { value: 10, name: 'Octubre' },
      { value: 11, name: 'Noviembre' },
      { value: 12, name: 'Diciembre' }
    ];

    // Filtrar meses según el año máximo
    if (this.max && !isDate(this.max)) {
      if (this.max.includes('T')) {
        this.max = this.max.split('T')[0];
      }

      const [year, month, day] = this.max.split('-');
      this.max = new Date(+year, +month - 1, +day);
    }

    if (this.max && isDate(this.max) && year === this.max.getFullYear()) {
      const maxMonth = this.max.getMonth() + 1;
      this.months = this.months.filter(month => month.value <= maxMonth);
    }

    if (this.min && !isDate(this.min)) {
      if (this.min.includes('T')) {
        this.min = this.min.split('T')[0];
      }

      const [year, month, day] = this.min.split('-');
      this.min = new Date(+year, +month - 1, +day);
    }

    // Filtrar meses según el año mínimo
    if (this.min && isDate(this.min) && year === this.min.getFullYear()) {
      const minMonth = this.min.getMonth() + 1;
      this.months = this.months.filter(month => month.value >= minMonth);
    }

    // si hay mes seleccionado, verificar si está en el rango
    if (this.editForm?.get('month')?.value) {
      const month = this.editForm?.get('month')?.value;
      if (this.max && isDate(this.max) && year === this.max.getFullYear() && month > this.max.getMonth() + 1) {
        this.editForm?.get('month')?.setValue(null);
      }

      if (this.min && isDate(this.min) && year === this.min.getFullYear() && month < this.min.getMonth() + 1) {
        this.editForm?.get('month')?.setValue(null);
      }
    }
  }

  initDatePickers() {
    const currentYear = new Date().getFullYear();
    let startYear = currentYear - 80;
    let endYear = currentYear + 20;

    if (this.min) {
      // si min es string, convertirlo a Date
      if (typeof this.min === 'string') {
        if (this.min.includes('T')) {
          this.min = this.min.split('T')[0];
        }

        const [year, month, day] = this.min.split('-');
        this.min = new Date(+year, +month - 1, +day);
      }
      startYear = this.min.getFullYear();
    }

    if (this.max) {
      // si max es string, convertirlo a Date
      if (typeof this.max === 'string') {
        if (this.max.includes('T')) {
          this.max = this.max.split('T')[0];
        }

        const [year, month, day] = this.max.split('-');
        this.max = new Date(+year, +month - 1, +day);
      }
      endYear = this.max.getFullYear();
    }

    this.years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

    // si hay año seleccionado, verificar si está en el rango
    if (this.editForm?.get('year')?.value) {
      const year = this.editForm?.get('year')?.value;
      if (this.max && isDate(this.max) && year > this.max.getFullYear()) {
        this.editForm?.get('year')?.setValue(null);
      }

      if (this.min && isDate(this.min) && year < this.min.getFullYear()) {
        this.editForm?.get('year')?.setValue(null);
      }
    }
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'eng': 'en',
      'esp': 'es'
    };
    return langMap[browserLang] || 'es';
  }

  dateChanged(event: any) {
    this.datechange.emit(event.value.format('YYYY-MM-DD'));
  }

  openDatepicker() {
    this.datepicker.open();
  }

  changeLang(lang: string) {
    this._adapter.setLocale(this.mapBrowserLangToAppLang(lang));
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

  onDayChange(event: any) {
    this.editForm?.get('day')?.setValue(event?.value);
    this.updateDaysInMonth();

    this.checkIfDateIsInRange();

    if (this.editForm?.get('day')?.value && this.editForm?.get('month')?.value && this.editForm?.get('year')?.value) {
      const day = this.editForm?.get('day')?.value;
      const month = this.editForm?.get('month')?.value;
      const year = this.editForm?.get('year')?.value;
      this.datechange.emit(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
    } else {
      this.datechange.emit('');
    }
  }

  // Escuchar cambios en el mes para actualizar los días disponibles
  onMonthChange(event: any) {
    this.editForm?.get('month')?.setValue(event?.value);
    this.updateMonths();
    this.updateDaysInMonth();

    this.checkIfDateIsInRange();

    // si ya hay dia, mes y año seleccionados, emitir la fecha
    if (this.editForm?.get('day')?.value && this.editForm?.get('month')?.value && this.editForm?.get('year')?.value) {
      const day = this.editForm?.get('day')?.value;
      const month = this.editForm?.get('month')?.value;
      const year = this.editForm?.get('year')?.value;
      this.datechange.emit(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
    } else {
      this.datechange.emit('');
    }
  }

  // Escuchar cambios en el año para actualizar si es bisiesto
  onYearChange(event: any) {
    this.editForm?.get('year')?.setValue(event?.value);
    const year = this.editForm?.get('year')?.value;

    // Inicializar con todos los meses
    this.months = [
      { value: 1, name: 'Enero' },
      { value: 2, name: 'Febrero' },
      { value: 3, name: 'Marzo' },
      { value: 4, name: 'Abril' },
      { value: 5, name: 'Mayo' },
      { value: 6, name: 'Junio' },
      { value: 7, name: 'Julio' },
      { value: 8, name: 'Agosto' },
      { value: 9, name: 'Septiembre' },
      { value: 10, name: 'Octubre' },
      { value: 11, name: 'Noviembre' },
      { value: 12, name: 'Diciembre' }
    ];

    // Filtrar meses según el año máximo
    if (this.max && !isDate(this.max)) {
      if (this.max.includes('T')) {
        this.max = this.max.split('T')[0];
      }

      const [year, month, day] = this.max.split('-');
      this.max = new Date(+year, +month - 1, +day);
    }

    if (this.max && isDate(this.max) && year === this.max.getFullYear()) {
      const maxMonth = this.max.getMonth() + 1;
      this.months = this.months.filter(month => month.value <= maxMonth);
    }

    if (this.min && !isDate(this.min)) {
      if (this.min.includes('T')) {
        this.min = this.min.split('T')[0];
      }

      const [year, month, day] = this.min.split('-');
      this.min = new Date(+year, +month - 1, +day);
    }

    // Filtrar meses según el año mínimo
    if (this.min && isDate(this.min) && year === this.min.getFullYear()) {
      const minMonth = this.min.getMonth() + 1;
      this.months = this.months.filter(month => month.value >= minMonth);
    }

    // si hay mes seleccionado, verificar si está en el rango
    if (this.editForm?.get('month')?.value) {
      const month = this.editForm?.get('month')?.value;
      if (this.max && isDate(this.max) && year === this.max.getFullYear() && month > this.max.getMonth() + 1) {
        this.editForm?.get('month')?.setValue(null);
      }

      if (this.min && isDate(this.min) && year === this.min.getFullYear() && month < this.min.getMonth() + 1) {
        this.editForm?.get('month')?.setValue(null);
      }
    }

    this.updateDaysInMonth();

    this.checkIfDateIsInRange();

    if (this.editForm?.get('day')?.value && this.editForm?.get('month')?.value && this.editForm?.get('year')?.value) {
      const day = this.editForm?.get('day')?.value;
      const month = this.editForm?.get('month')?.value;
      const year = this.editForm?.get('year')?.value;
      this.datechange.emit(`${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
    } else {
      this.datechange.emit('');
    }
  }

  checkIfDateIsInRange() {
    if (this.editForm?.get('day')?.value && this.editForm?.get('month')?.value && this.editForm?.get('year')?.value) {
      const day = this.editForm?.get('day')?.value;
      const month = this.editForm?.get('month')?.value;
      const year = this.editForm?.get('year')?.value;
      const selectedDate = new Date(year, month - 1, day);

      if (this.min && isDate(this.min) && selectedDate < this.min) {
        this.editForm?.get('day')?.setValue(null);
        this.editForm?.get('month')?.setValue(null);
        this.editForm?.get('year')?.setValue(null);
      }

      if (this.max && isDate(this.max) && selectedDate > this.max) {
        this.editForm?.get('day')?.setValue(null);
        this.editForm?.get('month')?.setValue(null);
        this.editForm?.get('year')?.setValue(null);
      }
    }
  }

  // Actualiza el número de días basado en el mes y el año seleccionado
  updateDaysInMonth() {
    const month = this.editForm?.get('month')?.value || 1;
    const year = this.editForm?.get('year')?.value || new Date().getFullYear();

    // Determina el número de días en el mes
    if (month === 2) {
      // Si es febrero, revisa si es año bisiesto
      this.days = this.isLeapYear(year) ? Array.from({ length: 29 }, (_, i) => i + 1) : Array.from({ length: 28 }, (_, i) => i + 1);
    } else if ([4, 6, 9, 11].includes(month)) {
      // Abril, Junio, Septiembre, Noviembre tienen 30 días
      this.days = Array.from({ length: 30 }, (_, i) => i + 1);
    } else {
      // Los demás meses tienen 31 días
      this.days = Array.from({ length: 31 }, (_, i) => i + 1);
    }

    if (this.max && !isDate(this.max)) {
      if (this.max.includes('T')) {
        this.max = this.max.split('T')[0];
      }

      const [year, month, day] = this.max.split('-');
      this.max = new Date(+year, +month - 1, +day);
    }

    // quitar los dias sobrantes si hay un max
    if (this.max && isDate(this.max) && this.editForm?.get('year')?.value === this.max.getFullYear() && this.editForm?.get('month')?.value === this.max.getMonth() + 1) {
      const maxDay = this.max.getDate();
      this.days = this.days.filter(day => day <= maxDay);
    }

    if (this.min && !isDate(this.min)) {
      if (this.min.includes('T')) {
        this.min = this.min.split('T')[0];
      }

      const [year, month, day] = this.min.split('-');
      this.min = new Date(+year, +month - 1, +day);
    }

    // quitar los dias sobrantes si hay un min
    if (this.min && isDate(this.min) && this.editForm?.get('year')?.value === this.min.getFullYear() && this.editForm?.get('month')?.value === this.min.getMonth() + 1) {
      const minDay = this.min.getDate();
      this.days = this.days.filter(day => day >= minDay);
    }

    // Obtiene el valor actual del día ej. 31
    const day = this.editForm?.get('day')?.value;

    // Valida si el día seleccionado está dentro del rango válido
    if (day && this.days.indexOf(day) === -1) {
      // Si el día es mayor que el número máximo de días
      this.editForm?.get('day')?.setValue(null);
    }
  }

  // Función para determinar si un año es bisiesto
  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }
}
