import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PuzzleService } from '../../services/puzzle.service';
import { HomeComponent } from '../../home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Source } from '../../interfaces/source';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-or-edit-source',
  templateUrl: './add-or-edit-source.component.html',
  styleUrls: ['./add-or-edit-source.component.css']
})
export class AddOrEditSourceComponent implements OnInit {

  listaSources: Source[] = [];

  sendSource: Source = {
    recruitmenT_SOURCE_COMPANY_ID: '',
    recruitmenT_SOURCE_NAME: '',
    recruitmenT_SOURCE_DESCRIPTION: '',
    recruitmenT_SOURCE_ID: '',
  };

  esNuevo: boolean = false;
  tabCount: number = 0;
  sending: boolean = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.tabCount++;
      if (this.tabCount === 2) {
        const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
        if (submitButton) {
          submitButton.focus();
        }
      }
    } else if (event.key !== 'Enter') {
      this.tabCount = 0;
    }
  }

  @HostListener('document:keydown.tab', ['$event'])
  handleTabPress(event: KeyboardEvent) {
    this.tabCount++;
    if (this.tabCount === 2) {
      const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.focus();
      }
    }
  }
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent) {
    if (this.esNuevo) return;
    if (this.tabCount === 1) {
      event.preventDefault();
      if (!this.esNuevo) {
        this.addSource();
      }
    } else if (this.tabCount >= 2) {
      event.preventDefault();
      this.submitForm();
    }
    this.tabCount = 0;
  }

  constructor(
    private elementRef: ElementRef,
    private translate: TranslateService,
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Source | string
  ) { }

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [this.isRequiredIfEmptyArray.bind(this), this.noSpecialCharactersValidator]],
    descripcion: ['', [this.noSpecialCharactersValidator]]
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  public isRequiredIfEmptyArray(control: FormControl) {
    const value = control.value || '';

    // Supongo que listaSources es una propiedad del componente que contiene el array.
    if (this.listaSources.length === 0 && !value.trim()) {
      return { 'required': true };
    }

    return null;
  }

  ngOnInit(): void {
    if (!(typeof this.data == 'string')) {
      this.esNuevo = true;
      this.miFormulario.reset({
        nombre: this.transformValue(this.data.recruitmenT_SOURCE_NAME),
        descripcion: this.transformValue(this.data.recruitmenT_SOURCE_DESCRIPTION)
      })
    }
  }

  addSource(event?: Event) {
    if (!this.esNuevo) {

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.miFormulario.markAllAsTouched();
      const nombreValue = this.miFormulario.get('nombre').value || '';
      if (nombreValue.trim() !== '' && this.miFormulario.valid) {
        // validar que no exista el nombre en la lista
        const existeNombre = this.listaSources.find(source => source.recruitmenT_SOURCE_NAME === nombreValue);
        if (!existeNombre) {
          const nuevoSource = this.createSource();
          this.listaSources.unshift(nuevoSource);
          this.miFormulario.reset();
        } else {
          this.miFormulario.get('nombre')?.setValue('');
        }
      }
    }
  }

  createSource() {
    let sourceId = null;
    if (typeof this.data !== 'string' && this.data.recruitmenT_SOURCE_ID) {
      sourceId = this.data.recruitmenT_SOURCE_ID;
    }
    return {
      recruitmenT_SOURCE_COMPANY_ID: typeof this.data === 'string' ? this.data : this.data.recruitmenT_SOURCE_COMPANY_ID,
      recruitmenT_SOURCE_NAME: this.miFormulario.get('nombre').value,
      recruitmenT_SOURCE_DESCRIPTION: this.miFormulario.get('descripcion').value,
      recruitmenT_SOURCE_ID: sourceId,
    };
  }

  async submitForm() {
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.valid) {
      const nombreValue = this.miFormulario.get('nombre').value || '';
      const descripcionValue = this.miFormulario.get('descripcion').value || '';
      if (nombreValue.trim() !== '') {
        const source = {
          ...this.createSource(),
          recruitmenT_SOURCE_DESCRIPTION: descripcionValue
        };
        if (this.listaSources.length === 0) {
          this.sentSource(source);
          return;
        } else {
          this.listaSources.push(source);
        }
      }
      const sourcesToSend = this.listaSources.map(source => ({
        ...source,
        esNuevo: !source.recruitmenT_SOURCE_ID,
        recruitmenT_SOURCE_DESCRIPTION: source.recruitmenT_SOURCE_DESCRIPTION || ''
      }));
      if (sourcesToSend.length > 0) {
        let ultimoNumeroFase = 0;
        let responses = [];
        this.sending = true;

        const promises = sourcesToSend.map(async (source, index) => {
          source.recruitmenT_SOURCE_NUMBER = ultimoNumeroFase + 1 + index;

          try {
            const response = await this.puzzleService.createOrEditSource(source).toPromise();
            return {
              isSuccess: response.isSuccess,
              message: this.translate.instant(response.message),
              name: source.recruitmenT_SOURCE_NAME
            };
          } catch (error) {
            const errorMessage = this.translate.instant('addDepartamentAlert');
            return { isSuccess: false, message: errorMessage, name: source.recruitmenT_SOURCE_NAME };
          }
        });

        try {
          responses = await Promise.all(promises);
        } catch (error) {
          console.error("Error en las promesas:", error);
        }
        this.dialogRef.close(responses);
      } /* else {
        this.dialogRef.close([]);
      } */

    }
  }

  sentSource(source) {
    this.sending = true;
    this.puzzleService.createOrEditSource(source).subscribe(
      response => {
        const translatedMessage = this.translate.instant(response.message);
        this.dialogRef.close([{ isSuccess: response.isSuccess, message: translatedMessage }]);
      },
      error => {
        const errorMessage = this.translate.instant('addSourceAlert');
        this.dialogRef.close([{ isSuccess: false, message: errorMessage }]);
      }
    );
  }

  deleteSource(index: number): void {
    this.listaSources.splice(index, 1);
  }

  invalidField(field: string): boolean {
    const fieldValue = this.miFormulario.get(field)?.value || '';
    if (this.listaSources.length === 0) {
      return (this.miFormulario.get(field)?.invalid || fieldValue.trim() === '') && this.miFormulario.get(field)?.touched;
    }
    return false;
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]/g, '').trimStart();
  }

}

