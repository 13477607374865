<div cdkDrag class="p-3 px-4 border-quest mb-3">
    <div class="flex items-center justify-between">
        <div>
            <label class="green-subtitle">
                {{ 'questionLabel' | translate }} {{ index + 1 }}
            </label>
        </div>
        <div class="flex gap-1" [tourAnchor]="seted ? '' : 'catalogs.job.quest.add.modal.questionActions'">
            <mat-icon class="icon-btn" (click)="onDuplicate()"
                [matTooltip]="'duplicateQuestion' | translate">content_copy</mat-icon>
            <mat-icon *ngIf="deleteAvailable" class="icon-btn" (click)="onDelete()"
                [matTooltip]="'deleteQuestion' | translate">delete</mat-icon>
            <mat-icon cdkDragHandle class="icon-btn">drag_handle</mat-icon>
        </div>
    </div>

    <div class="mt-2 grid grid-cols-1 md:grid-cols-2 gap-3">
        <div [tourAnchor]="seted ? '' : 'catalogs.job.quest.add.modal.question'" class="pt-2">
            <label class="pr-6 question-label" for="nombre">
                {{ 'questionTitleLabel' | translate }}
            </label>
            <input id="testTitle" [(ngModel)]="question.questioN_TEXT" class="p-2 w-full mt-1 borde" type="text"
                name="nombre" maxlength="500" oninput='this.value = this.value.trimStart();' />
            <div *ngIf="!question.questioN_TEXT" class="text-red-500 text-xs mt-1">
                <span>
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('questionTitleLabel' | translate) } }}
                </span>
            </div>
        </div>
        <div [tourAnchor]="seted ? '' : 'catalogs.job.quest.add.modal.questionType'" class="pt-2">
            <label class="pr-6 question-label" for="type">
                {{ 'questionTypeLabel' | translate }}
            </label>
            <mat-select *ngIf="question.questioN_TYPE" [(ngModel)]="question.questioN_TYPE"
                class="p-2 w-full mt-1 borde" name="type" (selectionChange)="changeType($event)">
                <mat-option *ngFor="let type of questionTypes" [value]="type">
                    {{ type | translate }}
                </mat-option>
            </mat-select>
        </div>
    </div>

    <div *ngIf="question.questioN_TYPE == 'YES_NO'" class="mt-4">
        <mat-radio-group [tourAnchor]="getTourAnchor()" [(ngModel)]="selectedOption" aria-label="Select an option" class="flex flex-col gap-4 custom-radio">
            <mat-radio-button *ngFor="let option of question.options; let i = index" [value]="i"
                (change)="changeCorrectOption(i)">
                {{ option.optioN_TEXT | translate }}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="question.questioN_TYPE == 'MULTIPLE_CHOICE'" class="mt-4">
        <mat-radio-group [tourAnchor]="getTourAnchor()" [(ngModel)]="selectedOption" aria-label="Select an option" class="flex w-full flex-col gap-2 custom-radio">
            <div *ngFor="let option of question.options; let i = index" class="w-full">
                <div class="w-full flex items-center">
                    <mat-radio-button [value]="i" (change)="changeCorrectOption(i)">
                    </mat-radio-button>
                    <div class="w-full flex items-center gap-2">
                        <input [(ngModel)]="option.optioN_TEXT" class="p-2 w-full borde" type="text" name="option"
                            maxlength="50" oninput='this.value = this.value.trimStart();' />
                        <mat-icon *ngIf="question.options.length > 2" class="icon-btn" (click)="deleteOption(i)"
                            [matTooltip]="'deleteQuestionOption' | translate">delete</mat-icon>
                    </div>
                </div>
                <div *ngIf="!option.optioN_TEXT" class="text-red-500 text-xs mt-1 ml-7">
                    <span>
                        {{ 'optionBlankError' | translate }}
                    </span>
                </div>
            </div>
        </mat-radio-group>
        <div *ngIf="!checkIfAtLeastOneTrue()" class="text-red-500 text-xs mt-2 -mb-2">
            <span>
                {{ 'optionNotTrueSelected' | translate }}
            </span>
        </div>
        <div class="mt-3 flex items-center justify-between flex-col md:flex-row gap-2">
            <div>
                {{ 'selectCorrectOption' | translate }}
            </div>
            <app-custom-primary-button id="addQuestionOption" size="question-size" color="secondary" class="text-center"
                [textPadding]="'8px'" [textAlign]="'inherit'" (click)="addOption()">
                <span class="btn-label">{{ 'addQuestionOption' | translate }}</span>
            </app-custom-primary-button>
        </div>
    </div>
    <div *ngIf="question.questioN_TYPE == 'CHECKBOXES'" class="mt-4">
        <div [tourAnchor]="getTourAnchor()" class="flex w-full flex-col gap-2">
            <div *ngFor="let option of question.options; let i = index" class="w-full">
                <div class="w-full flex items-center">
                    <mat-checkbox [(ngModel)]="option.iS_CORRECT">
                    </mat-checkbox>
                    <div class="w-full flex items-center gap-2 pl-3">
                        <input [(ngModel)]="option.optioN_TEXT" class="p-2 w-full borde" type="text" name="option"
                            maxlength="50" oninput='this.value = this.value.trimStart();' />
                        <mat-icon *ngIf="question.options.length > 2" class="icon-btn" (click)="deleteOption(i)"
                            [matTooltip]="'deleteQuestionOption' | translate">delete</mat-icon>
                    </div>
                </div>
                <div *ngIf="!option.optioN_TEXT" class="text-red-500 text-xs mt-1 ml-7">
                    <span>
                        {{ 'optionBlankError' | translate }}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="!checkIfAtLeastOneTrue()" class="text-red-500 text-xs mt-2 -mb-2">
            <span>
                {{ 'optionNotTrueSelected' | translate }}
            </span>
        </div>
        <div class="mt-3 flex items-center justify-between flex-col md:flex-row gap-2">
            <div>
                {{ 'selectCorrectOption' | translate }}
            </div>
            <app-custom-primary-button id="addQuestionOption" size="question-size" color="secondary" class="text-center"
                [textPadding]="'8px'" [textAlign]="'inherit'" (click)="addOption()">
                <span class="btn-label">{{ 'addQuestionOption' | translate }}</span>
            </app-custom-primary-button>
        </div>
    </div>
</div>