import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { PuzzleService } from '../services/puzzle.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Blog } from '../interfaces/blog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  searchForm: FormGroup;
  blogs: Blog[] = [];
  page: number = 1;
  isLoading: boolean = false;
  totalBlogs: number = 0;
  lastPost: Blog | null = null;
  defaultpfp: string = '../../assets/icons/default-pfp-icon.svg';
  defaultPicture: string = '../../assets/icons/icon-512x512.png';

  constructor(
    private puzzleService: PuzzleService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    window.scrollTo(0, 0);
  }


  ngOnInit(): void {
    this.searchForm = this.fb.group({
      generalSearch: [''],
      title: [''],
      seoDescription: [''],
      activeStatus: [true]
    });

    this.searchForm.valueChanges.subscribe(() => {
      this.page = 1;
      this.loadBlogs();
    });

    this.loadBlogs();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + window.innerHeight;
    const max = document.documentElement.scrollHeight;

    // Verificar si el usuario ha alcanzado el final de la página y no está cargando
    if (pos >= max && !this.isLoading && this.blogs.length < this.totalBlogs) {
      this.page++;
      this.loadBlogs(false, true);  // Cambiar la página y cargar más
    }
  }

  loadBlogs(resetPage: boolean = true, loadingMore: boolean = false): void {
    if (resetPage) {
      this.page = 1;
      this.blogs = [];
      this.isLoading = true;
    } else if (loadingMore) {
      this.isLoading = true;
    }

    const filters = {
      PageNumber: this.page,
      PageSize: 7,
      GeneralSearch: this.searchForm.get('generalSearch').value,
      Title: this.searchForm.get('title').value,
      SeoDescription: this.searchForm.get('seoDescription').value,
      ActiveStatus: this.searchForm.get('activeStatus').value
    };

    this.puzzleService.getBlogs(filters).subscribe(
      (response) => {
        this.totalBlogs = response.pagination.totaL_COUNT;

        const blogs = response.blogs.map((blog) => {
          blog.eH_BLOG_IMG_BLOG = blog.eH_BLOG_IMG_BLOG
            ? environment.imageUrl + blog.eH_BLOG_IMG_BLOG
            : this.defaultPicture;
          blog.eH_BLOG_PFP_AUTHOR = blog.eH_BLOG_PFP_AUTHOR
            ? environment.imageUrl + blog.eH_BLOG_PFP_AUTHOR
            : this.defaultpfp;
          return blog;
        });

        if (resetPage) {
          this.blogs = blogs;
        } else {
          this.blogs = this.blogs.concat(blogs); // Añadir nuevos posts
        }

        if (this.page === 1 && this.blogs.length > 0) {
          this.lastPost = this.blogs[0];
        }

        this.isLoading = false;
        this.cdr.markForCheck(); // Solo marca para detectar cambios
      },
      (error) => {
        console.error('Error fetching blogs', error);
        this.isLoading = false;
      }
    );
  }

  removeSkeleton(event: Event): void {
    const target = event.target as HTMLElement;
    target.parentElement?.classList.add('loaded');
  }

  goToPost(post: Blog) {
    this.router.navigate(['/blog', post.eH_BLOG_SLUG]);
  }
}
