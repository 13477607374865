import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-share-candidates-dialog',
  templateUrl: './share-candidates-dialog.component.html',
  styleUrls: ['./share-candidates-dialog.component.css']
})
export class ShareCandidatesDialogComponent implements OnInit {

  sending: boolean = false;
  usuariosFiltrados = [];
  allUsers = [];
  pageSize = 1000;
  pageIndex = 0;
  id: string;
  selectedUsers = [];
  selectedCandidates = [];
  currentUser: string;
  emptyUsers: boolean = false;
  editSubjectMode: boolean = false;
  companyLogoRoute: string = '';
  editIcon: SafeResourceUrl = '../../../assets/icons/edit-icon.svg';
  miFormulario: FormGroup;
  subject: string;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ShareCandidatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.selectedCandidates = data.selectedCandidates; // Recibir los candidatos seleccionados
      this.subject = data.subject;

    let tokencito = this.localService.getJsonValue('token');
    if (tokencito.data.companyId != null) {
      this.id = tokencito.data.companyId;
      this.companyLogoRoute = tokencito.data.compLogoRoute;
    }

    this.currentUser = tokencito.data.id.toLowerCase();
  }

  ngOnInit(): void {
    this.miFormulario = this.fb.group({
      message: ['', [Validators.required]],
      subject: [{ value: this.subject, disabled: true }, [Validators.required]] // Inyectar el subject y deshabilitar por defecto
    });
    this.getUsers();
  }

  getUsers(): void {
    this.puzzleService.getAllUsers(this.pageSize, this.pageIndex, null, 'Activos', this.id, null, true).subscribe(users => {

      this.allUsers = users.map(user => ({
        id: user.id.toLowerCase(),
        nombre: user.anU_FULL_NAME,
        email: user.email
      }));

      //ordenar por nombre
      this.allUsers.sort((a, b) => a.nombre.localeCompare(b.nombre));

      // quitar si ya se selecciono
      this.usuariosFiltrados = this.allUsers.filter(usuario =>
        !this.selectedUsers.find(selectedUser => selectedUser.id === usuario.id) && usuario.id !== this.currentUser
      );

      if (this.usuariosFiltrados.length === 0) {
        // close dialog and show message
        this.dialogRef.close({
          isSuccess: false,
          message: 'noUsersAvailable'
        });
      }
    }, error => {
    });
  }

  shareCandidates() {
    if (this.sending) { return; }
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.invalid || this.selectedUsers.length === 0) {
      if (this.selectedUsers.length === 0) { this.emptyUsers = true; }
      return;
    }
    this.sending = true;
    const data = {
      candidatesIds: this.selectedCandidates.map(candidate => ({ candidateId: candidate.cdT_ID, candidateName: `${candidate.cdT_NAME} ${candidate.cdT_PATERNAL_SURNAME} ${candidate.cdT_MATERNAL_SURNAME}`, cv: candidate.cv?.cdoC_DOCUMENT_ROUTE })),
      userList: this.selectedUsers,
      message: this.miFormulario.get('message').value,
      subject: this.miFormulario.get('subject').value,
      companyLogoRoute: this.companyLogoRoute,
      companyId: this.id
    }
    this.puzzleService.shareCandidatesMail(data).subscribe(response => {
      this.dialogRef.close({
        isSuccess: response.isSuccess,
        message: response.message
      });
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  toggleEditSubject() {
    this.editSubjectMode = !this.editSubjectMode;

    if (this.editSubjectMode) {
      // Habilitar el campo subject
      this.miFormulario.get('subject').enable();
    } else {
      // Deshabilitar el campo subject
      this.miFormulario.get('subject').disable();
    }
  }

  onParticipantSelected(event: MatSelectChange) {
    const user = event.value;
    // checar si ya esta seleccionado
    const index = this.selectedUsers.findIndex(selectedUser => selectedUser.id === user.id);
    if (index === -1) {
      this.selectedUsers.push(user);
      this.usuariosFiltrados = this.usuariosFiltrados.filter(usuario => usuario.id !== user.id);
    }
  }

  removeUser(user) {
    this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser.id !== user.id);
    this.usuariosFiltrados.push(JSON.parse(JSON.stringify(user)));
    //ordenar por nombre
    this.usuariosFiltrados.sort((a, b) => a.nombre.localeCompare(b.nombre));
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.addClass(target, 'focused');
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLElement;
    this.renderer.removeClass(target, 'focused');
  }

}
