<div>
    <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
        <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>

    <div>
        <div class="flex justify-center items-center pb-6 title-text relative">
            <div class="text-center green-title">
                {{'billingDataClient' | translate}}
            </div>
        </div>
        <div class="pl-6 subtitle-text">
            {{ 'billingDataClientDescription' | translate }}
        </div>
        <div class="px-6 pt-3">
            <div>
                <!-- Fecha de pago -->
                <div class="flex flex-col w-full">
                    <span class="pb-1 title-input-text">{{ 'clientDetailPaymentDate' | translate }}</span>
                    <div class="h-full">
                        <app-lang-datepicker id="hiredDate" [date]="paymentDate" (datechange)="onDateChange($event)"
                            [borde]="'#EBEBEB'" [min]="minDate"></app-lang-datepicker>
                    </div>
                </div>

                <!-- Plan -->
                <div class="pt-4  w-full">
                    <span class="pb-1 title-input-text">{{ 'clientDetailPlan' | translate }}</span>
                    <mat-select [value]="getPlan(company)" panelClass="my-custom-panel" class="input-style"
                        (selectionChange)="company.plan = $event.value">
                        <mat-option value="haibujobs">{{ "Haibu Jobs" | translate }}</mat-option>
                        <mat-option value="freemium">{{ 'Freemium' | translate }}</mat-option>
                        <mat-option value="basic">{{ 'Entry' | translate }}</mat-option>
                        <mat-option value="agency">{{ 'Entry + HH' | translate }}</mat-option>
                        <mat-option value="middle">{{ 'Mid' | translate }}</mat-option>
                        <mat-option value="full">{{ 'Mid + HH' | translate }}</mat-option>
                    </mat-select>
                </div>

                <!-- Vigencia -->
                <div class="pt-4 pb-3  w-full">
                    <span class="pb-1 title-input-text">{{ 'clientDetailDuration' | translate }}</span>
                    <mat-select id="changeStatusPhase" [(ngModel)]="selectedPlan" name="statusSelection"
                        class="borde p-3 centered-text brown-border">
                        <mat-option *ngFor="let plan of planOptions" [value]="plan.value">
                            {{ plan.viewValue | translate }}
                        </mat-option>
                    </mat-select>
                </div>

                <div>
                    <mat-checkbox [(ngModel)]="activeFreemium" id="sendEmail" class="w-full">
                        <span class="text-xs text-gray-500 whitespace-normal break-words">
                            {{ 'clientDetailFreemiumEnd' | translate }}
                        </span>
                    </mat-checkbox>
                </div>

                <div class="w-full mt-3 flex justify-center">
                    <app-custom-primary-button id="changeStatusSave2" type="submit" size="super-small-light"
                        color="primary" class="w-full flex justify-center" (click)="savePayment()">
                        {{ 'changeStatusSave' | translate }}
                    </app-custom-primary-button>
                </div>
            </div>
        </div>
    </div>
</div>