import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Vacancy } from 'src/app/interfaces/vacancy';
import { environment } from 'src/environments/environment';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/services/local.service';
import { HomeComponent } from 'src/app/home/home.component';

interface ClipboardItem {
  readonly types: string[];
  readonly presentationStyle: "unspecified" | "inline" | "attachment";
  getType(): Promise<Blob>;
}

interface ClipboardItemData {
  [mimeType: string]: Blob | string | Promise<Blob | string>;
}

declare var ClipboardItem: {
  prototype: ClipboardItem;
  new(itemData: ClipboardItemData): ClipboardItem;
};

@Component({
  selector: 'qr-link-dialog',
  templateUrl: './qr-link-dialog.component.html',
  styleUrls: ['./qr-link-dialog.component.css']
})

export class QrLinkDialogComponent implements OnInit {

  vacancy: Vacancy;
  token: string;
  qrUrl: string;
  isJobs: boolean = false;
  public qrCodeDownloadLink: SafeUrl = "";
  @ViewChild('qrCode') qrCode: ElementRef;
  logoHaibu: SafeResourceUrl = '../../../assets/icon-120x120.png';

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private localService: LocalService,
    private dialogRef: MatDialogRef<HomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.vacancy = data.vacancy;
    if (!data.jobs) {
      this.token = data.token;
    } else {
      this.isJobs = true;
    }
  }

  removeSpecialCharactersAndSpaces(text: string): string {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  ngOnInit(): void {
    if (!this.isJobs) {
      const userToken = this.localService.getJsonValue('token');
      const companyName = this.removeSpecialCharactersAndSpaces(userToken.data.companyName);
      const urlWithToken = environment.candidateUrlExternal + companyName + '/CE?id=' + this.token;
      this.qrUrl = urlWithToken;
    } else {
      this.qrUrl = 'https://haibujobs.com/' + this.vacancy.vacanT_ID;
    }
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  saveAsImage() {
    let parentElement = null

    parentElement = this.qrCode['qrcElement'].nativeElement
      .querySelector("img")
      .getAttribute("src")

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = this.vacancy.jobInfo.joB_NAME
      link.click()
      const message = this.translate.instant('candidateDownloadedQrDialog');
      this.dialog.closeAll();
      this.openResultDialog(1, message)
    }
  }

  async copyImage() {
    let parentElement = null

    parentElement = this.qrCode['qrcElement'].nativeElement
      .querySelector("img")
      .getAttribute("src")

    if (parentElement && navigator.clipboard['write']) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })

      try {
        await navigator.clipboard['write']([
          new ClipboardItem({
            [blob.type]: blob
          })
        ]);
        const message = this.translate.instant('candidateCopiedQrDialog');
        this.dialog.closeAll();
        this.openResultDialog(1, message)
      } catch (error) {
        const message = this.translate.instant('candidateErrorCopyQrDialog');
        this.dialog.closeAll();
        this.openResultDialog(0, message)
      }
    } else {
      const message = this.translate.instant('candidateErrorCopyQrDialog');
      this.dialog.closeAll();
      this.openResultDialog(0, message)
    }
  }

  convertBase64ToBlob(Base64Image: string) {
    const parts = Base64Image.split(";base64,")
    const imageType = parts[0].split(":")[1]
    const decodedData = window.atob(parts[1])
    const uInt8Array = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: imageType })
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
            disableClose: true
          });
        }
        break;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}

