<!-- Card de haibu -->
<div class="max-w-full rounded-lg flex items-center p-4 gap-4 my-6 card-haibu">
    <div class="flex-shrink-0 skeleton-image">
        <img [src]="defaultPicture" alt="Logo Haibu HR" class="w-16 h-16 rounded-full object-cover"
            (load)="removeSkeleton($event)">
    </div>

    <div class="flex-grow">
        <div class="text-haibu-card">
            {{ 'haibuCard.description' | translate }}
        </div>
    </div>

    <div class="flex-shrink-0">
        <button class="button-ask-demo" (click)="getDemo()">
            {{ 'haibuCard.button' | translate }}
        </button>
    </div>
</div>