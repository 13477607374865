import { Component, Inject, OnInit } from '@angular/core';
import { User } from '../../interfaces/user';
import { HomeComponent } from '../../home/home.component';
import { PuzzleService } from '../../services/puzzle.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangePassword } from '../../interfaces/change-password';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorService } from '../../services/validator.service';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.css']
})
export class PasswordDialogComponent implements OnInit {

  constructor(private validatorService: ValidatorService, private puzzleService: PuzzleService, private dialogRef: MatDialogRef<HomeComponent>, @Inject(MAT_DIALOG_DATA) public data: User, private fb: FormBuilder) { }

  myForm: FormGroup = this.fb.group(
    {
      password: ['', [Validators.required, Validators.minLength(6)]],
      password2: ['', [Validators.required]],
    },
    {
      validators: [
        this.validatorService.camposIguales('password', 'password2'),
      ],
    }
  )

  changePassword: ChangePassword = {
    id: this.data.id,
    Password: ''
  }

  hide: boolean = true;
  hide2: boolean = true;

  sending: boolean = false;

  ngOnInit(): void {
  }

  sendPass() {
    if (this.sending) {
      return
    }

    this.myForm.markAllAsTouched()

    if (!this.myForm.invalid) {
      this.changePassword.Password = this.myForm.get('password')?.value

      this.sending = true;
      this.puzzleService.editPassword(this.changePassword).subscribe(
        resp => {
          if (resp.isSuccess == true) {
            this.dialogRef.close(true);
          } else {
            this.sending = false;
          }
        },
        error => {
          this.sending = false;
        }
      )
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

}
