<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="mat-dialog-content flex flex-col justify-center items-center h-full relative">
  <div *ngIf="showCatalogsEmptyMessage"
    class="flex flex-col justify-center items-center  top-0 left-0 right-0 bottom-0 m-auto w-full max-w-md overflow-auto p-4">
    <div>
      <div class="text-center title-text-modal ">{{"vacancyNoDataAlert" | translate}}</div>
      <div class="flex justify-center mt-10">
        <app-custom-primary-button id="catalogueComponentTitle" size="recluter-size" color="secondary"
          class="w-full md:w-auto" (click)="goMyCompany()">
          {{ 'catalogueComponentTitle' | translate }}
        </app-custom-primary-button>
      </div>
    </div>
  </div>

  <div *ngIf="isCloseMode" class=" flex flex-col text-center title-text-modal mb-4">
    {{ 'closeVacancyTitle' | translate }}
    <div class="mt-6">
      {{ vacancyStatus === 'open' ? ('confirmCloseVacancy' | translate) : ('confirmOpenVacancy' | translate) }}
    </div>

    <div class="flex justify-center mt-8">
      <app-custom-primary-button id="resetPasswordConfirm" size="recluter-size" color="secondary"
        (click)="closeVacancy()" class="w-full md:w-auto" [disabled]="disabledSend">
        {{ 'resetPasswordConfirm' | translate }}
      </app-custom-primary-button>
    </div>
  </div>

  <div *ngIf="isEditMode">
    <div class="text-center title-text-modal mb-4">
      {{ 'vacancyInfo' | translate }}
    </div>
    <div class="flex flex-col md:flex-row justify-between items-end">
      <div class="flex flex-col w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
        <span class="pb-1 title-input-text">{{ 'vacancyCreatedBy' | translate }}</span>
        <input type="text" class="bordee centered-text brown-borderr" [(ngModel)]="creator" disabled>
      </div>
      <div class="flex flex-col w-full md:w-1/2 mb-2 md:mb-0">
        <div class="flex flex-row justify-between">
          <div
            class="flex flex-row md:flex-col w-full md:w-1/2 md:mr-4 md:mb-0 title-input-text title-input-text-small relative">
            <p class="absolute">{{ 'vacancyOpenDialog' | translate }}</p>
            <span class="md:ml-28 md:pl-3 stats-padd">{{ !disabledVacancyCount ? existingVacancy.vacanT_NUMBER -
              existingVacancy.totaL_CONTRATADOS : '∞' }}</span>
          </div>
          <div
            class="flex flex-row md:flex-col w-full md:w-1/2 md:mb-0 title-input-text title-input-text-small relative"
            [matTooltip]="existingVacancy.dayS_ELAPSED < 0 ? ('vacancyDaysNegative' | translate) : ''">
            <p class="absolute">{{ 'vacancyDaysElapsed' | translate }}</p>
            <span class="md:ml-32 md:pl-3 stats-padd2">
              {{ truncateToZero(existingVacancy.dayS_ELAPSED) }}
            </span>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div
            class="flex flex-row md:flex-col w-full md:w-1/2 md:mr-4 md:mb-0 title-input-text title-input-text-small relative">
            <p class="absolute">{{ 'vacancyCloseDialog' | translate }} </p>
            <span class="md:ml-28 md:pl-3 stats-padd">{{ existingVacancy.totaL_CONTRATADOS }}</span>
          </div>
          <div
            class="flex flex-row md:flex-col w-full md:w-1/2 md:mb-0 title-input-text title-input-text-small relative">
            <p class="absolute">{{ 'vacancyCandidatesNominated' | translate }}</p>
            <span class="md:ml-32 md:pl-3 stats-padd2">{{ existingVacancy.totaL_POSTULADOS }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <div class="flex flex-col w-full md:w-1/2 md:pr-2 mb-4 md:mb-0">
        <div class="text-left green-subtitle md:my-3">
          {{ 'editVacancyTitle2' | translate }}
        </div>
      </div>
      <div *ngIf="!editModeActive" class="flex flex-col w-full md:w-1/2 md:pr-2 mb-4 md:mb-0">
        <div id="toggleEditModeVacancy" *appPermission="'v002'" class="flex items-center justify-end md:my-3">
          <div class="cursor-pointer flex items-center" (click)="toggleEditMode()">
            <img [src]="editIcon" alt="Icono" class="w-4 h-4 mr-2">
            <span class="edit-text edit-text-size">{{"editVacancyTitle2" | translate}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="editModeActive" class="flex flex-col w-full md:w-1/2 md:pr-2 mb-4 md:mb-0">
        <div id="toggleEditModeVacancy" *appPermission="'v002'" class="flex items-center justify-end md:my-3">
          <div class="cursor-pointer flex items-center" (click)="toggleEditMode()">
            <mat-icon class="close-x close-x-primary mr-1">close</mat-icon>
            <span class="edit-text edit-text-size">{{"editVacancyTitleCancel" | translate}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 gap-x-4">
      <!-- Puesto -->
      <div>
        <span class="pb-1 title-input-text">{{'candidateDetailJob' | translate}}*</span>
        <div #containerJob1 class="bordee brown-border mt-1" [ngClass]="{'error-border': jobError}">
          <input tabindex="1" type="text" class="centered-text w-full"
            placeholder="{{'candidateDetailJob' | translate}}" aria-label="Job" matInput [formControl]="jobControl"
            [matAutocomplete]="autoJob" [disabled]="!editModeActive" (focus)="onFocusInput($event, containerJob1)"
            (blur)="onBlurInput($event, containerJob1)"
            oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
          <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="onJobSelected($event)">
            <mat-option *ngFor="let job of filteredJobs | async" [value]="job.joB_ID">
              {{ job.joB_NAME }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="text-xs" [ngClass]="jobError ? 'text-red-500' : 'text-gray-400'">
          {{ 'autoPlaceholder' | translate }}
        </div>
      </div>

      <!-- Sucursal -->
      <div>
        <span class="pb-1 title-input-text">{{ 'candidateBranch' | translate }}*</span>
        <div #containerBranch1 class="bordee brown-border mt-1" [ngClass]="{ 'error-border': branchError }">
          <input tabindex="2" type="text" class="centered-text w-full" placeholder="{{ 'candidateBranch' | translate }}"
            aria-label="Branch" matInput [formControl]="branchControl" [matAutocomplete]="autoBranch"
            [disabled]="!editModeActive" (focus)="onFocusInput($event, containerBranch1)"
            (blur)="onBlurInput($event, containerBranch1)"
            oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
          <mat-autocomplete #autoBranch="matAutocomplete" (optionSelected)="onBranchSelected($event)">
            <mat-option *ngFor="let branch of filteredBranches | async" [value]="branch.broF_ID">
              {{ branch.broF_NAME }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="text-xs" [ngClass]="branchError ? 'text-red-500' : 'text-gray-400'">
          {{ 'autoPlaceholder' | translate }}
        </div>
      </div>

      <!-- Departamento -->
      <div>
        <span class="pb-1 title-input-text">{{ 'addDepartamentName' | translate }}*</span>
        <div #containerDepartment1 class="bordee brown-border mt-1" [ngClass]="{ 'error-border': departmentError }">
          <input tabindex="3" type="text" class="centered-text w-full"
            placeholder="{{ 'addDepartamentName' | translate }}" aria-label="Department" matInput
            [formControl]="departmentControl" [matAutocomplete]="autoDepartment"
            (focus)="onFocusInput($event, containerDepartment1)" [disabled]="!editModeActive"
            (blur)="onBlurInput($event, containerDepartment1)"
            oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
          <mat-autocomplete #autoDepartment="matAutocomplete" (optionSelected)="onDepartmentSelected($event)">
            <mat-option *ngFor="let department of filteredDepartments | async" [value]="department.deP_ID">
              {{ department.deP_NAME }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="text-xs" [ngClass]="departmentError ? 'text-red-500' : 'text-gray-400'">
          {{ 'autoPlaceholder' | translate }}
        </div>
      </div>

      <!-- Jefe directo -->
      <div class="flex flex-col w-full">
        <div class="pb-1 title-input-text flex items-center">
          <span>{{ 'candidateDetailBoss' | translate }}*</span>
          <mat-icon [matTooltip]="'bossKeyForSelect' | translate" class="help-icon">help</mat-icon>
        </div>
        <div class="bordee brown-border mt-0.5" [ngClass]="{'error-border': bossError}">
          <input id="candidateDetailBoss1" matInput class="centered-text w-full" tabindex="4"
            [formControl]="inputControl" [matAutocomplete]="auto" [matAutocompleteDisabled]="!isUserMentioned"
            (input)="onInput($event.target.value)" #inputElement #autoTrigger="matAutocompleteTrigger"
            [disabled]="!editModeActive" maxlength="60"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.@]/g, '').trimStart();">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUserSelect($event.option.value)"
            (opened)="onInput(inputElement.value)" [disabled]="!editModeActive">
            <mat-option *ngFor="let usuario of allUsers" [value]="usuario.nombre">
              {{ usuario.nombre }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <!-- Reclutador responsable -->
      <div class="flex flex-col w-full">
        <div class="pb-1 title-input-text flex items-center">
          <span class="title-input-text">{{'addMainRecruiterVacancy' | translate}}</span>
          <mat-icon [matTooltip]="'noRecruitersTooltip' | translate" class="help-icon">help</mat-icon>
        </div>
        <div *ngIf="recruitersList?.length > 0; else noRecruiters" class="borde brown-border mt-1">
          <mat-select #select5 id="addMainRecruiter3" [(ngModel)]="mainRecruiter" name="participantSelection"
            class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="5" (focus)="onFocus($event)"
            (blur)="onBlur($event)" (selectionChange)="onParticipantSelected2($event)" [disabled]="!editModeActive">
            <mat-option *ngFor="let usuario of recruitersList" [value]="usuario">
              {{usuario.nombre}}
            </mat-option>
          </mat-select>
        </div>
        <ng-template #noRecruiters>
          <div (click)="goTo('home/catalogues/roles', companyId)" class="cursor-pointer text-gray-400 mt-1"
            [matTooltip]="'noRecruitersTooltip' | translate">
            {{ 'noRecruiters' | translate }}
          </div>
        </ng-template>
      </div>

      <!-- Participantes -->
      <div *ngIf="!isHaibuJobs" class="flex flex-col w-full">
        <div class="pb-1 title-input-text flex items-center">
          <span class="title-input-text">{{'addParticipantsVacancy' | translate}}</span>
          <mat-icon [matTooltip]="'participantsVacancyTooltip' | translate" class="help-icon">help</mat-icon>
        </div>
        <div class="borde brown-border mt-1">
          <mat-select #select4 id="addParticipantsVacancy1" *ngIf="usuariosFiltrados.length > 0" [ngModel]="null"
            name="participantSelection" class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="6"
            (selectionChange)="onParticipantSelected($event)" [disabled]="!editModeActive" (focus)="onFocus($event)"
            (blur)="onBlur($event)">
            <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario">
              {{usuario.nombre}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="isHaibuJobs" class="flex w-full gap-2">
        <!-- Número de vacantes -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyCount' | translate }}</span>
          <input *ngIf="!disabledVacancyCount" id="candidateDetailVacancyCount1" type="number"
            class="bordee centered-text brown-borderr" tabindex="7" [(ngModel)]="vacancyCount"
            (input)="onVacancyCountChange($event)" [disabled]="!editModeActive || disabledVacancyCount">
          <input *ngIf="disabledVacancyCount" id="candidateDetailVacancyCount1" type="text"
            class="bordee centered-text brown-borderr" tabindex="7" [(ngModel)]="vacancyCountIlimitado"
            (input)="onVacancyCountChange($event)" [disabled]="!editModeActive || disabledVacancyCount">
        </div>
        <!-- Cuestionario filtro -->
        <div *ngIf="jobHasQuest" class="flex flex-col w-full">
          <span class="pb-1 title-input-text w-full text-center">{{ 'vacancyQuestionnaire' | translate }}</span>
          <div class="h-full flex items-center w-full justify-center">
            <mat-slide-toggle [(ngModel)]="vacancyQuestEnabled" id="vacancyQuestionnaire" color="primary"
              [matTooltip]="'vacancyQuestionnaireTooltip' | translate" [disabled]="!editModeActive">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <!-- Sueldo -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailSalary' | translate }}</span>
        <div class="w-full flex items-center gap-1">
          <input id="candidateDetailSalary1" type="text" name="salary" class="bordee centered-text brown-borderr"
            tabindex="7" [(ngModel)]="formattedSalary" (numericValue)="actualSalary = $event" maxlength="12"
            appFormatCurrencyDirective [disabled]="!editModeActive">
          <mat-icon *ngIf="editModeActive"
            [matTooltip]="(!salaryVisibility ? 'salaryTooltip' : 'salaryTooltip2') | translate"
            class="eye-icon w-20 h-20" [ngClass]="salaryVisibility ? 'primary-color' : 'gray-color'"
            (click)="toggleSalaryVisibility()">
            {{salaryVisibility ? 'visibility' : 'visibility_off'}}
          </mat-icon>
          <mat-icon *ngIf="!editModeActive" class="eye-icon-disabled w-20 h-20"
            [ngClass]="salaryVisibility ? 'primary-color' : 'gray-color'">
            {{salaryVisibility ? 'visibility' : 'visibility_off'}}
          </mat-icon>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
        <!-- Frecuencia de pago -->
        <div class="w-full">
          <span class="pb-1 title-input-text">{{'addPaymentFrequency' | translate}}</span>
          <mat-select id="addPaymentFrequency" [(ngModel)]="selectedPaymentPeriod" name="paymentFrequencySelection"
            class="borde p-3 w-full centered-text brown-border" tabindex="8" [disabled]="!editModeActive"
            (focus)="onFocus($event)" (blur)="onBlur($event)">
            <mat-option *ngFor="let option of paymentPeriods" [value]="option.pyP_ID">
              {{ option.pyP_NAME | translate }}
            </mat-option>
          </mat-select>
        </div>
        <!-- Tipo de salario -->
        <div class="w-full">
          <span class="pb-1 title-input-text">{{'addSalaryType' | translate}}</span>
          <mat-select id="addSalaryType" [(ngModel)]="salaryType" name="salaryTypeSelection"
            class="borde p-3 w-full centered-text brown-border" tabindex="9" (focus)="onFocus($event)"
            (blur)="onBlur($event)" [disabled]="!editModeActive">
            <mat-option [value]="false">
              {{ 'Bruto' | translate }}
            </mat-option>
            <mat-option [value]="true">
              {{ 'Neto' | translate }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <!-- Modalidad de trabajo -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{'addVacacyJobModality' | translate}}</span>
        <div class="borde brown-border mt-1">
          <mat-select id="addVacacyJobModality1" [(ngModel)]="selectedModality" name="participantSelection"
            class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="10" (focus)="onFocus($event)"
            (blur)="onBlur($event)" [disabled]="!editModeActive">
            <mat-option *ngFor="let modality of modalities" [value]="modality">
              {{modality.code | translate}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <!-- Tipo de jornada -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{'addVacacyJobType' | translate}}</span>
        <div class="borde brown-border mt-1">
          <mat-select id="addVacacyJobType1" [(ngModel)]="selectedJobType" name="participantSelection"
            class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="11" (focus)="onFocus($event)"
            (blur)="onBlur($event)" [disabled]="!editModeActive">
            <mat-option *ngFor="let type of jobTypes" [value]="type">
              {{type.code | translate}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <!-- Fecha de inicio -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyOpen' | translate }}</span>
        <div class="h-full">
          <app-lang-datepicker id="openInput1" [date]="openVacancyDate" [tabindex]="12" [max]="closeVacancyDate"
            (datechange)="openVacancyDate = $event" [height]="'h-full'" [borde]="'#EBEBEB'"
            [disabled]="!editModeActive"></app-lang-datepicker>
        </div>
      </div>

      <!-- Fecha esperada de cierre -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyClose' | translate }}</span>
        <div class="h-full">
          <app-lang-datepicker id="closeInput1" [date]="closeVacancyDate" [min]="openVacancyDate" [tabindex]="13"
            (datechange)="closeVacancyDate = $event" [height]="'h-full'" [borde]="'#EBEBEB'"
            [disabled]="!editModeActive"></app-lang-datepicker>
        </div>
      </div>

      <div *ngIf="!isHaibuJobs" class="flex w-full gap-2">
        <!-- Número de vacantes -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyCount' | translate }}</span>
          <input *ngIf="!disabledVacancyCount" id="candidateDetailVacancyCount1" type="number"
            class="bordee centered-text brown-borderr" tabindex="14" [(ngModel)]="vacancyCount"
            (input)="onVacancyCountChange($event)" [disabled]="!editModeActive || disabledVacancyCount">
          <input *ngIf="disabledVacancyCount" id="candidateDetailVacancyCount1" type="text"
            class="bordee centered-text brown-borderr" tabindex="14" [(ngModel)]="vacancyCountIlimitado"
            (input)="onVacancyCountChange($event)" [disabled]="!editModeActive || disabledVacancyCount">
        </div>
        <!-- Cuestionario filtro -->
        <div *ngIf="jobHasQuest" class="flex flex-col w-full">
          <span class="pb-1 title-input-text w-full text-center">{{ 'vacancyQuestionnaire' | translate }}</span>
          <div class="h-full flex items-center w-full justify-center">
            <mat-slide-toggle [(ngModel)]="vacancyQuestEnabled" id="vacancyQuestionnaire" color="primary"
              [matTooltip]="'vacancyQuestionnaireTooltip' | translate" [disabled]="!editModeActive">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <!-- Nombre empresa -->
      <div *ngIf="isTelevisa" class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailCompany' | translate }}</span>
        <div class="w-full flex items-center gap-1">
          <input id="candidateDetailCompany3" type="text" name="company" class="bordee centered-text brown-borderr"
            tabindex="15" [(ngModel)]="companyName" disabled>
          <mat-icon *ngIf="editModeActive"
            [matTooltip]="(!companyVisibility ? 'companyTooltip' : 'companyTooltip2') | translate"
            class="eye-icon w-20 h-20" [ngClass]="companyVisibility ? 'primary-color' : 'gray-color'"
            (click)="toggleCompanyVisibility()">
            {{companyVisibility ? 'visibility' : 'visibility_off'}}
          </mat-icon>

          <mat-icon *ngIf="!editModeActive" class="eye-icon-disabled w-20 h-20"
            [ngClass]="companyVisibility ? 'primary-color' : 'gray-color'">
            {{companyVisibility ? 'visibility' : 'visibility_off'}}
          </mat-icon>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap" *ngIf="editModeActive">
      <span *ngFor="let user of selectedUsers;"
        class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
        <span>{{ user.nombre }}</span>
        <mat-icon id="removeUserVancancyDialog" (click)="removeUser(user)"
          class="cursor-pointer close-x ml-1">cancel</mat-icon>
      </span>
    </div>

    <div class="flex flex-wrap" *ngIf="!editModeActive">
      <span *ngFor="let user of selectedUsers;" class="tag-gray px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
        <span>{{ user.nombre }}</span>
        <mat-icon id="removeUserVancancyDialog" class="cursor-default close-x ml-1 pt-1">
          cancel
        </mat-icon>
      </span>
    </div>

    <!-- Botón -->
    <div *ngIf="editModeActive" class="flex justify-center mt-6 pb-2 md:pb-3">
      <app-custom-primary-button id="vacancyEditButtonSave" size="recluter-size" color="secondary" tabindex="16"
        (click)="editVacancy()" (keydown)="handleKeyDown($event)" class="w-full md:w-auto" [disabled]="disabledSend">
        <span *ngIf="!disabledSend">{{ 'vacancyEditButtonSave' | translate }}</span>
        <div *ngIf="disabledSend" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>

    <div *ngIf="showErrorMessage" class="error-modal">
      <div class="error-content">
        <img [src]="dangerousIcon" alt="Icono de error" class="error-icon ml-3">
        <div class="flex flex-col items-start">
          <div class="additional-text pl-4 pt-2 pb-1">Error</div>
          <div class="error-message pl-4 pb-2">
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <mat-icon id="closeErrorModalVacancyDialog" class="close-button cursor-pointer mr-2 mb-4"
        (click)="closeErrorModal()">close</mat-icon>

    </div>
  </div>

  <div *ngIf="isDuplicateMode">
    <div class="text-center title-text-modal mb-4">
      {{ 'vacancyModalTitle' | translate }}
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 gap-x-4">
      <!-- Puesto -->
      <div>
        <span class="pb-1 title-input-text">{{'candidateDetailJob' | translate}}*</span>
        <div #containerJob2 class="bordee brown-border mt-1" [ngClass]="{'error-border': jobError}">
          <input tabindex="1" type="text" class="centered-text w-full"
            placeholder="{{'candidateDetailJob' | translate}}" aria-label="Job" matInput [formControl]="jobControl"
            [matAutocomplete]="autoJob" (focus)="onFocusInput($event, containerJob2)"
            (blur)="onBlurInput($event, containerJob2)"
            oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
          <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="onJobSelected($event)">
            <mat-option *ngFor="let job of filteredJobs | async" [value]="job.joB_ID">
              {{ job.joB_NAME }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="text-xs" [ngClass]="jobError ? 'text-red-500' : 'text-gray-400'">
          {{ 'autoPlaceholder' | translate }}
        </div>
      </div>

      <!-- Sucursal -->
      <div>
        <span class="pb-1 title-input-text">{{ 'candidateBranch' | translate }}*</span>
        <div #containerBranch2 class="bordee brown-border mt-1" [ngClass]="{ 'error-border': branchError }">
          <input tabindex="2" type="text" class="centered-text w-full" placeholder="{{ 'candidateBranch' | translate }}"
            aria-label="Branch" matInput [formControl]="branchControl" [matAutocomplete]="autoBranch"
            (focus)="onFocusInput($event, containerBranch2)" (blur)="onBlurInput($event, containerBranch2)"
            oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
          <mat-autocomplete #autoBranch="matAutocomplete" (optionSelected)="onBranchSelected($event)">
            <mat-option *ngFor="let branch of filteredBranches | async" [value]="branch.broF_ID">
              {{ branch.broF_NAME }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="text-xs" [ngClass]="branchError ? 'text-red-500' : 'text-gray-400'">
          {{ 'autoPlaceholder' | translate }}
        </div>
      </div>

      <!-- Departamento -->
      <div>
        <span class="pb-1 title-input-text">{{ 'addDepartamentName' | translate }}*</span>
        <div #containerDepartment2 class="bordee brown-border mt-1" [ngClass]="{ 'error-border': departmentError }">
          <input tabindex="3" type="text" class="centered-text w-full"
            placeholder="{{ 'addDepartamentName' | translate }}" aria-label="Department" matInput
            [formControl]="departmentControl" [matAutocomplete]="autoDepartment"
            (focus)="onFocusInput($event, containerDepartment2)" (blur)="onBlurInput($event, containerDepartment2)"
            oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
          <mat-autocomplete #autoDepartment="matAutocomplete" (optionSelected)="onDepartmentSelected($event)">
            <mat-option *ngFor="let department of filteredDepartments | async" [value]="department.deP_ID">
              {{ department.deP_NAME }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="text-xs" [ngClass]="departmentError ? 'text-red-500' : 'text-gray-400'">
          {{ 'autoPlaceholder' | translate }}
        </div>
      </div>

      <!-- Jefe directo -->
      <div class="flex flex-col w-full">
        <div class="pb-1 title-input-text flex items-center">
          <span>{{ 'candidateDetailBoss' | translate }}*</span>
          <mat-icon [matTooltip]="'bossKeyForSelect' | translate" class="help-icon">help</mat-icon>
        </div>
        <div class="bordee brown-border mt-0.5" [ngClass]="{'error-border': bossError}">
          <input id="candidateDetailBoss2" matInput class="centered-text w-full" tabindex="4"
            [formControl]="inputControl" [matAutocomplete]="auto" [matAutocompleteDisabled]="!isUserMentioned"
            (input)="onInput($event.target.value)" #inputElement #autoTrigger="matAutocompleteTrigger" maxlength="60"
            oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s@]/g, '').trimStart();">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUserSelect($event.option.value)"
            (opened)="onInput(inputElement.value)">
            <mat-option *ngFor="let usuario of allUsers" [value]="usuario.nombre">
              {{ usuario.nombre }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <!-- Reclutador responsable -->
      <div class="flex flex-col w-full">
        <div class="pb-1 title-input-text flex items-center">
          <span class="title-input-text">{{'addMainRecruiterVacancy' | translate}}</span>
          <mat-icon [matTooltip]="'noRecruitersTooltip' | translate" class="help-icon">help</mat-icon>
        </div>
        <div *ngIf="recruitersList?.length > 0; else noRecruiters" class="borde brown-border mt-1">
          <mat-select #select5 id="addMainRecruiter3" [(ngModel)]="mainRecruiter" name="participantSelection"
            class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="5" (focus)="onFocus($event)"
            (blur)="onBlur($event)" (selectionChange)="onParticipantSelected2($event)">
            <mat-option *ngFor="let usuario of recruitersList" [value]="usuario">
              {{usuario.nombre}}
            </mat-option>
          </mat-select>
        </div>
        <ng-template #noRecruiters>
          <div (click)="goTo('home/catalogues/roles', companyId)" class="cursor-pointer text-gray-400 mt-1"
            [matTooltip]="'noRecruitersTooltip' | translate">
            {{ 'noRecruiters' | translate }}
          </div>
        </ng-template>
      </div>

      <!-- Participantes -->
      <div *ngIf="!isHaibuJobs" class="flex flex-col w-full">
        <div class="pb-1 title-input-text flex items-center">
          <span class="title-input-text">{{'addParticipantsVacancy' | translate}}</span>
          <mat-icon [matTooltip]="'participantsVacancyTooltip' | translate" class="help-icon">help</mat-icon>
        </div>
        <div class="borde brown-border mt-1">
          <mat-select #select4 id="addParticipantsVacancy2" *ngIf="usuariosFiltrados.length > 0" [ngModel]="null"
            name="participantSelection" class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="6"
            (selectionChange)="onParticipantSelected($event)" (focus)="onFocus($event)" (blur)="onBlur($event)">
            <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario">
              {{usuario.nombre}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="isHaibuJobs" class="flex w-full gap-2">
        <!-- Número de vacantes -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyCount' | translate }}</span>
          <input *ngIf="!disabledVacancyCount" id="candidateDetailVacancyCount2" type="number"
            class="bordee centered-text brown-borderr" tabindex="7" [(ngModel)]="vacancyCount"
            (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
          <input *ngIf="disabledVacancyCount" id="candidateDetailVacancyCount2" type="text"
            class="bordee centered-text brown-borderr" tabindex="7" [(ngModel)]="vacancyCountIlimitado"
            (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
        </div>
        <!-- Cuestionario filtro -->
        <div *ngIf="jobHasQuest" class="flex flex-col w-full">
          <span class="pb-1 title-input-text w-full text-center">{{ 'vacancyQuestionnaire' | translate }}</span>
          <div class="h-full flex items-center w-full justify-center">
            <mat-slide-toggle [(ngModel)]="vacancyQuestEnabled" id="vacancyQuestionnaire" color="primary"
              [matTooltip]="'vacancyQuestionnaireTooltip' | translate">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <!-- Sueldo -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailSalary' | translate }}</span>
        <div class="w-full flex items-center gap-1">
          <input id="candidateDetailSalary2" type="text" name="salary" class="bordee centered-text brown-borderr"
            tabindex="7" [(ngModel)]="formattedSalary" (numericValue)="actualSalary = $event" maxlength="12"
            appFormatCurrencyDirective>
          <mat-icon [matTooltip]="(!salaryVisibility ? 'salaryTooltip' : 'salaryTooltip2') | translate"
            class="eye-icon w-20 h-20" [ngClass]="salaryVisibility ? 'primary-color' : 'gray-color'"
            (click)="toggleSalaryVisibility()">
            {{salaryVisibility ? 'visibility' : 'visibility_off'}}
          </mat-icon>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
        <!-- Frecuencia de pago -->
        <div class="w-full">
          <span class="pb-1 title-input-text">{{'addPaymentFrequency' | translate}}</span>
          <mat-select id="addPaymentFrequency" [(ngModel)]="selectedPaymentPeriod" name="paymentFrequencySelection"
            class="borde p-3 w-full centered-text brown-border" tabindex="8" (focus)="onFocus($event)"
            (blur)="onBlur($event)">
            <mat-option *ngFor="let option of paymentPeriods" [value]="option.pyP_ID">
              {{ option.pyP_NAME | translate }}
            </mat-option>
          </mat-select>
        </div>
        <!-- Tipo de salario -->
        <div class="w-full">
          <span class="pb-1 title-input-text">{{'addSalaryType' | translate}}</span>
          <mat-select id="addSalaryType" [(ngModel)]="salaryType" name="salaryTypeSelection"
            class="borde p-3 w-full centered-text brown-border" tabindex="9" (focus)="onFocus($event)"
            (blur)="onBlur($event)">
            <mat-option [value]="false">
              {{ 'Bruto' | translate }}
            </mat-option>
            <mat-option [value]="true">
              {{ 'Neto' | translate }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <!-- Modalidad de trabajo -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{'addVacacyJobModality' | translate}}</span>
        <div class="borde brown-border mt-1">
          <mat-select id="addVacacyJobModality2" [(ngModel)]="selectedModality" name="participantSelection"
            class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="10" (focus)="onFocus($event)"
            (blur)="onBlur($event)">
            <mat-option *ngFor="let modality of modalities" [value]="modality">
              {{modality.code | translate}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <!-- Tipo de jornada -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{'addVacacyJobType' | translate}}</span>
        <div class="borde brown-border mt-1">
          <mat-select id="addVacacyJobType2" [(ngModel)]="selectedJobType" name="participantSelection"
            class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="11" (focus)="onFocus($event)"
            (blur)="onBlur($event)">
            <mat-option *ngFor="let type of jobTypes" [value]="type">
              {{type.code | translate}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <!-- Fecha de inicio -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyOpen' | translate }}</span>
        <div class="h-full">
          <app-lang-datepicker id="openInput1" [date]="openVacancyDate" [tabindex]="12" [max]="closeVacancyDate"
            (datechange)="openVacancyDate = $event" [height]="'h-full'" [borde]="'#EBEBEB'"></app-lang-datepicker>
        </div>
      </div>

      <!-- Fecha esperada de cierre -->
      <div class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyClose' | translate }}</span>
        <div class="h-full">
          <app-lang-datepicker id="closeInput2" [date]="closeVacancyDate" [min]="openVacancyDate" [tabindex]="13"
            (datechange)="closeVacancyDate = $event" [height]="'h-full'" [borde]="'#EBEBEB'"></app-lang-datepicker>
        </div>
      </div>

      <div *ngIf="!isHaibuJobs" class="flex w-full gap-2">
        <!-- Número de vacantes -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyCount' | translate }}</span>
          <input *ngIf="!disabledVacancyCount" id="candidateDetailVacancyCount2" type="number"
            class="bordee centered-text brown-borderr" tabindex="14" [(ngModel)]="vacancyCount"
            (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
          <input *ngIf="disabledVacancyCount" id="candidateDetailVacancyCount2" type="text"
            class="bordee centered-text brown-borderr" tabindex="14" [(ngModel)]="vacancyCountIlimitado"
            (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
        </div>
        <!-- Cuestionario filtro -->
        <div *ngIf="jobHasQuest" class="flex flex-col w-full">
          <span class="pb-1 title-input-text w-full text-center">{{ 'vacancyQuestionnaire' | translate }}</span>
          <div class="h-full flex items-center w-full justify-center">
            <mat-slide-toggle [(ngModel)]="vacancyQuestEnabled" id="vacancyQuestionnaire" color="primary"
              [matTooltip]="'vacancyQuestionnaireTooltip' | translate">
            </mat-slide-toggle>
          </div>
        </div>
      </div>

      <!-- Nombre empresa -->
      <div *ngIf="isTelevisa" class="flex flex-col w-full">
        <span class="pb-1 title-input-text">{{ 'candidateDetailCompany' | translate }}</span>
        <div class="w-full flex items-center gap-1">
          <input id="candidateDetailCompany3" type="text" name="company" class="bordee centered-text brown-borderr"
            tabindex="15" [(ngModel)]="companyName" disabled>
          <mat-icon [matTooltip]="(!companyVisibility ? 'companyTooltip' : 'companyTooltip2') | translate"
            class="eye-icon w-20 h-20" [ngClass]="companyVisibility ? 'primary-color' : 'gray-color'"
            (click)="toggleCompanyVisibility()">
            {{companyVisibility ? 'visibility' : 'visibility_off'}}
          </mat-icon>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap">
      <span *ngFor="let user of selectedUsers;"
        class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
        <span>{{ user.nombre }}</span>
        <mat-icon id="removeUserVacacyDialog2" (click)="removeUser(user)"
          class="cursor-pointer close-x">close</mat-icon>
      </span>
    </div>

    <!-- Botón -->
    <div class="flex justify-center mt-6 pb-2 md:pb-3">
      <app-custom-primary-button id="vacancyAddVacant2" size="recluter-size" color="secondary" tabindex="16"
        (click)="addVacancy()" (keydown)="handleKeyDown($event)" class="w-full md:w-auto" [disabled]="disabledSend">
        <span *ngIf="!disabledSend">{{ 'vacancyAddVacant' | translate }}</span>
        <div *ngIf="disabledSend" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>

    <div *ngIf="showErrorMessage" class="error-modal">
      <div class="error-content">
        <img [src]="dangerousIcon" alt="Icono de error" class="error-icon ml-3">
        <div class="flex flex-col items-start">
          <div class="additional-text pl-4 pt-2 pb-1">Error</div>
          <div class="error-message pl-4 pb-2">
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <mat-icon id="closeErrorModalVacancyDialog2" class="close-button cursor-pointer mr-2 mb-4"
        (click)="closeErrorModal()">close</mat-icon>

    </div>
  </div>

  <div *ngIf="isDeleteMode" class=" flex flex-col text-center title-text-modal mb-4">
    {{ 'vacancyDeletedOption' | translate }}
    <div class="mt-6">
      {{ 'vacancyDeleteTitle' | translate }}
    </div>

    <div class="flex justify-center mt-8">
      <app-custom-primary-button id="vacancyDeletedOption" size="recluter-size" color="secondary"
        (click)="deleteVacancy()" class="w-full md:w-auto" [disabled]="disabledSend">
        {{ 'vacancyDeletedOption' | translate }}
      </app-custom-primary-button>
    </div>
  </div>

  <div *ngIf="!isEditMode && !isDeleteMode && !isCloseMode && !showCatalogsEmptyMessage && !isDuplicateMode">
    <div>
      <div class="text-center title-text-modal mb-4">
        {{ 'vacancyModalTitle' | translate }}
      </div>
      <div idTour="vacancies.info" class="grid grid-cols-1 md:grid-cols-2 gap-3 gap-x-4">
        <!-- Puesto -->
        <div idTour="vacancies.job">
          <span class="pb-1 title-input-text">{{'candidateDetailJob' | translate}}*</span>
          <div #containerJob3 class="bordee brown-border mt-1" [ngClass]="{'error-border': jobError}">
            <input tabindex="1" type="text" class="centered-text w-full"
              placeholder="{{'candidateDetailJob' | translate}}" aria-label="Job" matInput [formControl]="jobControl"
              [matAutocomplete]="autoJob" (focus)="onFocusInput($event, containerJob3)"
              (blur)="onBlurInput($event, containerJob3)"
              oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
            <mat-autocomplete #autoJob="matAutocomplete" (optionSelected)="onJobSelected($event)">
              <mat-option *ngFor="let job of filteredJobs | async" [value]="job.joB_ID">
                {{ job.joB_NAME }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="text-xs" [ngClass]="jobError ? 'text-red-500' : 'text-gray-400'">
            {{ 'autoPlaceholder' | translate }}
          </div>
        </div>

        <!-- Sucursal -->
        <div idTour="vacancies.branch">
          <span class="pb-1 title-input-text">{{ 'candidateBranch' | translate }}*</span>
          <div #containerBranch3 class="bordee brown-border mt-1" [ngClass]="{ 'error-border': branchError }">
            <input tabindex="2" type="text" class="centered-text w-full"
              placeholder="{{ 'candidateBranch' | translate }}" aria-label="Branch" matInput
              [formControl]="branchControl" [matAutocomplete]="autoBranch"
              (focus)="onFocusInput($event, containerBranch3)" (blur)="onBlurInput($event, containerBranch3)"
              oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
            <mat-autocomplete #autoBranch="matAutocomplete" (optionSelected)="onBranchSelected($event)">
              <mat-option *ngFor="let branch of filteredBranches | async" [value]="branch.broF_ID">
                {{ branch.broF_NAME }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="text-xs" [ngClass]="branchError ? 'text-red-500' : 'text-gray-400'">
            {{ 'autoPlaceholder' | translate }}
          </div>
        </div>

        <!-- Departamento -->
        <div idTour="vacancies.department">
          <span class="pb-1 title-input-text">{{ 'addDepartamentName' | translate }}*</span>
          <div #containerDepartment3 class="bordee brown-border mt-1" [ngClass]="{ 'error-border': departmentError }">
            <input tabindex="3" type="text" class="centered-text w-full"
              placeholder="{{ 'addDepartamentName' | translate }}" aria-label="Department" matInput
              [formControl]="departmentControl" [matAutocomplete]="autoDepartment"
              (focus)="onFocusInput($event, containerDepartment3)" (blur)="onBlurInput($event, containerDepartment3)"
              oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()´"]+/g, "").trimStart();' />
            <mat-autocomplete #autoDepartment="matAutocomplete" (optionSelected)="onDepartmentSelected($event)">
              <mat-option *ngFor="let department of filteredDepartments | async" [value]="department.deP_ID">
                {{ department.deP_NAME }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="text-xs" [ngClass]="departmentError ? 'text-red-500' : 'text-gray-400'">
            {{ 'autoPlaceholder' | translate }}
          </div>
        </div>

        <!-- Jefe directo -->
        <div idTour="vacancies.boss" class="flex flex-col w-full">
          <div class="pb-1 title-input-text flex items-center">
            <span>{{ 'candidateDetailBoss' | translate }}*</span>
            <mat-icon [matTooltip]="'bossKeyForSelect' | translate" class="help-icon">help</mat-icon>
          </div>
          <div class="bordee brown-border mt-0.5" [ngClass]="{'error-border': bossError}">
            <input #autoTrigger="matAutocompleteTrigger" id="candidateDetailBoss3" matInput class="centered-text w-full"
              tabindex="4" [formControl]="inputControl" [matAutocomplete]="auto"
              [matAutocompleteDisabled]="!isUserMentioned" (input)="onInput($event.target.value)" #inputElement
              maxlength="60"
              oninput="this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s@]/g, '').trimStart();">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onUserSelect($event.option.value)"
              (opened)="onInput(inputElement.value)">
              <mat-option *ngFor="let usuario of allUsers" [value]="usuario.nombre">
                {{ usuario.nombre }}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>

        <!-- Reclutador responsable -->
        <div idTour="vacancies.recruiter" class="flex flex-col w-full">
          <div class="pb-1 title-input-text flex items-center">
            <span class="title-input-text">{{'addMainRecruiterVacancy' | translate}}</span>
            <mat-icon [matTooltip]="'noRecruitersTooltip' | translate" class="help-icon">help</mat-icon>
          </div>

          <div *ngIf="recruitersList?.length > 0; else noRecruiters" class="borde brown-border mt-1">
            <mat-select #select5 id="addMainRecruiter3" [(ngModel)]="mainRecruiter" name="participantSelection"
              class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="5" (focus)="onFocus($event)"
              (blur)="onBlur($event)" (selectionChange)="onParticipantSelected2($event)">
              <mat-option *ngFor="let usuario of recruitersList" [value]="usuario">
                {{usuario.nombre}}
              </mat-option>
            </mat-select>
          </div>
          <ng-template #noRecruiters>
            <div (click)="goTo('home/catalogues/roles', companyId)" class="cursor-pointer text-gray-400 mt-1"
              [matTooltip]="'noRecruitersTooltip' | translate">
              {{ 'noRecruiters' | translate }}
            </div>
          </ng-template>
        </div>

        <!-- Participantes -->
        <div *ngIf="!isHaibuJobs" class="flex flex-col w-full">
          <div class="pb-1 title-input-text flex items-center">
            <span class="title-input-text">{{'addParticipantsVacancy' | translate}}</span>
            <mat-icon [matTooltip]="'participantsVacancyTooltip' | translate" class="help-icon">help</mat-icon>
          </div>
          <div class="borde brown-border mt-1">
            <mat-select #select4 id="addParticipantsVacancy3" *ngIf="usuariosFiltrados.length > 0" [ngModel]="null"
              name="participantSelection" class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="6"
              (selectionChange)="onParticipantSelected($event)" (focus)="onFocus($event)" (blur)="onBlur($event)">
              <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario">
                {{usuario.nombre}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div *ngIf="isHaibuJobs" class="flex w-full gap-2">
          <!-- Número de vacantes -->
          <div class="flex flex-col w-full">
            <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyCount' | translate }}</span>
            <input *ngIf="!disabledVacancyCount" id="candidateDetailVacancyCount3" type="number"
              class="bordee centered-text brown-borderr" tabindex="7" [(ngModel)]="vacancyCount"
              (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
            <input *ngIf="disabledVacancyCount" id="candidateDetailVacancyCount3" type="text"
              class="bordee centered-text brown-borderr" tabindex="7" [(ngModel)]="vacancyCountIlimitado"
              (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
          </div>
          <!-- Cuestionario filtro -->
          <div *ngIf="jobHasQuest" class="flex flex-col w-full">
            <span class="pb-1 title-input-text w-full text-center">{{ 'vacancyQuestionnaire' | translate }}</span>
            <div class="h-full flex items-center w-full justify-center">
              <mat-slide-toggle [(ngModel)]="vacancyQuestEnabled" id="vacancyQuestionnaire" color="primary"
                [matTooltip]="'vacancyQuestionnaireTooltip' | translate">
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <!-- Sueldo -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailSalary' | translate }}</span>
          <div class="w-full flex items-center gap-1">
            <input id="candidateDetailSalary3" type="text" name="salary" class="bordee centered-text brown-borderr"
              tabindex="7" [(ngModel)]="formattedSalary" (numericValue)="actualSalary = $event" maxlength="12"
              appFormatCurrencyDirective>
            <mat-icon idTour="vacancies.eye"
              [matTooltip]="(!salaryVisibility ? 'salaryTooltip' : 'salaryTooltip2') | translate"
              class="eye-icon w-20 h-20" [ngClass]="salaryVisibility ? 'primary-color' : 'gray-color'"
              (click)="toggleSalaryVisibility()">
              {{salaryVisibility ? 'visibility' : 'visibility_off'}}
            </mat-icon>
          </div>
        </div>


        <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
          <!-- Frecuencia de pago -->
          <div class="w-full">
            <span class="pb-1 title-input-text">{{'addPaymentFrequency' | translate}}</span>
            <mat-select id="addPaymentFrequency" [(ngModel)]="selectedPaymentPeriod" name="paymentFrequencySelection"
              class="borde p-3 w-full centered-text brown-border" tabindex="8" (focus)="onFocus($event)"
              (blur)="onBlur($event)">
              <mat-option *ngFor="let option of paymentPeriods" [value]="option.pyP_ID">
                {{ option.pyP_NAME | translate }}
              </mat-option>
            </mat-select>
          </div>
          <!-- Tipo de salario -->
          <div class="w-full">
            <span class="pb-1 title-input-text">{{'addSalaryType' | translate}}</span>
            <mat-select id="addSalaryType" [(ngModel)]="salaryType" name="salaryTypeSelection"
              class="borde p-3 w-full centered-text brown-border" tabindex="9" (focus)="onFocus($event)"
              (blur)="onBlur($event)">
              <mat-option [value]="false">
                {{ 'Bruto' | translate }}
              </mat-option>
              <mat-option [value]="true">
                {{ 'Neto' | translate }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Modalidad de trabajo -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{'addVacacyJobModality' | translate}}</span>
          <div class="borde brown-border mt-1">
            <mat-select id="addVacacyJobModality3" [(ngModel)]="selectedModality" name="participantSelection"
              class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="9" (focus)="onFocus($event)"
              (blur)="onBlur($event)">
              <mat-option *ngFor="let modality of modalities" [value]="modality">
                {{modality.code | translate}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Tipo de jornada -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{'addVacacyJobType' | translate}}</span>
          <div class="borde brown-border mt-1">
            <mat-select id="addVacacyJobType3" [(ngModel)]="selectedJobType" name="participantSelection"
              class="w-full p-3 md:w-2/3 centered-text mt-0" tabindex="10" (focus)="onFocus($event)"
              (blur)="onBlur($event)">
              <mat-option *ngFor="let type of jobTypes" [value]="type">
                {{type.code | translate}}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- Fecha de inicio -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyOpen' | translate }}</span>
          <div class="h-full">
            <app-lang-datepicker id="openInput1" [date]="openVacancyDate" [tabindex]="11" [max]="closeVacancyDate"
              (datechange)="openVacancyDate = $event" [height]="'h-full'" [borde]="'#EBEBEB'"></app-lang-datepicker>
          </div>
        </div>

        <!-- Fecha esperada de cierre -->
        <div class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyClose' | translate }}</span>
          <div class="h-full">
            <app-lang-datepicker id="closeInput3" [date]="closeVacancyDate" [min]="openVacancyDate" [tabindex]="12"
              (datechange)="closeVacancyDate = $event" [height]="'h-full'" [borde]="'#EBEBEB'"></app-lang-datepicker>
          </div>
        </div>

        <div *ngIf="!isHaibuJobs" class="flex w-full gap-2">
          <!-- Número de vacantes -->
          <div class="flex flex-col w-full">
            <span class="pb-1 title-input-text">{{ 'candidateDetailVacancyCount' | translate }}</span>
            <input *ngIf="!disabledVacancyCount" id="candidateDetailVacancyCount3" type="number"
              class="bordee centered-text brown-borderr" tabindex="13" [(ngModel)]="vacancyCount"
              (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
            <input *ngIf="disabledVacancyCount" id="candidateDetailVacancyCount3" type="text"
              class="bordee centered-text brown-borderr" tabindex="13" [(ngModel)]="vacancyCountIlimitado"
              (input)="onVacancyCountChange($event)" [disabled]="disabledVacancyCount">
          </div>
          <!-- Cuestionario filtro -->
          <div *ngIf="jobHasQuest" class="flex flex-col w-full">
            <span class="pb-1 title-input-text w-full text-center">{{ 'vacancyQuestionnaire' | translate }}</span>
            <div class="h-full flex items-center w-full justify-center">
              <mat-slide-toggle [(ngModel)]="vacancyQuestEnabled" id="vacancyQuestionnaire" color="primary"
                [matTooltip]="'vacancyQuestionnaireTooltip' | translate">
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <!-- Nombre empresa -->
        <div *ngIf="isTelevisa" class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailCompany' | translate }}</span>
          <div class="w-full flex items-center gap-1">
            <input id="candidateDetailCompany3" type="text" name="company" class="bordee centered-text brown-borderr"
              tabindex="14" [(ngModel)]="companyName" disabled>
            <mat-icon [matTooltip]="(!companyVisibility ? 'companyTooltip' : 'companyTooltip2') | translate"
              class="eye-icon w-20 h-20" [ngClass]="companyVisibility ? 'primary-color' : 'gray-color'"
              (click)="toggleCompanyVisibility()">
              {{companyVisibility ? 'visibility' : 'visibility_off'}}
            </mat-icon>
          </div>
        </div>
      </div>

      <!-- Participantes seleccionados -->
      <div class="flex flex-wrap">
        <span *ngFor="let user of selectedUsers;"
          class="tag-primary px-2.5 py-0.5 rounded-full mt-3 mr-2 flex items-center">
          <span>{{ user.nombre }}</span>
          <div class="flex items-center justify-center">
            <mat-icon id="removeUserVacancyDialog3" (click)="removeUser(user)"
              class="cursor-pointer close-x">cancel</mat-icon>
          </div>
        </span>
      </div>

      <!-- Botón -->
      <div class="flex justify-center mt-6 pb-2 md:pb-3">
        <app-custom-primary-button idTour="vacancies.save" id="vacancyAddVacant3" size="recluter-size" color="secondary"
          (click)="addVacancy()" (keydown)="handleKeyDown($event)" class="w-full md:w-auto" tabindex="15"
          [disabled]="disabledSend">
          <span *ngIf="!disabledSend">{{ 'vacancyAddVacant' | translate }}</span>
          <div *ngIf="disabledSend" class="w-full flex justify-center">
            <mat-spinner diameter="20" color="primary"></mat-spinner>
          </div>
        </app-custom-primary-button>
      </div>

      <div *ngIf="showErrorMessage" class="error-modal">
        <div class="error-content">
          <img [src]="dangerousIcon" alt="Icono de error" class="error-icon ml-3">
          <div class="flex flex-col items-start">
            <div class="additional-text pl-4 pt-2 pb-1">Error</div>
            <div class="error-message pl-4 pb-2">
              {{ errorMessage }}
            </div>
          </div>
        </div>
        <mat-icon id="closeErrorModalVacancyDialog3" class="close-button cursor-pointer mr-2 mb-4"
          (click)="closeErrorModal()">close</mat-icon>
      </div>

    </div>
  </div>
</div>